import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Profile from './views/Profile.vue';
import Home from './views/LandingPage.vue';
import EmailConfirmation from './views/EmailConfirmation.vue';
import ResetPassword from './views/ResetPassword.vue';
import About from './views/About.vue';
import Contribute from './views/Contribute.vue';
import Search from './views/Search.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import TermsAndConditions from './views/TermsAndConditions.vue';
import MyHomes from './components/profile/MyHomes.vue';
import BulkUpload from './components/profile/BulkUpload.vue';
// import AccountManagement from './components/profile/AccountManagement.vue';
import NoPermissionMessage from './components/NoPermissionMessage.vue';
import AddHome from './views/AddHome.vue';
import SearchDetailWrapper from "@/views/SearchDetailWrapper";
import ProfileSettings from "@/components/profile/ProfileSettings";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: EmailConfirmation,
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: ResetPassword,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      redirect: { name: 'yourHomes' },
      children: [
        { name: 'yourHomes', path: 'your-homes', component: MyHomes },
        { name: 'bulkUpload', path: 'bulk-upload', component: BulkUpload },
        // { name: 'manage', path: 'manage', component: AccountManagement },
        { name: 'noAccess', path: 'no-access', component: NoPermissionMessage },
        { name: 'settings', path: 'settings', component: ProfileSettings}
      ],
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/contribute',
      name: 'contribute',
      component: Contribute,
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
    },
    {
      path: '/details/:id',
      name: 'details',
      component: SearchDetailWrapper,
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/add',
      name: 'add',
      component: AddHome
    },
    {
      path: '/terms-conditions',
      name: 'termsAndConditions',
      component: TermsAndConditions,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPolicy,
    }
  ]
});