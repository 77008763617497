import axios from 'axios';
import Vue from 'vue';
import VueFlashMessage from 'vue-flash-message';
import 'vue-flash-message/dist/vue-flash-message.min.css';
import { frontendConfig } from '../helpers/frontend_config';

Vue.use(VueFlashMessage, {
  messageOptions: {
    timeout: 3000,
    pauseOnInteract: true
  }
});

const baseURL = '/api/homes';
const authURL = '/api/auth/';
const photoURL = '/api/photos';
const permsURL = '/api/permissions';
const settingsURL = '/api/user_settings';


const handleError = fn => (...params) =>
  fn(...params).catch(error => {
      // TODO: do I need this method?
      return { error: error };
  });

export const api = {
  // Search
  getHomes: handleError(async () => {
    const res = await axios.get(baseURL);
    return res.data;
  }),
  getRecentHomes: handleError(async (limit) => {
    const res = await axios.get(baseURL+'/recent?limit='+limit);
    return res.data;
  }),
  searchForHomes: handleError(async (searchQuery, previous, shouldPaginate) => {
    const res = await axios.post(baseURL+'/search',
        {
            'query': searchQuery,
            'previous': previous,
            'shouldPaginate': shouldPaginate
        });
    return res.data;
  }),
  getHomeById: handleError(async (id) => {
    const res = await axios.get(baseURL+'/'+id);
    return res.data;
  }),

  // User management
  login: handleError(async (email, password) => {
      const body = {'email': email, 'password': password};
      const res = await axios.post(authURL+'login', body);
      return res.data;
  }),
  logout: handleError(async (userId) => {
      const body = { 'userId': userId };
      const res = await axios.post(authURL+'logout', body);
      return res.data;
  }),
  registerUser: handleError(async (email, password)=> {
      const body = {'email': email, 'password': password};
      const res = await axios.post(authURL+'register', body);
      return res.data;
  }),
  confirmUser: handleError(async (token, tokenID)=> {
      const body = {'token': token, 'token_id': tokenID};
      const res = await axios.post(authURL+'confirm', body);
      return res.data;
  }),
  sendResetPasswordEmail: handleError(async (email)=> {
      const body = {'email': email};
      const res = await axios.post(authURL+'sendResetEmail', body);
      return res.data;
  }),
  resetPassword: handleError(async (newPassword, token, tokenID)=> {
      const body = {'newPassword': newPassword, 'token': token, 'token_id': tokenID};
      const res = await axios.post(authURL+'resetPassword', body);
      return res.data;
  }),
  getPermissions: handleError(async (userID) => {
      const res = await axios.get(`${permsURL}/${userID}`);
      return res.data;
  }),
  updatePermissions: handleError(async (newPermissions) => {
      const res = await axios.post(`${permsURL}/${newPermissions.user_id}`, newPermissions);
      return res.data;
  }),
  getUserSettings: handleError(async (userID) => {
      const res = await axios.get(`${settingsURL}/${userID}`);
      return res.data;
  }),
  updateUserSettings: handleError(async (newSettings) => {
      const res = await axios.post(`${settingsURL}/${newSettings.user_id}`, newSettings);
      return res.data;
  }),

  // Create, update, delete homes
  submitHome: handleError(async (homeData) => {
      const res = await axios.post(baseURL, homeData);
      return res.data;
  }),
  updateHome: handleError(async (updatedHome, homeId) => {
    const res = await axios.put(baseURL + '/'+homeId, updatedHome);
    return res.data;
  }),
  deleteHome: handleError(async id => {
    const res = await axios.delete(baseURL + '/' + id);
    return res.data;
  }),
  bulkSubmitHomes: handleError(async (homesList) => {
      const res = await axios.post(baseURL + '/bulk', homesList);
      return res.data;
  }),

  // Photos
  deletePhoto: handleError(async (photoName) => {
      const body = { keyToDelete: photoName };
      const res = await axios.post(photoURL+'/delete', body);
      return res.data;
  }),

  // Google Maps API
  autofillLocation: handleError(async coordinates => {
      const res = await axios.get(
          'https://maps.googleapis.com/maps/api/geocode/json?key='
          + frontendConfig.GOOGLE_MAPS_API_KEY
          + '&language=en&latlng='+coordinates.lat+','+coordinates.lng);
      return res;
  }),
  searchByAddress: handleError(async searchStr => {
      const res = await axios.get(
          'https://maps.googleapis.com/maps/api/geocode/json?key='
          + frontendConfig.GOOGLE_MAPS_API_KEY
          + '&language=en&address='+searchStr
      )
      return res;
  })
};