<template>
    <span class="info-icon">
        <font-awesome-icon v-tooltip="tooltipText" icon="info-circle" class="tooltip-icon"/>
    </span>
</template>

<script>
    export default {
        name: "InfoIcon",
        props: {
            tooltipText: { type: String, default: null },
        }
    }
</script>

<style scoped>

</style>