import { api } from '@/helpers/api';
import { submitStatus, getFormattedAddress, USER_KEY, addHomeDetails, progressStatus } from '@/helpers/utils';
import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

const PAGINATION_LIMIT = 9;

// Auth mutations
const UPDATE_USER = 'updateUser';
const UPDATE_LOGIN_LOADING_STATUS = 'updateLoginLoadingStatus';
const SET_AUTH_ERROR = 'setAuthError';
const SET_AUTH_STATUS = 'setAuthStatus';
const UPDATE_USER_SETTINGS = 'updateUserSettings';

// 'Add a Home' form mutations
const OVERWRITE_HOME_FORM = 'overwriteHomeForm';
const SET_HOME_TO_EDIT = 'setHomeToEdit';
const SET_PHOTO_DELETE_STATUS = 'setPhotoDeleteStatus';
const SET_AUTOFILLED_LOCATION = 'setAutofilledLocation';
const SET_AUTOFILL_STATUS = 'setAutofillStatus';
const SET_ADDRESS_SEARCH_COORDINATES = 'setAddrSearchCoords';
const SET_ADDRESS_SEARCH_STATUS = 'setAddrSearchStatus';
const SET_HOME_SUBMIT_STATUS = 'setHomeSubmitStatus';
const SET_HOME_DELETE_STATUS = 'setHomeDeleteStatus';
const SET_CREATED_HOME_ID = 'setCreatedHomeId';
const TOGGLE_EXTRA = 'toggleExtraFormItem';
const UPDATE_FORM_SECTION = 'updateFormSection';
const RESET_SUBMIT_STATUS = 'resetSubmitStatus';

// Search mutations
const SET_SEARCH_STATUS = 'setSearchStatus';
const SET_SEARCH_RESULTS = 'setSearchResults';
const SET_MORE_TO_LOAD = 'setMoreToLoad';
const SET_NUM_SEARCH_RESULTS = 'setNumSearchResults';
// Permissions mutations
const UPDATE_PERMISSIONS = 'updatePerms';
const PUSH_USER_SETTINGS = 'pushUserSettings';
const SET_USER_SETTINGS_LOADING = 'setUserSettingsLoading';
const CLEAR_STORED_USER_SETTINGS = 'clearStoredUserSettings';

const blankAddHomeForm = {
    location: { label: 'Location', component: 'LocationForm' },
    selectDetails: { label: 'Choose Details', component: 'SelectDetails' },
    occupants: null,
    gad: null,
    census: null,
    structure: null,
    events: null,
    photos: null,
    references: null,
    review: { label: 'Review', component: 'ReviewDetailWrapper'},
    submit: { label: 'Submit', component: 'SubmitSection' },
}

export const store = new Vuex.Store({
    strict: true,
    state: {
        loggedInUser: null,
        loginLoading: false,
        authError: null,
        authStatus: null,
        homeToEdit: null,
        homeSubmitted: null,
        photoDeleteStatus: null,
        homeDeleteStatus: null,
        autofilledLocation: null,
        autofillStatus: null,
        addressSearchCoordinates: null,
        addressSearchStatus: null,
        searchStatus: null,
        searchResults: [],
        moreToLoad: false,
        numSearchResults: 0,
        addHomeFormSections: _.cloneDeep(blankAddHomeForm),
        createdHomeId: null,
        userSettings: {},
        userSettingsLoading: false,
    },
    getters: {
        // Auth getters
        getLoggedInUser (state) {
            // TODO: maybe make this an action so you can actually set loggedInUser to what's in sessionStorage
            // instead of leaving it null
            if (state.loggedInUser === null && sessionStorage.getItem(USER_KEY)) {
                const userStr = sessionStorage.getItem(USER_KEY);
                return JSON.parse(userStr);
            }
            return state.loggedInUser;
        },
        loginIsLoading (state) {
            return state.loginLoading;
        },
        authError (state) {
            return state.authError;
        },
        authStatus (state) {
            return state.authStatus;
        },
        getAllUserSettings (state) {
            return state.userSettings;
        },
        userSettingsLoading (state) {
            return state.userSettingsLoading;
        },

        // Home create/update/delete getters
        homeSubmitStatus(state) {
            return state.homeSubmitted;
        },
        homeDeleteStatus(state) {
            return state.homeDeleteStatus;
        },
        formIsEmpty(state){
            return _.isEqual(state.addHomeFormSections, blankAddHomeForm);
        },
        getHomeToEdit(state){
            return state.homeToEdit;
        },
        getPhotoDeleteStatus(state){
            return state.photoDeleteStatus;
        },
        getAutofilledLocation(state) {
            return state.autofilledLocation;
        },
        getAutofillStatus(state) {
            return state.autofillStatus;
        },
        getAddressCoordinates(state){
            return state.addressSearchCoordinates;
        },
        getAddressSearchStatus(state){
            return state.addressSearchStatus;
        },
        getCreatedHomeId(state){
            return state.createdHomeId;
        },

        // Search getters
        searchStatus(state){
           return state.searchStatus;
        },
        searchResults(state){
            return state.searchResults;
        },
        canLoadMore(state){
            return state.moreToLoad;
        },
        numSearchResults(state) {
            return state.numSearchResults;
        },
        getHomeFormSections(state){
            return state.addHomeFormSections;
        }
    },
    actions: {
        // Auth actions
        loginUser: (context, loginInfo) => {
            context.commit(UPDATE_LOGIN_LOADING_STATUS, true);

            api.login(loginInfo.email, loginInfo.password).then(result => {
                if ('error' in result) {
                    const errorFromAPI = result.error.response.data.message;
                    const errorText = errorFromAPI === 'invalid username/password'
                                                        ? 'Incorrect email address or password'
                                                        : errorFromAPI;
                    context.commit(SET_AUTH_ERROR, errorText);
                    context.commit(UPDATE_LOGIN_LOADING_STATUS, false);
                    return result;
                } else {
                    // set permissions
                    api.getPermissions(result.id).then(userPerms => {
                        if('error' in userPerms){
                            // MARLEE: handle this better
                            console.log(userPerms.error);
                            result.permissions = {};
                        }
                        else {
                            result.permissions = userPerms.permissions;
                        }

                        // get user settings
                        api.getUserSettings(result.id).then(userSettings => {
                            if('error' in userSettings){
                                // MARLEE: handle this better
                                console.log(userSettings.error);
                                result.settings = {};
                            }
                            else {
                                result.settings = userSettings.user_settings;
                            }
                            context.commit(UPDATE_USER, result);
                            context.commit(UPDATE_LOGIN_LOADING_STATUS, false);
                            return result;
                        })
                    });
                }
            });
        },
        logoutUser: context => {
            // if user in storage but not in state, use that instead
            const userInStorageOnly = context.state.loggedInUser === null && sessionStorage.getItem(USER_KEY);
            const userObj = userInStorageOnly ? JSON.parse(sessionStorage.getItem(USER_KEY)) : context.state.loggedInUser;
            api.logout(userObj.id).then(() => {
                sessionStorage.removeItem(USER_KEY);
                if(userInStorageOnly){
                    // watchers only register value changes, so make sure they notice that you logged out
                    context.commit(UPDATE_USER, 'trigger watchers');
                }
                context.commit(UPDATE_USER, null);
                context.commit(OVERWRITE_HOME_FORM, _.cloneDeep(blankAddHomeForm));
            });
        },
        createUser: (context, loginInfo) => {
            context.commit(UPDATE_LOGIN_LOADING_STATUS, true);
            api.registerUser(loginInfo.email, loginInfo.password).then( result => {
                if ('error' in result) {
                    const apiError = result.error.response.data.message;
                    const errorMessage = apiError === 'name already in use'
                                                        ? 'Selected email address already in use'
                                                        : apiError;
                    context.commit(SET_AUTH_ERROR, errorMessage);
                }
                else {
                    context.commit(SET_AUTH_STATUS, result.message);
                }
            })
        },
        confirmUser: (context, tokenInfo) => {
            api.confirmUser(tokenInfo.token, tokenInfo.token_id).then(result => {
                if (result && 'error' in result) {
                    const apiError = result.error.response.data.message;

                    let errorMessageClean = '';
                    switch (apiError){
                        case 'invalid token data':
                            errorMessageClean = 'Secret confirmation token in URL was incorrect.';
                            break;
                        case 'userpass token is expired or invalid':
                            errorMessageClean = 'Email link expired or invalid. You may have confirmed your account already, ' +
                                                'or waited more than 12 hours after registering to click the confirmation link. ' +
                                                'Try logging in, or re-register.';
                            break;
                        default:
                            errorMessageClean = apiError;
                    }
                    context.commit(SET_AUTH_ERROR, errorMessageClean);
                }
                else {
                    context.commit(SET_AUTH_STATUS, 'Confirmed account.');
                }
            })
        },
        sendPasswordResetEmail: (context, email) => {
            api.sendResetPasswordEmail(email).then(result => {
                if ('error' in result){
                    const errorReason = result.error.response.data.message;
                    context.commit(SET_AUTH_ERROR, errorReason);
                }
                else {
                    context.commit(SET_AUTH_STATUS, result.message);
                }
            })
        },
        resetPassword: (context, newPasswordInfo) => {
            api.resetPassword(newPasswordInfo.newPassword, newPasswordInfo.token, newPasswordInfo.token_id)
                .then(result => {
                    if ('error' in result){
                        const apiError = result.error.response.data.message;
                        const errorMessage = apiError === 'invalid token data' ? "Email link is no longer valid. Click 'forgot password' on the login page to send another." : apiError;
                        context.commit(SET_AUTH_ERROR, errorMessage);
                    }
                    else {
                        context.commit(SET_AUTH_STATUS, result.message);
                    }
                })
        },
        getUserSettings: (context, userID) => {
            context.commit(SET_USER_SETTINGS_LOADING, true);
            api.getUserSettings(userID).then(result => {
                context.commit(PUSH_USER_SETTINGS, {settings: result.user_settings, userID});
                context.commit(SET_USER_SETTINGS_LOADING, false);
              }
            )
        },
        updateUserSettings: (context, userSettings) => {
            api.updateUserSettings(userSettings).then(result => {
                if('error' in result){
                    console.log(result.error);
                }
                else {
                    // update user object w/ new settings
                    context.commit(UPDATE_USER_SETTINGS, result);
                    // clear old stored user settings
                    context.commit(CLEAR_STORED_USER_SETTINGS);
                }
            })
        },

        // Home create/update/delete actions
        toggleAddFormExtra(context, key){
            context.commit(TOGGLE_EXTRA, key);
        },
        overwriteForm: (context, houseData) => {
            context.commit(OVERWRITE_HOME_FORM, houseData);
        },
        clearForm: (context) => {
            context.commit(OVERWRITE_HOME_FORM, _.cloneDeep(blankAddHomeForm));
        },
        setHomeToEdit(context, home){
            context.commit(SET_HOME_TO_EDIT, home);
        },
        submitHome: (context, houseData) => {
            context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.LOADING);
            context.commit(SET_CREATED_HOME_ID, null);

            api.submitHome(houseData).then(result => {
                if ('errors' in result){
                    context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.FAILED);
                }
                else {
                    context.commit(SET_CREATED_HOME_ID, result._id);
                    context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.SUCCESS);
                    context.commit(OVERWRITE_HOME_FORM, _.cloneDeep(blankAddHomeForm));
                }
            })
        },
        bulkSubmitHomes: (context, houseList) => {
            context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.LOADING);

            api.bulkSubmitHomes(houseList).then(result => {
                if ('errors' in result){
                    context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.FAILED);
                }
                else {
                    context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.SUCCESS);
                }
            })
        },
        resetSubmitStatus: (context) => {
            // use this one
            context.commit(RESET_SUBMIT_STATUS);
        },
        updateHome: (context, houseData) => {
            context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.LOADING);
            api.updateHome(houseData.data, houseData.id).then(result => {
                if ('errors' in result){
                    context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.FAILED);
                }
                else {
                    context.commit(SET_HOME_SUBMIT_STATUS, submitStatus.SUCCESS);
                }
            })
        },
        deleteHome: (context, homeId) => {
            context.commit(SET_HOME_DELETE_STATUS, submitStatus.LOADING);
            api.deleteHome(homeId).then(result => {
                if ('errors' in result){
                    context.commit(SET_HOME_DELETE_STATUS, submitStatus.FAILED);
                }
                else {
                    context.commit(SET_HOME_DELETE_STATUS, submitStatus.SUCCESS);
                }
            })
        },
        // Create/edit home page - process form data
        resetHomeSubmitStatus(context){
            // this is the old version used in bulk uploads
            context.commit(SET_HOME_SUBMIT_STATUS, null);
        },
        autofillLocation(context, coordinates){
            context.commit(SET_AUTOFILL_STATUS, submitStatus.LOADING);

            api.autofillLocation(coordinates).then(result => {
                if(result.status === 200){
                    context.commit(SET_AUTOFILL_STATUS, submitStatus.SUCCESS);
                    const formattedAddress = getFormattedAddress(result.data.results);
                    context.commit(SET_AUTOFILLED_LOCATION, formattedAddress);
                }
                else {
                    context.commit(SET_AUTOFILL_STATUS, submitStatus.FAILED)
                }

            });
        },
        clearAddressSearch(context){
            context.commit(SET_ADDRESS_SEARCH_STATUS, null);
        },
        searchByAddress(context, address){
            context.commit(SET_ADDRESS_SEARCH_STATUS, submitStatus.LOADING);

            api.searchByAddress(address).then(result => {
                // clear out any existing search results
                context.commit(SET_ADDRESS_SEARCH_COORDINATES, null);
                if(result.status === 200){
                    try {
                        const results = result.data.results;
                        const formattedAddress = getFormattedAddress(results);
                        const locationObj = {...results[0].geometry.location, country: formattedAddress.country};
                        context.commit(SET_ADDRESS_SEARCH_COORDINATES, locationObj);
                        context.commit(SET_ADDRESS_SEARCH_STATUS, submitStatus.SUCCESS);
                    }
                    catch (e){
                        context.commit(SET_ADDRESS_SEARCH_STATUS, submitStatus.FAILED);
                    }
                }
                else {
                    context.commit(SET_ADDRESS_SEARCH_STATUS, submitStatus.FAILED);
                }
            })
        },
        deletePhoto(context, homeToDelete) {
            context.commit(SET_PHOTO_DELETE_STATUS, submitStatus.LOADING);

            api.deletePhoto(homeToDelete).then(result => {
                if ('error' in result){
                    context.commit(SET_PHOTO_DELETE_STATUS, submitStatus.FAILED);
                }
                else {
                    context.commit(SET_PHOTO_DELETE_STATUS, submitStatus.SUCCESS);
                }
            })
        },
        updateFormSection(context, {key, data}){
            context.commit(UPDATE_FORM_SECTION, { key, data });
        },
        // Search actions
        searchForHome(context, searchQuery) {
            context.commit(SET_SEARCH_STATUS, submitStatus.LOADING);

            // get most recent search result if previous pages loaded
            let previous = null;
            const shouldGetNextPage = parseInt(searchQuery.nextPage) === 1;
            const shouldPaginate = searchQuery.shouldPaginate;

            if(shouldGetNextPage) {
                const oldSearchResults = context.state.searchResults;
                const mostRecentItem = oldSearchResults[oldSearchResults.length - 1];
                previous = mostRecentItem._id;
            }

            api.searchForHomes(searchQuery.query, previous, shouldPaginate).then(result => {
                if ('errors' in result){
                    context.commit(SET_SEARCH_STATUS, submitStatus.FAILED);
                    context.commit(SET_SEARCH_RESULTS, []);
                }
                else {
                    context.commit(SET_SEARCH_STATUS, submitStatus.SUCCESS);

                    const resultsArray = shouldGetNextPage ? [...context.state.searchResults, ...result.homes] : result.homes;
                    context.commit(SET_SEARCH_RESULTS, resultsArray);

                    const totalSearchResults = result.numSearchResults;
                    if (previous === null) {
                        // fresh search, set result size
                        context.commit(SET_NUM_SEARCH_RESULTS, totalSearchResults);
                    }

                    const moreToLoad = result.homes.length === PAGINATION_LIMIT
                                        && totalSearchResults > resultsArray.length;
                    context.commit(SET_MORE_TO_LOAD, moreToLoad);
                }
            })
        },
        getRecentHomes(context, limit = 4){
            context.commit(SET_SEARCH_STATUS, submitStatus.LOADING);
            api.getRecentHomes(limit).then(result => {
                if ('error' in result){
                    context.commit(SET_SEARCH_STATUS, submitStatus.FAILED);
                }
                else {
                    context.commit(SET_SEARCH_STATUS, submitStatus.SUCCESS);
                    context.commit(SET_SEARCH_RESULTS, result);
                }
            })
        },
        updatePermissions(context, newPerms){
            api.updatePermissions(newPerms).then(result => {
                if('error' in result) {
                    // MARLEE: handle error
                }
                else {
                    context.commit(UPDATE_PERMISSIONS, result);
                }
            })
        },
        resetAuthError(context){
            context.commit(SET_AUTH_ERROR, null);
        },
        resetAuthStatus(context){
            context.commit(SET_AUTH_STATUS, null);
        }
    },

    // mutations shouldn't be async, so use actions for the actual logic
    mutations: {
        [UPDATE_USER] (state, user) {
            state.loggedInUser = user;
            sessionStorage.setItem(USER_KEY, JSON.stringify(user));
        },
        [UPDATE_PERMISSIONS] (state, perms) {
            let updatedUser = null;

            if(!state.loggedInUser && sessionStorage.getItem(USER_KEY)) {
                // Get loggedInUser from storage if it's null
                const userStr = sessionStorage.getItem(USER_KEY);
                state.loggedInUser = JSON.parse(userStr);
            }
            else if(!state.loggedInUser) {
                return;
            }
            updatedUser = {...state.loggedInUser};
            updatedUser.permissions = perms;
            state.loggedInUser = updatedUser;
            sessionStorage.setItem(USER_KEY, JSON.stringify(updatedUser));
        },
        [PUSH_USER_SETTINGS] (state, userSettingsObj) {
            const {userID, settings} = userSettingsObj;
            state.userSettings = {...state.userSettings, [userID]: settings};
        },
        [CLEAR_STORED_USER_SETTINGS] (state) {
            state.userSettings = {};
        },
        [UPDATE_USER_SETTINGS] (state, settings) {
            let updatedUser = null;

            if(!state.loggedInUser && sessionStorage.getItem(USER_KEY)) {
                // Get loggedInUser from storage if it's null
                const userStr = sessionStorage.getItem(USER_KEY);
                state.loggedInUser = JSON.parse(userStr);
            }
            else if(!state.loggedInUser) {
                return;
            }
            updatedUser = {...state.loggedInUser};
            updatedUser.settings = settings;
            state.loggedInUser = updatedUser;
            sessionStorage.setItem(USER_KEY, JSON.stringify(updatedUser));
        },
        [SET_USER_SETTINGS_LOADING] (state, isLoading){
            state.userSettingsLoading = isLoading;
        },
        [UPDATE_LOGIN_LOADING_STATUS] (state, status) {
            state.loginLoading = status;
        },
        [OVERWRITE_HOME_FORM] (state, houseData) {
            state.addHomeFormSections = houseData;
        },
        [SET_AUTH_ERROR] (state, errorMsg) {
            state.authError = errorMsg;
        },
        [SET_AUTH_STATUS] (state, msg) {
            state.authStatus = msg;
        },
        [SET_HOME_SUBMIT_STATUS] (state, status) {
            state.homeSubmitted = status;
        },
        [SET_CREATED_HOME_ID] (state, id) {
            state.createdHomeId = id;
        },
        [SET_SEARCH_STATUS] (state, status) {
            state.searchStatus = status;
        },
        [SET_SEARCH_RESULTS] (state, results) {
            state.searchResults = results;
        },
        [SET_HOME_TO_EDIT] (state, home) {
            // if there are any -1s left over from the android app, remove them
            const { structure, events, censuses } = home;

            if (home.census){
                // old format. Update to new.
                let censusEmpty = true;
                Object.values(home.census).forEach(value => {
                    const isEmptyArray = Array.isArray(value) && value.length === 0;
                    if(value !== null && value !== "" && !isEmptyArray) {
                        censusEmpty = false;
                    }
                });

                if(!censusEmpty) {
                    censuses.push(home.census);
                }
                home.census = null;
            }

            censuses.forEach(censusItem => {
                if(censusItem.year && censusItem.year === -1){
                    censusItem.year = null;
                }
            });

            const intStructFields = ['year', 'numRooms', 'numWindows', 'numDoors'];
            Object.keys(structure).forEach(key => {
                if(intStructFields.includes(key) && structure[key] === -1){
                    structure[key] = null
                }
            });

            events.forEach(event => {
                if(event.year === -1){
                    event.year = null;
                }
            });

            state.homeToEdit = home;
            // Copy home data to the form in the proper format
            state.addHomeFormSections.location.data = {
                country: home.country,
                latitude: home.latitude,
                longitude: home.longitude
            }
            state.addHomeFormSections.occupants = home.occupants.length > 0 ? { ...addHomeDetails.occupants, data: home.occupants } : null;
            state.addHomeFormSections.gad = !Object.values(home.gad).every(val => val === null) ? { ...addHomeDetails.gad, data:home.gad } : null;
            state.addHomeFormSections.census = home.censuses.length > 0 ? { ...addHomeDetails.census, data:home.censuses } : null;
            state.addHomeFormSections.structure = !Object.values(home.structure).every(val => val === null) ? { ...addHomeDetails.structure, data: home.structure }: null;
            state.addHomeFormSections.events = home.events.length > 0 ? { ...addHomeDetails.events, data:home.events } : null;
            state.addHomeFormSections.photos = home.photos.length > 0 ? { ...addHomeDetails.photos, data:home.photos } : null;
            state.addHomeFormSections.references = home.references.length > 0 ? { ...addHomeDetails.references, data:home.references } : null;

            // Mark parts of form that have been filled out as done
            Object.entries(state.addHomeFormSections).forEach(([key, value])=> {
                if(!['submit', 'review'].includes(key) && value !== null){
                    state.addHomeFormSections[key].status = progressStatus.DONE;
                }
            });
        },
        [SET_PHOTO_DELETE_STATUS] (state, status) {
            state.photoDeleteStatus = status;
        },
        [SET_HOME_DELETE_STATUS] (state, status) {
            state.homeDeleteStatus = status;
        },
        [SET_AUTOFILLED_LOCATION] (state, location) {
            state.autofilledLocation = location;
        },
        [SET_AUTOFILL_STATUS] (state, status) {
            state.autofillStatus = status;
        },
        [SET_ADDRESS_SEARCH_COORDINATES](state, coordinates) {
            state.addressSearchCoordinates = coordinates;
        },
        [SET_ADDRESS_SEARCH_STATUS](state, status) {
            state.addressSearchStatus = status;
        },
        [SET_MORE_TO_LOAD] (state, status) {
            state.moreToLoad = status;
        },
        [SET_NUM_SEARCH_RESULTS] (state, numResults) {
            state.numSearchResults = numResults;
        },
        [TOGGLE_EXTRA] (state, key) {
            if(state.addHomeFormSections[key] == null){
                state.addHomeFormSections[key] = addHomeDetails[key];
            }
            else {
                state.addHomeFormSections[key] = null;
            }
        },
        [UPDATE_FORM_SECTION] (state, formData) {
            const {key, data} = formData;
            state.addHomeFormSections[key] = {...state.addHomeFormSections[key], ...data};
        },
        [RESET_SUBMIT_STATUS] (state) {
            state.addHomeFormSections.review = _.cloneDeep(blankAddHomeForm.review);
            state.addHomeFormSections.submit = _.cloneDeep(blankAddHomeForm.submit);
        }
    }
});