<template>
  <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
    <ol v-if="fullImageList.length > 1" class="carousel-indicators">
      <li
        v-for="(imgUrl, index) in fullImageList"
        :key="index"
        data-target="#carouselIndicators"
        :data-slide-to="index"
        class="active"
      />
    </ol>
    <div class="carousel-inner">
      <div v-for="(imgUrl, index) in fullImageList" :key="index" :class="['carousel-item', {'active': index === 0}]">
        <img class="d-block w-100" :src="imgUrl" @error="showErrorImage">
      </div>
    </div>
    <a v-if="fullImageList.length > 1" class="carousel-control-prev" href="#carouselIndicators" role="button"
       data-slide="prev">
      <span class="carousel-control-arrow"><font-awesome-icon icon="caret-left"/></span>
      <span class="sr-only">Previous</span>
    </a>
    <a v-if="fullImageList.length > 1" class="carousel-control-next" href="#carouselIndicators" role="button"
       data-slide="next">
      <span class="carousel-control-arrow"><font-awesome-icon icon="caret-right"/></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import {getPlaceholderUrl, getS3Url, getImgErrorUrl} from '@/helpers/utils'

export default {
  name: "ImageCarousel",
  props: {
    imageList: {type: Array, default: () => []}
  },
  computed: {
    fullImageList() {
      if (this.imageList.length === 0) {
        const placeholder = getPlaceholderUrl();
        return [placeholder];
      }
      const imgList = [];
      this.imageList.forEach(imageName => {
        const s3Url = getS3Url(imageName);
        imgList.push(s3Url);
      })
      return imgList;
    }
  },
  methods: {
    showErrorImage(event) {
      event.target.src = getImgErrorUrl();
    }
  }
}
</script>

<style scoped>
.image-carousel {
  border-radius: 6px;
}

.carousel-inner {
  border-radius: 6px;
}

.carousel-item, .carousel-inner {
  height: 100%;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
}

.carousel-control-prev, .carousel-control-next {
  font-size: 6em;
}


@media screen and (max-width: 600px) {
.carousel-control-prev, .carousel-control-next {
  font-size: xxx-large;
}
}
</style>