<template>
  <div>
    <h2>Property Details</h2>
    <div class="selection-details">
      Additional information about the home's physical characteristics and construction.
    </div>
    <div class="row num-inputs">
      <ValidationInput
        v-model="structYear"
        type="number"
        label="Year"
        class="col-lg-3 col-sm-12"
        validation-type="year"
        :should-validate="shouldValidateField['year']"
        @validated="checkIfDoneValidating"
      />
      <ValidationInput
        v-model="numRooms"
        type="number"
        label="Number of Rooms"
        class="col-lg-3 col-sm-12"
        validation-type="positive"
        :should-validate="shouldValidateField['number of rooms']"
        @validated="checkIfDoneValidating"
      />
      <ValidationInput
        v-model="numWindows"
        type="number"
        label="Number of Windows"
        class="col-lg-3 col-sm-12"
        validation-type="positive"
        :should-validate="shouldValidateField['number of windows']"
        @validated="checkIfDoneValidating"
      />
      <ValidationInput
        v-model="numDoors"
        type="number"
        label="Number of Doors"
        class="col-lg-3 col-sm-12"
        validation-type="positive"
        :should-validate="shouldValidateField['number of doors']"
        @validated="checkIfDoneValidating"
      />
    </div>
    <div class="col-12">
      <label for="inputStructName" class="form-label">Structure Name</label>
      <InfoIcon :tooltip-text="tooltips.structName"/>
      <input v-model="structName" type="text" class="form-control" id="inputStructName">
    </div>
    <div class="col-12">
      <label for="inputRoofDesc" class="form-label">Roof Description</label>
      <input v-model="roofDesc" type="text" class="form-control" id="inputRoofDesc">
    </div>
    <div class="col-12">
      <label for="inputWallDesc" class="form-label">Wall Description</label>
      <input v-model="wallDesc" type="text" class="form-control" id="inputWallDesc">
    </div>
    <div class="col-12">
      <label for="inputFloorDesc" class="form-label">Floor Description</label>
      <input v-model="floorDesc" type="text" class="form-control" id="inputFloorDesc">
    </div>
    <div class="col-12">
      <label for="inputStructDesc" class="form-label">General Description</label>
      <textarea v-model="structDesc" class="form-control" id="inputStructDesc"></textarea>
    </div>
  </div>
</template>

<script>
import {progressStatus} from '@/helpers/utils';
import {ROUTENAMES, TOOLTIPS} from '@/helpers/constants';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import ValidationInput from '../ValidationInput';
import InfoIcon from '../InfoIcon';

export default {
  name: "StructureForm",
  components: { ValidationInput, InfoIcon },
  data() {
    return {
      structYear: null,
      numDoors: null,
      numWindows: null,
      numRooms: null,
      wallDesc: null,
      floorDesc: null,
      roofDesc: null,
      structName: null,
      structDesc: null,
      shouldValidateField: {
        year: false,
        'number of rooms': false,
        'number of windows': false,
        'number of doors': false
      },
      validFields: {
        year: true,
        'number of rooms': true,
        'number of windows': true,
        'number of doors': true
      }
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    if (this.getHomeFormSections.structure?.data) {
      const { data } = this.getHomeFormSections.structure;
      this.structYear = data.year;
      this.roofDesc = data.roofDesc;
      this.floorDesc = data.floorDesc;
      this.wallDesc = data.wallDesc;
      this.numDoors = data.numDoors;
      this.numWindows = data.numWindows;
      this.numRooms = data.numRooms;
      this.structName = data.structName;
      this.structDesc = data.structDesc;
    } else {
      this.$emit('disableNext', 'Please fill in at least one field.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please fill in at least one field.');
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({key: 'structure', data: {status: progressStatus.DONE}});
      }
    },
    structYear() {
      this.shouldValidateField['year'] = true;
    },
    numRooms() {
      this.shouldValidateField['number of rooms'] = true;
    },
    numWindows() {
      this.shouldValidateField['number of windows'] = true;
    },
    numDoors() {
      this.shouldValidateField['number of doors'] = true;
    },
    validFields: {
      handler(to) {
        const allTrue = Object.values(to).every(val => val === true);
        if (!this.wholeFormEmpty && allTrue) {
          this.$emit('disableNext', null);
          this.updateFormSection({ key: 'structure', data: { status: progressStatus.DONE }});
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    tooltips() {
      return TOOLTIPS;
    },
    wholeFormEmpty(){
      return !(this.roofDesc || this.floorDesc || this.wallDesc || this.numDoors || this.numWindows || this.numRooms
          || this.structName || this.structDesc || this.structYear)
    }
  },
  methods: {
    ...mapActions(['updateFormSection']),
    savePageContents(){
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        const structData = {
          year: this.structYear,
          roofDesc: this.roofDesc,
          floorDesc: this.floorDesc,
          wallDesc: this.wallDesc,
          numDoors: this.numDoors,
          numWindows: this.numWindows,
          numRooms: this.numRooms,
          structName: this.structName,
          structDesc: this.structDesc,
        };
        this.updateFormSection({ key: 'structure', data: { data: structData } });
        if (this.wholeFormEmpty) {
          this.updateFormSection({ key: 'structure', data: { status: null } });
        }
        this.$emit('disableNext', null);
      }
    },
    checkIfDoneValidating(result) {
      if (result.valid) {
        this.shouldValidateField[result.label] = false;
        this.validFields[result.label] = true;
      } else {
        this.validFields[result.label] = false;
        this.$emit('disableNext', 'Please correct the "' + result.label + '" field before continuing.');
        this.updateFormSection({ key: 'structure', data: { status: progressStatus.ERROR } });
      }
    },
  }
}
</script>

<style scoped>
.num-inputs {
  margin-right: auto;
  margin-left: auto;
}
</style>