<template>
    <div class="terms-container page-bg">
        <h1>Terms and Conditions</h1>
        <div class="row coming-soon">
            <div class="col-12">
                <p>
                    These terms and conditions ("Terms", "Agreement") are an agreement between Web Application Developer
                    ("Application Developer", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets
                    forth the general terms and conditions of your use of the Ancestral Homes web application and any of its
                    products or services (collectively, "Web Application" or "Services").
                </p>
                <h5>Accounts and Membership</h5>
                <p>
                    You must be at least 16 years of age to use this Web Application. By using this Web Application and by agreeing
                    to this Agreement you warrant and represent that you are at least 18 years of age.
                    If you create an account in the Web Application, you are responsible for maintaining the security of your account
                    and you are fully responsible for all activities that occur under the account and any other actions taken in connection
                    with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services.
                    Providing false contact information of any kind may result in the termination of your account. You must immediately notify
                    us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or
                    omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend,
                    disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this
                    Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account
                    for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol
                    address to prevent further registration.
                </p>
                <h5>User Content</h5>
                <p>
                    We do not own any data, information or material ("Content") that you submit in the Web Application in the course of
                    using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability,
                    appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no
                    obligation to, monitor and review Content in the Web Application submitted or created using our Services by you.
                    Unless specifically permitted by you, your use of the Web Application does not grant us the license to use,
                    reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for
                    commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store,
                    transmit, reformat, display and perform the Content of your user account solely as required for the purpose of
                    providing the Services to you. Without limiting any of those representations or warranties, we have the right,
                    though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable
                    opinion, violates any of our policies or is in any way harmful or objectionable.
                </p>
                <h5>Backups</h5>
                <p>
                    We are not responsible for Content residing in the Web Application. In no event shall we be held
                    liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of
                    your Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with
                    absolutely no obligation, we may be able to restore some or all of your data that has been deleted as
                    of a certain date and time when we may have backed up data for our own purposes. We make no guarantee
                    that the data you need will be available.
                </p>
                <h5>Links to other applications</h5>
                <p>
                    Although this Web Application may link to other web applications, we are not, directly or
                    indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any
                    linked web application, unless specifically stated herein. We are not responsible for examining
                    or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content
                    of their web applications. We do not assume any responsibility or liability for the actions,
                    products, services, and content of any other third-parties. You should carefully review the legal
                    statements and other conditions of use of any web application which you access through a link from
                    this Web Application. Your linking to any other off-site applications is at your own risk.
                </p>
                <h5>Prohibited Uses</h5>
                <p>
                    In addition to other terms as set forth in the Agreement, you are prohibited from using the Web Application
                    or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any
                    unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws,
                    or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual
                    property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate,
                    or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin,
                    or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any
                    other type of malicious code that will or may be used in any way that will affect the functionality or
                    operation of the Service or of any related web application, other web applications, or the Internet;
                    (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider,
                    crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the
                    security features of the Service or any related web application, other web applications, or the
                    Internet. We reserve the right to terminate your use of the Service or any related application
                    for violating any of the prohibited uses.
                </p>
                <h5>Indemnification</h5>
                <p>
                    You agree to indemnify and hold Web Application Developer and its affiliates, directors, officers,
                    employees, and agents harmless from and against any liabilities, losses, damages or costs, including
                    reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations,
                    claims, actions, disputes, or demands asserted against any of them as a result of or relating to your
                    Content, your use of the Web Application or Services or any willful misconduct on your part.
                </p>
                <h5>Severability</h5>
                <p>
                    All rights and restrictions contained in this Agreement may be exercised and shall be applicable and
                    binding only to the extent that they do not violate any applicable laws and are intended to be limited
                    to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable.
                    If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid
                    or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the
                    remaining provisions or portions thereof shall constitute their agreement with respect to the subject
                    matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
                </p>
                <h5>Changes and Amendments</h5>
                <p>
                    We reserve the right to modify this Agreement or its policies relating to the Web Application or Services
                    at any time, effective upon posting of an updated version of this Agreement in the Web Application.
                    When we do, we will revise the updated date at the bottom of this page. Continued use of the Web Application
                    after any such changes shall constitute your consent to such changes. Policy was created with https://www.WebsitePolicies.com
                </p>
                <h5>Acceptance of these Terms</h5>
                <p>
                    You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using
                    the Web Application or its Services you agree to be bound by this Agreement. If you do not agree to
                    abide by the terms of this Agreement, you are not authorized to use or access the Web Application
                    and its Services.
                </p>
                <h5>Contacting Us</h5>
                <p>
                    If you would like to contact us to understand more about this Agreement or wish to contact us concerning
                    any matter relating to it, you may send an email to <a href="mailto:info@ancestralhomes.net">info@ancestralhomes.net</a>
                </p>
                <p>This document was last updated on April 3, 2021</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TermsAndConditions"
    }
</script>

<style scoped>
.terms-container h1 {
    margin: 1rem 0;
}
</style>