<template>
    <div class="search-container">
        <!--Top search area-->
        <section class="row intro">
          <div class="search-bg teal-bg h-100">
            <div class="mask d-flex align-items-center h-100">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 col-lg-10 col-xl-9 mx-auto">

                    <!--Main search box-->
                    <div class="card">
                      <div class="card-header">
                        <div class="input-group input-group-lg">
                          <input
                            v-model="form['country']"
                            type="text"
                            class="form-control form-control-lg rounded"
                            id="locationInput"
                            placeholder="Country"
                          />
                        </div>
                        <div class="input-group input-group-lg">
                          <input v-model="form['occupants_lName']"
                            type="text"
                            class="form-control form-control-lg rounded"
                            id="familyInput"
                            placeholder="Family Name"
                          />
                        </div>
                        <a class="advanced-link" @click="toggleAdvancedSearch">{{advancedSearchText}}</a>
                        <!--Search button, if advanced closed-->
                        <div v-if="!showAdvanced" class="d-flex justify-content-between align-items-center mt-4">
                          <p class="text-muted mb-0"><span class="text-info">{{totalSearchResults}} </span>{{resultString}}</p>
                          <div>
                            <a type="button" class="btn btn-link text-body" data-mdb-ripple-color="dark" @click="clearAll">Reset</a>
                            <button type="button" class="btn btn-info" @click="searchClicked">Search</button>
                          </div>
                        </div>
                      </div>

                      <!--Advanced search section  -->
                      <div v-if="showAdvanced" class="advanced-search-section card-body p-4">
                        <h6 class="text-muted text-uppercase mt-3 mb-4">Advanced Search</h6>
                        <!--Dropdowns  -->
                        <div class="row">
                          <!--GAD  -->
                          <div class="col-md-4 mb-3">
                            <div class="dropdown">
                              <a
                                class="btn btn-outline-info btn-lg btn-block dropdown-toggle"
                                type="button" id="dropdownGADButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Geographic
                              </a>
                              <div class="dropdown-menu" aria-labelledby="dropdownGADButton">
                                <a
                                  v-for="option in gadOptions"
                                  :key='option.fieldName'
                                  :class="[{disabled: optionInUse(option)},'dropdown-item']"
                                  @click="addSearchField(option)"
                                >
                                  {{option.title}}
                                </a>
                              </div>
                            </div>
                          </div>
                          <!--Census  -->
                          <div class="col-md-4 mb-3">
                            <div class="dropdown">
                              <a
                                class="btn btn-outline-info btn-lg btn-block dropdown-toggle"
                                type="button"
                                id="dropdownCensusButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Census
                              </a>
                              <div class="dropdown-menu" aria-labelledby="dropdownCensusButton">
                                <a
                                  v-for="option in censusOptions"
                                  :key='option.fieldName'
                                  :class="[{disabled: optionInUse(option)},'dropdown-item']"
                                  @click="addSearchField(option)"
                                >
                                  {{option.title}}
                                </a>
                              </div>
                            </div>
                          </div>
                          <!--Structure  -->
                          <div class="col-md-4 mb-3">
                            <div class="dropdown">
                              <a
                                class="btn btn-outline-info btn-lg btn-block dropdown-toggle"
                                type="button"
                                id="dropdownStructureButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Structure
                              </a>
                              <div class="dropdown-menu" aria-labelledby="dropdownStructureButton">
                                <a
                                  v-for="option in structureOptions"
                                  :key='option.fieldName'
                                  class="dropdown-item"
                                  @click="addSearchField(option)"
                                >
                                  {{option.title}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <!--Events  -->
                          <div class="col-md-4 mb-3">
                            <div class="dropdown">
                              <a
                                class="btn btn-outline-info btn-lg btn-block dropdown-toggle"
                                type="button"
                                id="dropdownEventsButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Events
                              </a>
                              <div class="dropdown-menu" aria-labelledby="dropdownEventsButton">
                                <a
                                  v-for="option in eventOptions"
                                  :key='option.fieldName'
                                  class="dropdown-item"
                                  @click="addSearchField(option)"
                                >
                                  {{option.title}}
                                </a>
                              </div>
                            </div>
                          </div>
                          <!--Residents and Grave sites  -->
                          <div class="col-md-4 mb-3">
                            <div class="dropdown">
                              <a
                                class="btn btn-outline-info btn-lg btn-block dropdown-toggle"
                                type="button" id="dropdownResidentsButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Residents
                              </a>
                              <div class="dropdown-menu" aria-labelledby="dropdownResidentsButton">
                                <a
                                  v-for="option in residentOptions"
                                  :key='option.fieldName'
                                  class="dropdown-item"
                                  @click="addSearchField(option)"
                                >
                                  {{option.title}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--Extra input fields-->
                        <div v-if="extraFields.length > 0" class="d-flex justify-content-between align-items-center mt-4">
                          <div class="input-group input-group-lg">
                            <div v-for="extra in extraFields" :key="extra.fieldName" class="input-group input-group-lg">
                              <div class="range-input" v-if="extra.isRange">
                                <input
                                  v-model="form[`${extra.fieldName}Start`]"
                                  :type="extra.type || 'text'"
                                  class="form-control form-control-lg rounded advanced-input"
                                  :placeholder="`${extra.title} (Start)`"
                                />
                                <span> - </span>
                                <input
                                  v-model="form[`${extra.fieldName}End`]"
                                  :type="extra.type || 'text'"
                                  class="form-control form-control-lg rounded advanced-input"
                                  :placeholder="`${extra.title} (End)`"
                                />
                              </div>

                              <input
                                v-else
                                v-model="form[extra.fieldName]"
                                :type="extra.type || 'text'"
                                class="form-control form-control-lg rounded advanced-input"
                                :placeholder="extra.title"/>

                              <a class="delete-button warning-text" @click="removeSearchField(extra)">
                                <font-awesome-icon icon="times"/>
                              </a>
                            </div>
                          </div>
                        </div>
                        <!--Search button, if advanced closed-->
                        <div class="d-flex justify-content-between align-items-center mt-4">
                          <p class="text-muted mb-0"><span class="text-info">{{totalSearchResults}} </span>{{resultString}}</p>
                          <div>
                            <a type="button" class="btn btn-link text-body" @click="clearAll">Reset</a>
                            <button type="button" class="btn btn-info" @click="searchClicked">Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!--Search results-->
        <div class="row search-results">
          <div class="map-toggle-buttons">
            <button
              :class="['button', 'btn-lg', 'left', {active: isMapView}]"
              @click="toggleMapView"
            >
              Map View
            </button>
            <button
              :class="['button', 'btn-lg', 'right', {active: !isMapView}]"
              @click="toggleMapView"
            >
              Grid View
            </button>
          </div>

          <div v-if="isMapView" class="search-map-view">
            <div v-if="!resultsLoading && currentSearchResults.length === 0" class="empty-map-message teal-bg">
              <div class="result-message" v-if="showCallToAction">
                <h2>No records found.</h2>
                <p>Is this your family's name? Help us fill in the gaps by adding your ancestor's home now!</p>
                <router-link class="click-prompt" :to="{ name: loginDestination, params: {register: true } }">Click here</router-link>
                <span> to {{promptText}} and get started.</span>
              </div>
              <h2 class="result-message" v-else>{{resultMessage}}</h2>
            </div>
            <GmapMap
              class="location-map"
              ref="mapRef"
              :center="mapCentre"
              :zoom="3"
              :options="{ streetViewControl: false }"
            >
              <GmapInfoWindow
                class="info-window"
                v-if="selectedHome"
                :position="selectedHome.location"
                :options="{ pixelOffset: { width: 0, height: -35}}"
              >
                <div class="info-window-contents" @click="redirectToDetailPage(selectedHome.id)">
                  <img class="tooltip-img" :src="selectedHome.photo"/>
                  <span class="tooltip-txt">{{selectedHome.description}}</span>
                  <span v-if="selectedHome.year" class="tooltip-subtext">{{selectedHome.year}}</span>
                </div>
              </GmapInfoWindow>

              <GmapMarker
                v-for="home in currentSearchResults"
                :key="home._id"
                :position="{lat: home.latitude, lng: home.longitude}"
                @click="mapMarkerClicked(home)"
              />
            </GmapMap>
          </div>

          <div v-else class="search-grid-view">
            <div v-if="currentSearchResults.length > 0" class="results-grid">
                <SearchResultCard v-for="result in currentSearchResults" :key="result.id" :home="result"/>
            </div>
            <div v-else-if="resultsLoading" class="col-12 d-flex justify-content-center">
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="result-message" v-else-if="showCallToAction">
              <h2>No records found.</h2>
              <p>Is this your family's name? Help us fill in the gaps by adding your ancestor's home now!</p>
              <router-link class="click-prompt" :to="{ name: loginDestination, params: {register: true } }">Click here</router-link>
              <span> to {{promptText}} and get started.</span>
            </div>
            <h2 class="result-message" v-else>{{resultMessage}}</h2>
          </div>
        </div>
        <div v-if="!isMapView && canLoadMore" class="row">
          <div class="col-12 text-center">
            <button class="btn btn-lg btn-primary load-more" @click="loadMore">Load More</button>
          </div>
        </div>
    </div>
</template>

<script>
import SearchResultCard from '../components/search/SearchResultCard';
import { submitStatus, sendGoogleAnalyticsEvent, getS3Url, getPlaceholderUrl, LAST_SEARCH_KEY } from '@/helpers/utils'
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
    name: "Search",
    components: { SearchResultCard },
    data() {
        return {
            extraFields: [],
            currentSearchResults: [],
            showAdvanced: false,
            showCallToAction: false,
            gadOptions: [
                { title: 'Subdivision', fieldName: 'gad_subdivision' },
                { title: 'City/Town', fieldName: 'gad_city' },
                { title: 'County', fieldName: 'gad_county' },
                { title: 'Townland', fieldName: 'gad_townland' },
                { title: 'Township', fieldName: 'gad_township' },
                { title: 'Parish', fieldName: 'gad_parish' },
                { title: 'Concession', fieldName: 'gad_concession' },
                { title: 'Lot', fieldName: 'gad_lot' },
                { title: 'Municipality', fieldName: 'gad_municipal' },
                { title: 'Map Sheet Number', fieldName: 'gad_mapSheetNum' },
                { title: 'Map Series', fieldName: 'gad_mapSeries' },
            ],
            censusOptions: [
                { title: 'Census Year', fieldName: 'censuses_year', type: 'number', isRange: true },
            ],
            structureOptions: [
                { title: 'Structure Name', fieldName: 'structure_structName' },
                { title: 'Year Constructed', fieldName: 'structure_year', type: 'number', isRange: true },
                { title: 'Number of Rooms', fieldName: 'structure_numRooms', type: 'number' },
                { title: 'Number of Windows', fieldName: 'structure_numWindows', type: 'number' },
                { title: 'Number of Doors', fieldName: 'structure_numDoors', type: 'number' },
            ],
            eventOptions: [
                { title: 'Event Year', fieldName: 'events_yearRecorded', type: 'number', isRange: true },
            ],
            residentOptions: [
                { title: 'First Name', fieldName: 'occupants_fName' },
                { title: 'Last Name', fieldName: 'occupants_lName' },
                { title: 'Occupancy Start', fieldName: 'occupants_occupancyStart' },
                { title: 'Occupancy End', fieldName: 'occupants_occupancyEnd' },
                { title: 'Date of Birth', fieldName: 'occupants_birthdate' },
                { title: 'Date of Death', fieldName: 'occupants_deathDate' },
                { title: 'Grave Site', fieldName: 'occupants_gravesite' },
            ],
            form: {},
            resultsLoading: false,
            isMapView: true,
            map: null,
            mapCentre: {lat: 53.35, lng: -6.26}, //Dublin
            selectedHome: null,
        }
    },
    mounted() {
        this.$gtag.pageview('Search');
        this.$refs.mapRef.$mapPromise.then(map => {
          this.map = map;
        });

        // check if a search term is being passed in as part of the request
        const numParams = Object.keys(this.$route.params).length;
        // Show appropriate message if no results
        this.showCallToAction = numParams > 0;

        let searchQuery = {}
        let searchNextPage = 0;

        if(this.showCallToAction){
          // Family name was passed in as a parameter from the home page
          searchQuery = this.$route.params.searchQuery;
          if (searchQuery['occupants.lName']) {
            this.form['occupants_lName'] = searchQuery['occupants.lName'];
            this.isMapView = false;
            this.storeQueryAsRecent(searchQuery, searchNextPage);
          }
        }
        else if(!this.isRecentSearchEmpty){
          // if a recent search has been stored, use that
          const { form, query, nextPage, isMapView } = this.mostRecentSearch;
          this.form = form || {};
          searchQuery = query || {};
          searchNextPage = nextPage || 0;
          this.isMapView = isMapView || false;

          // add advanced fields if needed
          const formKeys = Object.keys(form);
          const noAdvancedFields = formKeys.every(item => {
            return ['country', 'occupants_lName'].includes(item);
          })
          if(!noAdvancedFields){
            this.showAdvanced = true;
            formKeys.forEach(formKey => {
              // if key is part of a range, strip that part off so it will match the label mappings
              let formKeyCopy = formKey.replace('End', '');
              formKeyCopy = formKeyCopy.replace('Start', '');
              this.addSearchField({title: this.fieldLabels[formKeyCopy], fieldName: formKeyCopy, isRange: formKeyCopy !== formKey});
            });
          }
        }

        this.searchForHome({'query': searchQuery, 'nextPage': searchNextPage, 'shouldPaginate': !this.isMapView});
    },
    watch: {
        searchStatus(to) {
            this.selectedHome = null;

            if(to === submitStatus.SUCCESS){
                this.currentSearchResults = this.searchResults;
                this.resultsLoading = false;
                this.showCallToAction = this.shouldShowCallToAction()
            }
            else if(to === submitStatus.LOADING){
                this.resultsLoading = true;
            }
            else if(to === submitStatus.FAILED){
                this.resultsLoading = false;
                this.showCallToAction = this.shouldShowCallToAction()
            }
        }
    },
    computed: {
        ...mapGetters(['searchStatus', 'searchResults', 'getLoggedInUser', 'canLoadMore', 'numSearchResults']),
        advancedSearchText(){
            return this.showAdvanced ? 'Hide Advanced Search' : 'Advanced Search';
        },
        resultString(){
            return this.numSearchResults === 1 ? 'result' : 'results';
        },
        resultMessage(){
            return this.searchStatus === submitStatus.FAILED
                ? 'There was an error fetching your search results.'
                : 'No homes found matching your search.'
        },
        loginDestination() {
          return this.getLoggedInUser ? 'add' : 'login';
        },
        promptText(){
            return this.getLoggedInUser ? 'add a home' : 'create an account'
        },
        totalSearchResults(){
            return this.numSearchResults || 0;
        },
        mostRecentSearch(){
          const queryStr = localStorage.getItem(LAST_SEARCH_KEY)
          return JSON.parse(queryStr);
        },
        isRecentSearchEmpty(){
          return this.mostRecentSearch === null || this.mostRecentSearch.query === {};
        },
        fieldLabels(){
          const labelMappings = {};
          const allFields = [
            ...this.gadOptions,
            ...this.censusOptions,
            ...this.structureOptions,
            ...this.eventOptions,
            ...this.residentOptions
          ];
          allFields.forEach(option => {
            labelMappings[option.fieldName] = option.title;
          })
          return labelMappings;
        }
    },
    methods: {
        ...mapActions(['searchForHome']),
        toggleAdvancedSearch() {
            this.showAdvanced = !this.showAdvanced;
        },
        addSearchField(option){
            if(!this.optionInUse(option)) {
                sendGoogleAnalyticsEvent(this, 'Add Extra Search Field', 'search', option.fieldName);
                this.extraFields.push(option);
            }
        },
        removeSearchField(option){
            const index = this.extraFields.indexOf(option);

            if (index > -1){
                sendGoogleAnalyticsEvent(this, 'Remove Extra Search Field', 'search', option.fieldName);
                this.extraFields.splice(index, 1);

                let fieldHadValue = false;

                if(option.isRange){
                    // remove BOTH form fields (Start and End)
                    fieldHadValue = this.form[`${option.fieldName}Start`] != null || this.form[`${option.fieldName}End`] != null;
                    delete this.form[`${option.fieldName}Start`];
                    delete this.form[`${option.fieldName}End`];
                }
                else {
                    fieldHadValue = this.form[option.fieldName] != null;
                    delete this.form[option.fieldName];
                }

                // update so search reflects removed entry
                if(fieldHadValue) {
                    this.searchClicked();
                }
            }
        },
        searchClicked(nextPage = 0){
            this.showCallToAction = false;

            const query = {};
            Object.entries(this.form).forEach(entry => {
                const key = entry[0];
                const value = entry[1];

                // format search query for MongoDB (nestedObject.field)
                if(value !== null && value !== '') {
                    // if extraFields[key].type = number, parse int
                    const isNumber = this.extraFields.find(field => {
                        return (field.fieldName === key && field.type === 'number');
                    });
                    const isRange = this.extraFields.find(field => {
                        return (key.includes(field.fieldName) && field.isRange);
                    });

                    const keyContents = key.split('_');
                    const searchKey = keyContents.length === 2 ? keyContents[0] + '.' + keyContents[1] : key;

                    if(isRange) {
                        this.addRangeToQuery(query, searchKey, value);
                    }
                    else {
                        query[searchKey] = isNumber ? parseInt(value) : value.trim();
                    }
                }
            });
            sendGoogleAnalyticsEvent(this, 'Search Clicked (search page)', 'search');

            // store search query locally so we can reload it if they come back to the search page
            this.storeQueryAsRecent(query);
            // send search query to API
            this.searchForHome({'query': query, 'nextPage': nextPage, 'shouldPaginate': !this.isMapView});
        },
        addRangeToQuery(query, key, value){
            const rangeQuery = {};

            if(key.endsWith('Start')){
                rangeQuery['$gte'] = parseInt(value);
                key = key.replace('Start', '');
            }
            else if(key.endsWith('End')){
                rangeQuery['$lte'] = parseInt(value);
                key = key.replace('End', '');
            }
            rangeQuery['$nin'] = [-1, null];
            query[key] = {...query[key], ...rangeQuery};
        },
        clearAll(){
            this.showCallToAction = false;
            this.extraFields = [];
            this.form = {};
            localStorage.removeItem(LAST_SEARCH_KEY);
            this.searchForHome({'query': {}, 'nextPage': 0, 'shouldPaginate': !this.isMapView});

        },
        optionInUse(option){
            return this.extraFields.some(item => item.fieldName === option.fieldName);
        },
        shouldShowCallToAction(){
            // Don't reset if true (set on mount)
            if (!this.showCallToAction) {
                // only show family-specific call to action if a search for family name didn't return any results
                return Object.keys(this.form).includes('occupants_lName') && this.form.occupants_lName !== "";
            }
            return true;
        },
        loadMore(){
            // for pagination. Using ints for true and false because javascript is a nightmare
            this.searchClicked(1);
        },
        toggleMapView(){
            this.isMapView = !this.isMapView;
            this.searchClicked();
        },
        mapMarkerClicked(home){
          const closeTooltip = this.selectedHome && this.selectedHome.id === home._id;

          this.selectedHome = null;

          if(closeTooltip){
              return;
          }

          this.selectedHome = {
              id: home._id,
              location: {lat: home.latitude, lng: home.longitude},
              photo: this.getFirstPhoto(home.photos),
              description: this.getTooltipText(home.structure),
              year: this.getTooltipYear(home.structure)
          };
        },
        getFirstPhoto(photoArray){
            if(photoArray.length < 1){
                return getPlaceholderUrl();
            }
            return getS3Url(photoArray[0]);
        },
        redirectToDetailPage(homeId) {
          this.$router.push({ name: 'details', params: { id: homeId }});
        },
        getTooltipText(structure) {
            const fieldExists = structure && structure.structName;

            if(fieldExists){
                const fullName = structure.structName;
                const truncatedText = fullName.length > 24 ? fullName.substring(0,23) + '...' : fullName;
                return truncatedText;
            }

            return 'Unnamed Home';
        },
        getTooltipYear(structure) {
            const fieldExists = structure && structure.year && structure.year !== -1;
            return fieldExists ? structure.year : null;
        },
        storeQueryAsRecent(query, nextPage){
          localStorage.setItem(LAST_SEARCH_KEY, JSON.stringify({
              'form': this.form || {},
              'query': query,
              'nextPage': this.isMapView ? 0 : nextPage,
              'isMapView': this.isMapView
          }));
        }
    }
}
</script>

<style scoped>
.search-container h1 {
    text-align: center;
    font-weight: normal;
}
.search-results {
    align-items: center;
    justify-content: center;
}
.main-search input{
    margin: 1rem;
}
html,
body,
.intro {
  height: 100%;
}

.form-control {
  border-color: transparent;
}

.dropdown-menu {
  width: 100%;
}

.input-group>.form-control:focus {
  border-color: transparent;
  box-shadow: inset 0 0 0 1px transparent;
}
.search-bg {
    width: 100%;
    padding: 3rem 4rem;
}
.input-group-lg {
    margin-bottom: 0.5rem;
}
.advanced-link {
    color: #006060;
    margin: 0.5rem;
    font-size: small;
}
.advanced-link:hover {
    color: #0c5460;
    text-decoration: underline;
    cursor: pointer;
}
.advanced-search-section {
    border-top: 3px solid #006060;
}
.advanced-input {
  border: 1px solid lightgrey;
}
.advanced-input:focus {
  border-color: #006060 !important;
}
.range-input {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.range-input span {
  color: darkgrey;
  font-size: large;
  margin: 0 1rem;
  display: inline-flex;
  align-items: center;
}

.delete-button {
  margin: auto 0 auto 1rem;
  font-size: x-large;
}
.delete-button:hover {
  cursor: pointer;
  color: darkred;
}
.result-message {
  margin-top: 4rem;
  text-align: center;
}

.load-more {
  margin-bottom: 2rem;
  min-width: 11rem;
}
.click-prompt {
  font-weight: bold;
}
.click-prompt:hover {
  text-decoration: underline;
}
.map-toggle-buttons {
  margin-top: -1.5rem;
  z-index: 3;
}

.map-toggle-buttons button {
  height: 3rem;
  color: black;
  background-color: white;
  border: 1px solid lightgrey;
  padding: 0 3rem;
}

.map-toggle-buttons button:hover{
  color: white;
  background-color: #0c5460;
  border: 1px solid #0c5460;
}

.map-toggle-buttons button:active {
  transform: translateY(1px);
}

.map-toggle-buttons .left {
  border-radius: 25px 0 0 25px;
}

.map-toggle-buttons .right {
  border-radius: 0 25px 25px 0;
  border-left: none;
}

.map-toggle-buttons .active {
  background-color: #418e8d;
  color: white;
  border: 1px solid #418e8d;
}

.search-grid-view, .search-map-view {
  width: 100%;
}

.search-grid-view {
  min-height: 20rem;
}

.search-map-view {
  position: relative;
}

.empty-map-message {
  position: absolute;
  width: 40%;
  height: 50%;
  z-index: 3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  text-align: center;
  border-radius: 5px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  display:flex;
  justify-content:center;
  align-items:center
}

.empty-map-message .result-message {
  margin: 0;
}

.empty-map-message a {
  color: white;
}

.empty-map-message h2 {
  margin: 0 0 1rem 0;
}

.location-map {
  height: 95vh;
  width: 100%;
  margin-top: -1.5rem;
}
.info-window-contents {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 10rem;
  overflow: hidden;
  white-space: nowrap;
}
.tooltip-img {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border: 1px solid lightgrey;
}
.tooltip-txt {
  font-weight: bold;
  width: 10rem;
}
/deep/ .gm-ui-hover-effect {
  display: none !important;
}

/deep/ .gm-style-iw-c {
  padding: 0.75rem !important;
}

/deep/ .gm-style-iw-d {
  overflow: hidden !important;
}

@media screen and (max-width: 600px) {
  .search-bg {
    padding: 0;
  }
  .container {
    padding: 1rem;
  }
  .results-grid {
    display: flex;
    flex-direction: column;
  }
  .map-toggle-buttons{
    margin: 0;
    padding: 0 !important;
    display: flex;
    flex-grow: 1;
  }
  .map-toggle-buttons button {
    border-radius: 0 !important;
    flex: 1;
  }
  .location-map {
    margin-top: 0;
    height: 50vh;
  }
  .empty-map-message {
    width: 95%;
    height: 30%;
  }
}

</style>