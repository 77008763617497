<template>
  <div>
    <h2>Location Details</h2>
    <div class="selection-details">
      <p>
        More detailed information about where the home is located. Enter manually, or click the Autofill button
        to fill in the fields based on the coordinates you provided earlier.
        <br>
        <i>As many countries subdivide themselves differently, the labels below may not match what you are expecting.
          Hover over the ‘i’ icons to see detailed descriptions of what to put in each field.</i>
      </p>
    </div>
    <div class="button-container">
      <button class="btn btn-primary" @click="autofillFromCoordinates">Autofill from Location</button>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-12">
            <label for="inputAddress" class="form-label">Address</label>
            <InfoIcon :tooltip-text="tooltips.address"/>
            <input v-model="address" type="text" class="form-control" id="inputAddress">
          </div>
          <div class="col-12">
            <label for="inputSubdivision" class="form-label">Subdivision</label>
            <InfoIcon :tooltip-text="tooltips.subdivision"/>
            <input v-model="subdivision" type="text" class="form-control" id="inputSubdivision">
          </div>
          <div class="col-12">
            <label for="inputCity" class="form-label">City/Town</label>
            <InfoIcon :tooltip-text="tooltips.city"/>
            <input v-model="city" type="text" class="form-control" id="inputCity">
          </div>
          <div class="col-12">
            <label for="inputCounty" class="form-label">County</label>
            <InfoIcon :tooltip-text="tooltips.county"/>
            <input v-model="county" type="text" class="form-control" id="inputCounty">
          </div>
          <div class="col-12">
            <label for="inputTownland" class="form-label">Townland</label>
            <InfoIcon :tooltip-text="tooltips.townland"/>
            <input v-model="townland" type="text" class="form-control" id="inputTownland">
          </div>
          <div class="col-12">
            <label for="inputTownship" class="form-label">Township</label>
            <InfoIcon :tooltip-text="tooltips.township"/>
            <input v-model="township" type="text" class="form-control" id="inputTownship">
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-12">
            <label for="inputParish" class="form-label">Parish</label>
            <InfoIcon :tooltip-text="tooltips.parish"/>
            <input v-model="parish" type="text" class="form-control" id="inputParish">
          </div>
          <div class="col-12">
            <label for="inputConcession" class="form-label">Concession</label>
            <InfoIcon :tooltip-text="tooltips.concession"/>
            <input v-model="concession" type="text" class="form-control" id="inputConcession">
          </div>
          <div class="col-12">
            <label for="inputLot" class="form-label">Lot</label>
            <InfoIcon :tooltip-text="tooltips.lot"/>
            <input v-model="lot" type="text" class="form-control" id="inputLot">
          </div>
          <div class="col-12">
            <label for="inputMunicipal" class="form-label">Municipality</label>
            <InfoIcon :tooltip-text="tooltips.municipality"/>
            <input v-model="municipal" type="text" class="form-control" id="inputMunicipal">
          </div>
          <div class="col-12">
            <label for="inputMapSheetNum" class="form-label">Map sheet number</label>
            <InfoIcon :tooltip-text="tooltips.mapSheetNum"/>
            <input v-model="mapSheetNum" type="text" class="form-control" id="inputMapSheetNum">
          </div>
          <div class="col-12">
            <label for="inputMapSeries" class="form-label">Map Series</label>
            <InfoIcon :tooltip-text="tooltips.mapSeries"/>
            <input v-model="mapSeries" type="text" class="form-control" id="inputMapSeries">
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="inputGADDescription" class="form-label">Description</label>
        <textarea v-model="gadDescription" type="text" class="form-control" id="inputGADDescription"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import {progressStatus, submitStatus} from '@/helpers/utils';
import {ROUTENAMES, TOOLTIPS} from '@/helpers/constants';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import InfoIcon from '@/components/InfoIcon';

export default {
  name: "GADForm",
  components: {InfoIcon},
  data() {
    return {
      subdivision: null,
      city: null,
      county: null,
      townland: null,
      township: null,
      parish: null,
      concession: null,
      lot: null,
      municipal: null,
      mapSheetNum: null,
      mapSeries: null,
      address: null,
      gadDescription: null,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    if (this.getHomeFormSections.gad?.data) {
      const {data} = this.getHomeFormSections.gad;
      this.subdivision = data.subdivision;
      this.city = data.city;
      this.county = data.county;
      this.townland = data.townland;
      this.township = data.township;
      this.parish = data.parish;
      this.concession = data.concession;
      this.lot = data.lot;
      this.municipal = data.municipal;
      this.mapSheetNum = data.mapSheetNum;
      this.mapSeries = data.mapSeries;
      this.gadDescription = data.gadDescription;
      this.address = data.address;
    } else {
      this.$emit('disableNext', 'Please fill in at least one field.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents()
      }
    },
    getAutofillStatus(to) {
      if (to === submitStatus.SUCCESS) {
        // set GAD details to values that got returned
        const result = this.getAutofilledLocation;
        this.subdivision = result.subdivision;
        this.city = result.city;
        this.county = result.county;
        this.townland = result.townland;
        this.township = result.township;
        this.municipal = result.municipal;
        this.address = result.address;
        this.concession = result.concession;
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please fill in at least one field.');
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({key: 'gad', data: {status: progressStatus.DONE}});
      }
    }
  },
  computed: {
    ...mapGetters([
      'getHomeToEdit',
      'getAutofillStatus',
      'getAutofilledLocation',
      'getHomeFormSections'
    ]),
    tooltips() {
      return TOOLTIPS;
    },
    wholeFormEmpty() {
      return (!this.subdivision && !this.city && !this.county && !this.townland && !this.township && !this.parish
          && !this.concession && !this.lot && !this.municipal && !this.mapSheetNum && !this.mapSeries
          && !this.gadDescription && !this.address);
    }
  },
  methods: {
    ...mapActions(['autofillLocation', 'updateFormSection']),
    savePageContents(){
      if (this.$route.name === ROUTENAMES.ADD_HOME) {
        // copy form data to the store
        const gadData = {
          subdivision: this.trimEntry(this.subdivision),
          city: this.trimEntry(this.city),
          county: this.trimEntry(this.county),
          townland: this.trimEntry(this.townland),
          township: this.trimEntry(this.township),
          parish: this.trimEntry(this.parish),
          concession: this.trimEntry(this.concession),
          lot: this.trimEntry(this.lot),
          municipal: this.trimEntry(this.municipal),
          mapSheetNum: this.trimEntry(this.mapSheetNum),
          mapSeries: this.trimEntry(this.mapSeries),
          gadDescription: this.gadDescription,
          address: this.trimEntry(this.address),
        };
        this.updateFormSection({key: 'gad', data: {data: gadData}});
        if (this.wholeFormEmpty) {
          this.updateFormSection({key: 'gad', data: {status: progressStatus.ERROR}});
        }
      }
    },
    trimEntry(str) {
      if (str) {
        return str.trim();
      }
      return str;
    },
    autofillFromCoordinates() {
      const {latitude, longitude} = this.getHomeFormSections.location.data;
      let coordinates = {
        lat: latitude,
        lng: longitude
      };
      this.autofillLocation(coordinates);
    }
  },
}
</script>

<style scoped>
.button-container {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.button-container .btn {
  font-size: 1.65rem;
  padding: 1rem 2rem;
}
</style>