<template>
  <div class="homepage-container">
    <div class="row main-text-container">
      <div class="col-12 main-text-content">
        <h1 class="title-font-bold">Do you know where your ancestors lived?</h1>
        <h2 class="title-font-light">Discover and share their story</h2>
        <div class="action-cards">
          <IconCard
            icon-name="home"
            title="Ready to add a home?"
            button-text="Add a Home"
            :button-destination="{ name: 'add' }"
          >
            <template v-bind:slot="'body'">
              <p>
                Ancestral homes can be a valuable source of historical and genealogical information, acting as reminders
                of a region's past and offering glimpses into their inhabitants' way of life.
                Sadly, due to decay or modernization, these structures often vanish over time, disappearing from both
                landscape and living memory as the years go by.
              </p>
              <p>Have a home you'd like to record? All it takes is a few simple steps.</p>
              <p>Click the button below to get started.</p>
            </template>
          </IconCard>
          <IconCard
            icon-name="search"
            title="Just Browsing?"
            button-text="Explore the Database"
            :button-destination="{ name: 'search' }"
          >
            <template v-bind:slot="'body'">
              <p>
                Whether you're an avid genealogist, an aspiring historian, or just generally curious, there is lots to
                discover in the Ancestral Homes Network database.
              </p>
              <p>
                Searching is easy, and can be tailored to meet your needs. Type in something simple, like a family name
                or country, and see what pops up. Or take advantage of our advanced search options, which boast a variety
                of specific fields to help you track down specific records with ease.
              </p>
              <p>Our database is free to use, even if you don't have an account.</p>
            </template>
          </IconCard>
        </div>
      </div>
      <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
    </div>
    <div class="row extra-text-row">
      <div class="col-12 extra-text-container text-center">
        <p>
          The Ancestral Homes Network can be used to both search for and record information about properties
          around the world, placing special importance on the sort of "everyday history" often inaccessible in larger
          historical registries- stories passed down through families or community lore, which often rely on memory to
          carry the tales from one generation to the next.
        </p>
        <p>
          Our focus is on preserving these rich histories, old and new, in order to provide both professional and amateur
          researchers with an easily accessible, ever-growing, archive of information on ancestral homes and their
          inhabitants.
          We count on users like you to help create these records, which are designed to survive the passage of time:
          a digital collection of photos, stories, census documents, and geographical information.
        </p>
        <div class="row icon-container">
          <div class="col-3 giant-icon">
            <router-link to="/add" class="add" title="Create Home Records"><font-awesome-icon icon="home"/></router-link>
            <p class="title-font">Record</p>
          </div>
          <div class="col-3 giant-icon">
            <router-link to="/login" class="join" title="Join the Network"><font-awesome-icon icon="globe"/></router-link>
            <p class="title-font">Connect</p>
          </div>
          <div class="col-3 giant-icon">
            <router-link to="/search" class="search" title="Search for Homes"><font-awesome-icon icon="search"/></router-link>
            <p class="title-font">Search</p>
          </div>
          <div class="col-3 giant-icon">
            <router-link to="/about" class="about" title="Learn More"><font-awesome-icon icon="book"/></router-link>
            <p class="title-font">Learn</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 home-photo-container secondary-bg">
        <div class="homes-group">
            <div class="row" v-if="homes.length > 0">
              <div v-for="(home, index) in homes" :key="index" :class="[{'col-3': !isMobile}, {'col-12': isMobile}]">
                <HomeCard class="home-card" :home="home"/>
              </div>
            </div>
            <div v-else-if="homesLoading" class="col-12 d-flex justify-content-center">
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else>
              <h3>No homes found.</h3>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center create-account-content secondary-bg">
        <p class="no-space-below">The epitaphs on cemetery tombstones are precious records of ancestral lives once lived. Of equal value is the memory of the old walls where those lives had their origins.</p>
        <p>Our goal, over time, is to preserve as many of these memories as we can, one ancestral home at a time, and we need your help to do it.</p>
        <p>Not sure where to start?</p>
        <p>Click the button below, and together we'll immortalize a chapter of your family's history.</p>
        <button class="btn btn-lg btn-light account-button" @click="redirectToRegisterPage">Create an Account</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { submitStatus, sendGoogleAnalyticsEvent, sendGoogleAdsConversion } from '@/helpers/utils'
import HomeCard from '@/components/home/HomeCard.vue';
import IconCard from "@/components/IconCard";

export default {
  name: 'home',
  components: {IconCard, HomeCard },
  data() {
      return {
          searchName: null,
          homes: [],
          homesLoading: false,
          windowWidth: null,
      }
  },
  mounted(){
      this.$gtag.pageview('Home');
      sendGoogleAdsConversion(this);
      // can load more recent homes by passing in a number but the default is 4
      this.getRecentHomes();
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
  },
  destroyed () {
      window.removeEventListener('resize', this.handleResize);
  },
  watch: {
      searchStatus(to) {
          if(to === submitStatus.SUCCESS){
              this.homes = this.searchResults;
              this.homesLoading = false;
          }
          else if(to === submitStatus.LOADING){
              this.homesLoading = true;
          }
          else if(to === submitStatus.FAILED){
              this.homesLoading = false;
          }
      }
  },
  computed: {
      ...mapGetters(['getLoggedInUser', 'searchStatus', 'searchResults']),
      loginDestination() {
          return this.getLoggedInUser ? 'profile' : 'login';
      },
      isMobile(){
          return this.windowWidth < 600;
      }
  },
  methods: {
      ...mapActions(['getRecentHomes']),
      searchByFamilyName(){
          sendGoogleAnalyticsEvent(this, 'Search Clicked (home page)', 'search', this.searchName);
          const searchQuery = this.searchName === null ? {} : { 'occupants.lName': this.searchName };
          this.$router.push({ name: 'search', params: { searchQuery: searchQuery } });
      },
      handleResize(){
          this.windowWidth = window.innerWidth;
      },
      redirectToRegisterPage(){
          sendGoogleAnalyticsEvent(this, 'Register (home page)', 'sign_up');
          this.$router.push({ name: this.loginDestination, params: { register: true }});
      }
  }
};
</script>
<style scoped>
.homepage-container {
  display: flex;
  flex-direction: column;
}

/*Top section*/
.action-cards {
  display: flex;
  flex-direction: row;
}
.main-text-container {
  position: relative;
  height: 95vh;
  overflow-x: hidden;
  align-items: center;
}
.main-text-content {
  z-index: 2;
  text-align: center;
}
.main-text-content h1 {
  text-transform: uppercase;
  font-size: 3vw;
  white-space: nowrap;
}
.main-text-content h2 {
  margin-top: 1rem;
  color: #006060;
  font-size: 2.3vw;
  font-weight: normal;
}
.bg-img-bottom {
  opacity: 0.80;
  width: auto;
}
@media screen and (max-width: 600px) {
  .main-text-container {
    height: auto;
  }
  .main-text-content {
    margin-left: 0;
    margin-top: 4rem;
  }
  .main-text-content h1 {
    white-space: normal;
    font-size: xx-large;
    margin-bottom: 1rem;
  }
  .main-text-content h2 {
    font-size: x-large;
  }
  .action-cards {
    flex-direction: column;
  }
}

/*About section*/
.extra-text-row {
  padding: 5rem 5rem 3rem 5rem;
}
.extra-text-container {
  margin-top: auto;
  margin-bottom: auto;
}
.extra-text-container h1 {
  margin-bottom: 2rem;
}
.extra-text-container p {
  font-size: large;
}
.giant-icon {
  font-size: 15vh;
}
.giant-icon p {
  font-size: large;
  text-transform: uppercase;
  color: #006060;
}
.icon-container {
  margin: 4rem 2rem 0 2rem;
}
@media screen and (max-width: 600px) {
  .extra-text-row {
    padding: 2rem 1rem;
  }
  .icon-container{
    margin: 0;
  }
  .giant-icon {
    font-size: 12vw;
  }
  .giant-icon p {
    display: none;
  }
}

/*Home photo section*/
.home-photo-container {
  padding: 0.5rem 1rem 1rem 1rem;
}
.homes-group {
  padding-top: 1rem;
}
.home-card:first-child{
  margin-left: 0 !important;
}
.homes-group > .row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
}
.homes-group > .row > .home-card {
  flex: 0 0 auto;
  margin: 0 0.5rem;
}

/*Account section*/
.account-button {
  margin-top: 1rem;
  color: #003536;
}
.create-account-content {
  padding: 3rem;
}
.no-space-below {
  margin-bottom: 0;
}
</style>