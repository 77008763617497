<template>
  <div class="card">
    <div class="top-icon-container">
      <font-awesome-icon :icon="iconName" :style="iconStyle" class="top-icon" />
    </div>
    <h5 class="card-title title-font" :style="iconStyle">{{title}}</h5>
    <div class="card-body">
      <slot name="body"/>
      <router-link :to="buttonDestination">
        <button class="btn btn-primary btn-lg">{{buttonText}}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconCard",
  props: {
   iconName: { type: String, required: true },
   iconColour: { type: String, default: '#006060' },
   title: { type: String, default: null },
   buttonText: { type: String, default: 'Select' },
   buttonDestination: { type: Object, default: null }
  },
  computed: {
    iconStyle(){
      let colourCode = this.iconColour;
      if(this.iconColour === 'primary'){
        colourCode = '#006060'
      }
      else if(this.iconColour === 'secondary'){
        colourCode = '#6c757d'
      }
      return { color: colourCode };
    }
  }
}
</script>

<style scoped>
a {
  width: 100%;
}

.top-icon-container {
  font-size: 16vh;
  text-align: center;
  margin-top: -5rem;
}

.card {
  margin: 4rem 1rem 1rem 1rem;
  align-items: center;
  z-index: 1;
  width: 100%;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-title {
  text-transform: capitalize;
  font-size: xx-large;
  text-align: center;
}

.card-body {
  position: relative;
  width: 100%;
  padding: 0 4rem 5rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4rem;
  font-size: x-large;
  border-radius: 0 0 0.2rem 0.2rem;
}

@media screen and (max-width: 600px) {
  .card {
    margin: 2rem 0.5rem 0.5rem 0.5rem;
    width: auto;
  }
  .top-icon-container {
    font-size: 8vh;
    margin-top: -2rem;
  }
  .card-body {
    padding: 1rem;
    padding-bottom: 4rem !important;
  }
}
</style>