<template>
    <div class="my-homes-container page-bg">
        <h2 class="homes-title">Your Homes</h2>
        <div class="inner-homes-scrollable row light-teal-bg">
            <div :class="['col-12', 'homes-grid', {'no-homes': noHomesFound}]">
                <div v-if="noHomesFound" class="no-homes-text">
                    <h4>You haven't created any homes yet.</h4>
                    <p>Help us grow the Archive by adding your own entries to our collection.
                        <router-link to="/add">Click here</router-link> to get started.</p>
                </div>
                <div v-else-if="myHomes.length === 0 && resultsLoading" class="spinner-container d-flex justify-content-center">
                    <div class="spinner-border text-info" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div v-else class="results-grid">
                    <SearchResultCard class="short-card" v-for="home in myHomes" :key="home.id" :home="home"/>
                </div>
                <div v-if="canLoadMore && !resultsLoading">
                  <div class="col-12 text-center">
                    <button class="btn btn-lg btn-primary load-more" @click="getOwnHomes(1)">Load More</button>
                  </div>
                </div>
                <div v-if="myHomes.length !== 0 && resultsLoading" class="bottom-spinner text-center">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchResultCard from '../search/SearchResultCard';
import { submitStatus } from '../../helpers/utils';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
    name: "MyHomes",
    components: { SearchResultCard },
    data() {
        return {
            myHomes: [],
            showInfoText: false,
            resultsLoading: false,
        }
    },
    mounted() {
        this.$gtag.pageview('User Profile - Your Homes');
        // get homes belonging to user
        const user = this.getLoggedInUser;
        if(user){
            this.getOwnHomes();
        }
    },
    watch: {
        searchStatus(to) {
            if(to === submitStatus.SUCCESS){
                this.myHomes = this.searchResults;
                this.resultsLoading = false;
            }
            else if(to === submitStatus.FAILED){
                this.resultsLoading = false;
            }
            else if(to === submitStatus.LOADING){
                this.resultsLoading = true;
            }
        },
    },
    computed: {
        ...mapGetters(['getLoggedInUser', 'searchStatus', 'searchResults', 'canLoadMore']),
        userEmail() {
            return this.getLoggedInUser.profile.email
        },
        userID(){
            return this.getLoggedInUser.id;
        },
        noHomesFound(){
            return this.myHomes.length === 0 && !this.resultsLoading;
        }
    },
    methods: {
        ...mapActions(['searchForHome']),
        getOwnHomes(shouldGetNextPage = 0){
            // 0 means it's a brand new search, 1 is for if you're getting the next page of results
            this.searchForHome({'query': {'owner_id': this.userID}, 'nextPage': shouldGetNextPage});
        },
    }
}
</script>

<style scoped>
.my-homes-container {
    height: 97%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}
.inner-homes-scrollable{
    overflow: hidden;
    overflow-y: scroll;
    flex: 1;
    margin: 1rem 0 0 0;
    max-height: 85vh;
    border: #e6f7fa 1px solid;
}
.homes-title {
    margin-bottom: 0;
}
.homes-grid {
    padding: 0;
    height: 100%;
}
.results-grid {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}
.short-card /deep/ img {
    height: 10rem;
}
.buttons .btn {
    margin: 1rem;
}
.btn {
    min-width: 11rem;
}
.load-more {
    margin-bottom: 1rem;
}
.spinner-container {
    height: 100%;
    align-items: center;
}
.no-homes {
    display: flex;
    align-items: center;
    text-align: center;
}
.no-homes-text {
    width: 100%;
}
</style>