<template>
  <div class="submit-section">
    <font-awesome-icon icon="home" class="giant-icon"/>
    <h2>{{ submitStatusText }}</h2>
    <router-link v-if="linkToHome" :to="linkToHome" class="see-home-btn">
      <button class="btn btn-lg btn-primary">See it on the site</button>
    </router-link>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DRAFT_STORAGE_KEY, progressStatus, submitStatus} from "@/helpers/utils";

export default {
  name: "SubmitSection",
  data() {
    return {
      formData: {},
    }
  },
  props: {
    editId: { type: String, default: null }
  },
  mounted() {
    this.updateFormSection({key: 'review', data: {status: progressStatus.DONE}});

    this.formData = this.formatHomeData();
    this.formData['owner_id'] = this.getLoggedInUser.id;

    if(this.editId){
      this.updateHome({ data: this.formData, id: this.editId });
    }
    else {
      this.submitHome(this.formData);
    }
  },
  beforeDestroy() {
    this.updateFormSection({key: 'submit', data: null});
  },
  watch: {
    homeSubmitStatus(to) {
      if (to === submitStatus.SUCCESS) {
        this.updateFormSection({key: 'submit', data: {status: progressStatus.DONE}});
        // if this was originally a draft, delete the corresponding draft
        this.removeDraftIfJustSubmitted();
      } else if (to === submitStatus.FAILED) {
        this.updateFormSection({key: 'submit', data: {status: progressStatus.ERROR}});
      }
    }
  },
  computed: {
    ...mapGetters(['getHomeFormSections', 'getLoggedInUser', 'homeSubmitStatus', 'getCreatedHomeId']),
    submitStatusText() {
      switch (this.homeSubmitStatus) {
        case submitStatus.LOADING:
          return 'Submitting home...'
        case submitStatus.FAILED:
          return 'Failed to submit home to the database.'
        case submitStatus.SUCCESS:
          return this.editId ? 'Home updated!' : 'Home submitted!'
        default:
          return 'Submitting home...'
      }
    },
    linkToHome() {
      if (this.getCreatedHomeId) {
        return `/details/${this.getCreatedHomeId}`
      }
      return null;
    }
  },
  methods: {
    ...mapActions(['updateFormSection', 'submitHome', 'updateHome']),
    formatHomeData() {
      let formattedHome = {}
      Object.entries(this.getHomeFormSections).forEach(([key, value]) => {
        if (key === 'location') {
          formattedHome = {...formattedHome, ...value.data};
        } else if (key === 'gad') {
          const defaultObj = {
            subdivision: null,
            city: null,
            county: null,
            townland: null,
            township: null,
            parish: null,
            concession: null,
            lot: null,
            municipal: null,
            mapSheetNum: null,
            mapSeries: null,
            address: null,
            gadDescription: null
          }
          formattedHome.gad = value?.data || defaultObj;
        } else if (key === 'census') {
          formattedHome.censuses = value?.data || [];
        } else if (key === 'occupants') {
          formattedHome.occupants = value?.data || [];
        } else if (key === 'structure') {
          const defaultStruct = {
            floorDesc: null,
            numDoors: null,
            numRooms: null,
            numWindows: null,
            roofDesc: null,
            structDesc: null,
            structName: null,
            wallDesc: null,
            year: null
          }
          formattedHome.structure = value?.data || defaultStruct;
        } else if (!['review', 'submit', 'selectDetails'].includes(key)) {
          formattedHome[key] = value?.data || [];
        }
      })
      return formattedHome;
    },
    removeDraftIfJustSubmitted(){
      const draftStr = localStorage.getItem(DRAFT_STORAGE_KEY);
      const currentDraft = JSON.parse(draftStr);

      const latIsSame = currentDraft?.location?.data?.latitude === this.formData.latitude;
      const longIsSame = currentDraft?.location?.data?.longitude === this.formData.longitude;

      if(latIsSame && longIsSame){
        localStorage.removeItem(DRAFT_STORAGE_KEY);
      }
    }
  }
}
</script>

<style scoped>
.submit-section {
  height: 60vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.giant-icon {
  font-size: 30vh;
  margin-bottom: 1rem;
  color: #006060;
}

.see-home-btn {
  margin-top: 1rem;
}
</style>