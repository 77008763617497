import { render, staticRenderFns } from "./GADForm.vue?vue&type=template&id=1bfdbc67&scoped=true"
import script from "./GADForm.vue?vue&type=script&lang=js"
export * from "./GADForm.vue?vue&type=script&lang=js"
import style0 from "./GADForm.vue?vue&type=style&index=0&id=1bfdbc67&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bfdbc67",
  null
  
)

export default component.exports