<template>
<div class="structure-detail-view row">
  <div v-if="screenIsSmall" class="col-12">
    <div v-for="category in categories" :key="category.label">
      <span class="field-name">{{category.label}}: </span>
      <span>{{formatValue(sectionData[category.key])}}</span>
    </div>
  </div>
  <div v-else class="structure-numbers d-flex col-12">
    <div class="number-section" v-for="category in categories" :key="category.label">
      <span class="field-name">{{category.label}}</span>
      <span>{{formatValue(sectionData[category.key])}}</span>
    </div>
  </div>
  <div class="col-12 text-section">
    <div class="description-section">
      <p v-if="sectionData.structDesc">{{sectionData.structDesc}}</p>
    </div>
    <div v-for="(key, index) in remainingSectionData" :key="index">
      <div v-if="sectionData[key]" class="structure-text">
        <span class="field-name">{{formatKey(key)}}: </span>
        <span>{{sectionData[key] || '-'}}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {detailPageSections, stringFormatter} from "@/helpers/utils";

export default {
  name: "StructureDetailView",
  props: {
    sectionData: { type: Object, default: () => {} },
  },
  data() {
    return {
      categories: [
        {label: 'Year', key: 'year'},
        {label: 'Number of Doors', key: 'numDoors'},
        {label: 'Number of Windows', key: 'numWindows'},
        {label: 'Number of Rooms', key: 'numRooms'},
      ],
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    // add listener in case user resizes the window
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    remainingSectionData(){
      const remainingKeys = []
      const alreadyUsed = this.categories.map(category => category.key);
      alreadyUsed.push('structName');
      alreadyUsed.push('structDesc');

      const allKeys = Object.keys(this.sectionData);
      allKeys.forEach((key) => {
        if(!alreadyUsed.includes(key)){
          remainingKeys.push(key);
        }
      });

      return remainingKeys;
    },
    screenIsSmall() {
      return this.windowWidth <= 600;
    },
  },
  methods: {
    formatKey(key) {
      const specialFormattedKeys = detailPageSections['structure'].sectionLabels;
      const isSpecialKey = Object.keys(specialFormattedKeys).includes(key);
      return isSpecialKey ? specialFormattedKeys[key] : stringFormatter.toSentenceCase(key);
    },
    formatValue(value){
      return value && value !== -1 ? value : '-';
    }
  }
}
</script>

<style scoped>
.field-name {
  font-size: large;
  font-weight: bolder;
  color: #0c5460;
}
.structure-numbers {
  justify-content: space-between;
}
.number-section {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.structure-text {
  display: flex;
  flex-direction: column;
}
.description-section {
  margin-top: 1rem;
}
</style>