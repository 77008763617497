<template>
    <div class="card text-white" @click="redirectToDetailPage">
      <img v-if="imageSrc" class="card-img-top" :src="imageSrc" alt="Unable to load home photos" @error="showErrorImage">
      <img v-else class="card-img-top" src="../../assets/app_icon_512.png" alt="No home photos to show">
      <div class="card-img-overlay">
          <h5 class="card-title">{{cardTitleText}}</h5>
          <p class="card-text">{{cardContent}}</p>
      </div>
    </div>
</template>

<script>
import { getS3Url, getImgErrorUrl, sendGoogleAnalyticsEvent } from '../../helpers/utils'

export default {
    name: "HomeCard",
    data() {
        return {
            placeholderUrl: "/img/app_icon_512.42c7de1c.png"
        }
    },
    props: {
        home: { type: Object, default: () => {} }
    },
    computed: {
        imageSrc() {
            // used to be : placeholderUrl but making it dynamic is a pain in the butt I'll save for later
            const imgToLoad = this.home.photos && this.home.photos[0] ? getS3Url(this.home.photos[0]) : null;
            return imgToLoad;
        },
        cardTitleText() {
            const { home } = this;

            let text = home.country;
            if (home.gad && home.gad.city) {
                text = `${home.gad.city}, `+text;
            }
            return text;
        },
        cardContent() {
            return this.yearIsNotEmpty ? this.home.structure.year : '';
        },
        yearIsNotEmpty() {
            const { home } = this;
            return home.structure && home.structure.year && home.structure.year !== -1;
        }
    },
    methods: {
        redirectToDetailPage() {
            sendGoogleAnalyticsEvent(this, 'Home Card Clicked (home page)', 'view_item', this.home._id );
            this.$router.push({ name: 'details', params: { id: this.home._id }});
        },
        showErrorImage(event){
            event.target.src=getImgErrorUrl();
        }
    }
}
</script>

<style scoped>
.card {
    width: 100%;
    height: 13rem;
}
.card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-img-overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.card-img-overlay:hover {
    background-color: rgba(0, 89, 107, 0.62);
    transition: opacity 0.75s;
    opacity: 1;
}
.card {
    border: 5px solid white;
}
</style>