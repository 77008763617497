<template>
  <div class="home-detail-page">
    <div class="main-details row">
      <div
        :class="['col-12', 'share-status', {'can-share': canShareOnSocial}, {'cannot-share': !canShareOnSocial}]"
        v-if="userIsAdmin"
      >
        <font-awesome-icon class="share-icon" icon="bullhorn"/>
        <p v-if="canShareOnSocial">This home is safe to share on social media.</p>
        <p v-else>You do not have permission to share this home on social media.</p>
      </div>
    <!--Left side - carousel of home photos-->
    <div class="col-lg-7 col-md-12 img-main">
      <ImageCarousel class="image-carousel" :image-list="home.photos"/>
    </div>
    <!--right side - text-->
    <div class="col-lg-5 col-md-12 text-main" ref="textMain">
      <div class="col-12 country-section" ref="countrySection">
        <h3>{{ detailPageTitle }}</h3>
        <h5>{{ latLongStr }}</h5>
      </div>
      <div class="col-12 main-text-container" :style="{ height: mainSectionSpaceLeft+'%' }">
        <h5>Family Names</h5>
        <div v-if="familyNamesList.length > 0">
          <button class="btn btn-sm btn-primary family-button" v-for="(name, index) in familyNamesList" :key="index">
            <a href="#occupants" role="button">{{ name }}</a>
          </button>
        </div>
        <div v-else>
          <p>No occupant information has been recorded for this home yet.</p>
        </div>
        <h5 class="section-header">Key Events</h5>
        <div v-if="eventsChronological.length > 0">
          <p class="info-text">{{timelineText}}</p>
          <div v-if="screenIsSmall">
            <button
              :class="[
                'btn', 'btn-sm', 'family-button',
                {'btn-primary': index !== eventIndex},
                {'btn-outline-primary': index === eventIndex}
              ]"
              v-for="(event, index) in eventLabels"
              :key="index"
              @click="navToEvent(index)"
            >
              {{event.label}}
            </button>
          </div>
          <div v-else>
            <div>
              <ProgressBar
                  :current-page-index="eventIndex"
                  :progress-nodes="eventLabels"
                  no-validation
                  small
                  @nodeClicked="navToEvent"
              />
            </div>
          </div>
          <p>{{ eventsChronological[eventIndex].eventDesc }}</p>
        </div>
        <div v-else>
          <p>No key events have been recorded for this home yet.</p>
        </div>
        <h5 class="section-header">Explore More</h5>
        <p class="info-text">Scroll down or click the buttons below to see additional details about this home.</p>
        <button v-for="(section, index) in completedSections" :key="index" class="btn btn-secondary family-button">
          <a :href="`#${section.key}`" role="button">{{ section.label }}</a>
        </button>
      </div>
    </div>
    </div>
    <div class="row">
    <div class="extra-details-container col-12">
        <div class="info-category light-teal-bg" v-for="(section, index) in completedSections" :key="index">
          <div class="d-flex">
            <h4 :id="section.key">{{section.label}}</h4>
            <InfoIcon :tooltip-text="section.info"/>
          </div>
          <DetailTable v-if="section.useTable" :table-contents="getTableData(section.key)" :type="section.key"/>
          <div v-else-if="section.customComponent">
            <div class="custom-view">
              <component
                :is="section.customComponent"
                :section-data="home[section.key]"
                :coordinates="{lat: home.latitude, lng: home.longitude}"
              />
            </div>
          </div>
          <div v-else>
            <div v-for="(value, key) in home[section.key]" :key="key">
              <h5>{{formatSectionKey(key, section.key)}}</h5>
              <p>{{value}}</p>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import { getS3Url, getPlaceholderUrl, stringFormatter, detailPageSections } from '@/helpers/utils'
import ImageCarousel from "../components/ImageCarousel.vue";
import { mapActions, mapGetters } from "vuex";
import ProgressBar from "@/components/ProgressBar";
import InfoIcon from "@/components/InfoIcon";
import DetailTable from "@/components/details/DetailTable.vue";
import LocationDetailView from "@/components/details/LocationDetailView.vue";
import StructureDetailView from "@/components/details/StructureDetailView.vue";
import {isEmpty, isEqual} from "lodash";

export default {
  name: "HomeDetails",
  components: {DetailTable, InfoIcon, ProgressBar, ImageCarousel, LocationDetailView, StructureDetailView},
  props: {
    home: {type: Object, required: true},
  },
  data() {
    return {
      activeTab: 'gad',
      windowWidth: window.innerWidth,
      eventIndex: 0,
      mainSectionSpaceLeft: 100,
    }
  },
  mounted() {
    // add listener in case user resizes the window
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.setTextHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    home(to) {
      const ownerID = to.owner_id;
      if (!this.getAllUserSettings || !this.getAllUserSettings[ownerID]) {
        this.getUserSettings(ownerID);
      }
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getAllUserSettings']),
    detailPageTitle() {
      const {home} = this;
      const namePrefix = home.structure && home.structure.structName ? `${home.structure.structName}, ` : '';
      return `${namePrefix}${home.country || '-'}`;
    },
    imageSrc() {
      const imgToLoad = this.home.photos && this.home.photos[0] ? getS3Url(this.home.photos[0]) : getPlaceholderUrl();
      return imgToLoad;
    },
    latLongStr() {
      if (!(this.home.latitude || this.home.longitude)) {
        return '-';
      }
      return stringFormatter.toLocation(this.home.latitude, this.home.longitude);
    },
    eventLabels() {
      return this.eventsChronological.map(event => {
        const label = event.yearRecorded && event.yearRecorded !== -1 ? event.yearRecorded : 'No Date';
        return {label}
      });
    },
    eventsChronological() {
      return this.orderByYear(this.home.events, 'yearRecorded');
    },
    screenIsSmall() {
      return this.windowWidth <= 600;
    },
    censusList() {
      //todo: figure out why it's adding empty links to old census
      const {censuses, census} = this.home;

      if (censuses && censuses.length > 0) {
        return this.orderByYear(censuses, 'year');
      }
      // accommodate old format if necessary
      return census ? [census] : [];
    },
    canShareOnSocial() {
      const allSettings = this.getAllUserSettings;
      const ownerSettings = allSettings[this.home.owner_id];
      return ownerSettings ? ownerSettings.consents_to_sharing : false;
    },
    userIsAdmin() {
      const user = this.getLoggedInUser;
      return user && user.permissions && user.permissions.is_admin;
    },
    familyNamesList() {
      const names = this.home?.occupants.map((person) => {
        return person.lName ? stringFormatter.toSentenceCase(person.lName) : null;
      }) || [];
      const uniqueNames = new Set(names.filter(name => name !== null));
      return Array.from(uniqueNames);
    },
    completedSections() {
      const keysToShow = Object.keys(detailPageSections);

      const keysWithValues = Object.keys(this.home).filter(key => {
        const value = this.home[key];

        const isEmptyArray = Array.isArray(value) && value.length === 0;
        const isEmptyCensus =
            key === 'census' &&
            (isEqual({pdfLinks: []}, value) || isEqual({year: -1, pdfLinks: []}, value) || isEqual({pdfLinks: [], year: null, countryLink: null, townlandLink: "", residentsLink: ""}, value) || isEqual({year: null, pdfLinks: []}, value));
        const isEmptyStructure =
            key === 'structure' &&
            isEqual({numDoors: -1, numRooms: -1, numWindows: -1, year: -1}, value);

        return keysToShow.includes(key) && !isEmptyArray && !isEmptyCensus && !isEmptyStructure && !isEmpty(value);
      })

      return keysWithValues.map(key => {
        const detailSection = detailPageSections[key];
        return {...detailSection, key}
      });
    },
    timelineText(){
      const includeTimeline = this.screenIsSmall ? '' : 'on the timeline ';
      return `Click a date ${includeTimeline}to learn more about important events in the history of this home.`;
    },
  },
  methods: {
    ...mapActions(['getUserSettings']),
    navToEvent(index) {
      this.eventIndex = index;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    orderByYear(list, key) {
      if (!list) {
        return [];
      }
      const orderedEvents = [...list];
      orderedEvents.sort((a, b) => {
        const yearA = new Date(a[key] + '');
        const yearB = new Date(b[key] + '');
        if (yearA < yearB) {
          return -1;
        }
        return 1;
      });
      return orderedEvents;
    },
    formatSectionKey(key, section){
      const specialFormattedKeys = detailPageSections[section].sectionLabels;
      const isSpecialKey = Object.keys(specialFormattedKeys).includes(key);
      return isSpecialKey ? specialFormattedKeys[key] : stringFormatter.toSentenceCase(key);
    },
    getTableData(sectionKey){
      return ['census', 'censuses'].includes(sectionKey) ? this.censusList : this.home[sectionKey];
    },
    setTextHeight(){
      if(this.$refs.countrySection) {
        const titleHeight = this.$refs.countrySection.clientHeight + 16;
        const sectionHeight = this.$refs.textMain.clientHeight;
        const spaceLeft = sectionHeight - titleHeight
        this.mainSectionSpaceLeft = (spaceLeft / sectionHeight) * 100;
      }
    }
  }
}
</script>

<style scoped>
.img-main .image-carousel {
  border: 1px solid #f5f5f5;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-main, .img-main {
  max-height: 70vh;
  overflow-y: hidden;
}

.country-section {
  margin-bottom: 1rem;
}

.main-text-container {
  border-top: 1px solid #dee2e6;
  padding: 1rem;
  overflow-y: scroll;
  /*height: 85%;*/
}

.info-text {
  font-size: small;
  color: #5a6268;
  margin-top: 0;
}

.family-button {
  margin: 0 0.5rem 0.5rem 0;
}

.section-header {
  margin-top: 2rem;
}

.extra-details-container {
  margin-top: 2rem;
}

.info-category {
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 1rem;
}

.share-status {
  display: flex;
  flex-direction: row;
  line-height: 1;
}

.share-icon {
  margin-right: 0.5rem;
}

.can-share {
  color: #1e7e34;
}

.cannot-share {
  color: darkred;
}

a {
  color: white;
}

@media screen and (max-width: 600px) {
  .img-main .image-carousel {
    height: 18rem;
    margin-bottom: 1rem;
  }
  .text-main {
    max-height: 100%;
  }
}
</style>