<template>
    <div class="detail-table-container">
        <table v-if="tableContents.length > 0" class="table table-container">
            <thead>
                <tr>
                    <th v-for="(key, index) in tableHeaders" :key="index" scope="col">
                        {{key}}
                        <InfoIcon v-if="key === 'Supporting Documents'" :tooltip-text="supportingDocText"/>
                    </th>
                </tr>
            </thead>
            <tbody v-if="type === 'events'">
                <EventRow v-for="(item, index) in tableContents" :key="index" :event="item"/>
            </tbody>
            <tbody v-else-if="type === 'occupants'">
                <ResidentRow v-for="(item, index) in tableContents" :key="index" :resident="item"/>
            </tbody>
            <tbody v-else-if="type === 'references'">
                <ReferenceRow v-for="(item, index) in tableContents" :key="index" :reference="item"/>
            </tbody>
            <tbody v-else>
                <CensusRow v-for="(item, index) in tableContents" :key="index" :census="item"/>
            </tbody>
        </table>
        <h5 v-else>This section is empty.</h5>
    </div>
</template>

<script>
import { objectFormat } from '../../helpers/utils';
import EventRow from "./EventRow";
import ResidentRow from "./ResidentRow";
import CensusRow from "./CensusRow";
import ReferenceRow from "./ReferenceRow";
import InfoIcon from "../InfoIcon";

export default {
    name: "DetailTable",
    components: {InfoIcon, ReferenceRow, CensusRow, ResidentRow, EventRow },
    data() {
        return {
            supportingDocText: 'Clicking on one of the page icons, if present, will download the relevant census ' +
                                'document to your computer as a PDF.'
        }
    },
    props: {
        tableContents: { type: Array, default: () => [] },
        type: { type: String, default: null},
    },
    computed: {
        tableHeaders() {
            switch (this.type){
                case 'events':
                    return this.getLabels(objectFormat.EVENT_FIELDS);
                case 'occupants':
                    return ['First Name', 'Last Name', 'Occupancy Period', 'Birth Date', 'Death Date', 'Gravesite', 'Additional Information'];
                case 'census':
                case 'censuses':
                    return [...this.getLabels(objectFormat.CENSUS_FIELDS), 'Supporting Documents'];
                case 'references':
                    return this.getLabels(objectFormat.REFERENCE_FIELDS);
                default:
                    return [];
            }
        }
    },
    methods: {
        getLabels(fieldArray){
            let labels = [];
            fieldArray.forEach(eventField => {
                labels.push(eventField.label);
            });
            return labels;
        }
    }
}
</script>

<style scoped>
.detail-table-container {
  overflow-x: scroll;
}
</style>