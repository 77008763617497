<template>
<div>
  <h2>Review</h2>
  <div class="selection-details">
    <p>
      This is what your home will look like once it is published to the site.
      Please review it to ensure that all details are correct, then click {{actionMessage}} your home.
      <br>
      <i>You can click on the teal section headers (Events, Residents, Census, and References Used) to expand each
      section and see what's inside.</i>
    </p>
    <Alert v-if="hasErrors" permanent :alert-type="'danger'">
      <h4>You cannot submit this home yet!</h4>
      <p>
        In order to submit this home to the Ancestral Homes Network database, all sections of this form must be filled
        out correctly. Please check the progress bar above for details- grey dots indicate that section information is
        missing, while red ones indicate an issue with data that has been entered there. You can click on the dots to
        jump right to a specific section.
      </p>
      <br>
      <p>
        If you are not sure how to proceed, we recommend that you save your progress as a draft so you can access it later.
        Use the 'Need Help?' button at the top right corner of the page to send us a message, and we will get back to
        you as soon as we can.
      </p>
    </Alert>
  </div>
  <hr>
  <HomeDetails :home="home"/>
</div>
</template>

<script>
import HomeDetails from "@/views/HomeDetails";
import { mapGetters } from "vuex";
import Alert from "@/components/Alert";
import { progressStatus } from "@/helpers/utils";

export default {
  name: "ReviewDetailWrapper",
  components: {Alert, HomeDetails },
  props: {
    editId: { type: String, default: null }
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    actionMessage(){
      return this.editId ? "'Save Changes' to update" : "'Submit' to publish"
    },
    home(){
      let formattedHome = {}
      Object.entries(this.getHomeFormSections).forEach(([key, value]) => {
        if (key === 'location') {
          formattedHome = {...formattedHome, ...value.data};
        } else if (key === 'gad') {
          const defaultObj = {
            subdivision: null,
            city: null,
            county: null,
            townland: null,
            township: null,
            parish: null,
            concession: null,
            lot: null,
            municipal: null,
            mapSheetNum: null,
            mapSeries: null,
            address: null,
            gadDescription: null
          }
          formattedHome.gad = value?.data || defaultObj;
        } else if (key === 'census') {
          formattedHome.censuses = value?.data || [];
        } else if (key === 'occupants') {
          formattedHome.occupants = value?.data || [];
        } else if (key === 'structure') {
          const defaultStruct = {
            floorDesc: null,
            numDoors: null,
            numRooms: null,
            numWindows: null,
            roofDesc: null,
            structDesc: null,
            structName: null,
            wallDesc: null,
            year: null
          }
          formattedHome.structure = value?.data || defaultStruct;
        } else if (!['review', 'submit', 'selectDetails'].includes(key)) {
          const emptyVal = key === 'structure' ? {} : [];
          formattedHome[key] = value?.data || emptyVal;
        }
      })
      return formattedHome;
    },
    hasErrors(){
      let hasErrors = false;
      Object.entries(this.getHomeFormSections).forEach(([key, value])=> {
        if(value && !['review', 'submit', 'selectDetails'].includes(key)){
          if(!value?.data || value?.status !== progressStatus.DONE){
            hasErrors = true;
            this.$emit('disableNext', 'Please fill in all sections and fix all errors before submitting.');
          }
        }
      });

      if(!hasErrors){
        this.$emit('disableNext', null);
      }
      return hasErrors;
    }
  }
}
</script>