<template>
  <div>
    <h2>Family & Property Events</h2>
    <div class="selection-details">
      Noteworthy historical or personal events that impacted the property or its inhabitants.
    </div>
    <div class="row top-section">
      <table v-if="events.length > 0" class="table table-container">
        <thead>
        <tr>
          <th scope="col">Year</th>
          <th scope="col">Description</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event, index) in events" :key="index"
            :class="{'light-teal-bg-selected': index === entryBeingEdited}">
          <td>{{ event.yearRecorded || '-' }}</td>
          <td>{{ event.eventDesc || '-' }}</td>
          <td>
            <div class="event-form-buttons float-right">
              <a class="edit-icon" title="Edit" @click="editEvent(index)">
                <font-awesome-icon icon="edit"/>
              </a>
              <a class="delete-icon" title="Delete" @click="removeEventFromTable(index)">
                <font-awesome-icon icon="trash"/>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="!showForm" class="add-event-container" @click="toggleForm">
        <p>Click to add an event</p>
      </div>
    </div>
    <div v-if="showForm" class="row">
      <div class="col-lg-2 col-sm-12">
        <ValidationInput
            label="Year"
            type="number"
            v-model="eventYear"
            validation-type="year"
            :should-validate="shouldValidateYear"
            @validated="checkIfDoneValidating"
        />
      </div>
      <div class="col-12">
        <label for="inputEventDescription" class="form-label">Description</label>
        <textarea v-model="eventDescription" type="text" class="form-control" id="inputEventDescription"></textarea>
      </div>
      <div class="col-12 save-event-button">
        <!--reversed order because float-right flips them-->
        <button class="btn btn-secondary float-right" @click="addEventClicked">{{ eventActionString }}</button>
        <button
            v-if="this.entryBeingEdited !== null"
            class="btn btn-secondary cancel-edit-button float-right"
            @click="clearFormFields"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {progressStatus} from '@/helpers/utils';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import ValidationInput from '../ValidationInput';
import {ROUTENAMES} from "@/helpers/constants";

export default {
  name: "EventForm",
  components: { ValidationInput },
  data() {
    return {
      showForm: false,
      events: [],
      eventYear: null,
      eventDescription: null,
      shouldValidateYear: false,
      numValidFields: 0,
      expectedValidFields: 1,
      entryBeingEdited: null,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    const eventData = this.getHomeFormSections.events?.data;
    if (eventData && eventData.length > 0) {
      this.events = eventData;
    } else {
      this.$emit('disableNext', 'Please add at least one event.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please add at least one event.');
        this.updateFormSection({ key: 'events', data: { status: null } });
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({ key: 'events', data: { status: progressStatus.DONE } });
      }
    },
    eventYear() {
      this.shouldValidateYear = false;
    },
    numValidFields(to) {
      if (this.shouldValidateYear && to === this.expectedValidFields) {
        // Save and clear
        this.addEvent();
      }
    },
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    eventActionString() {
      return this.entryBeingEdited !== null ? 'Update Event' : 'Save Event';
    },
    wholeFormEmpty() {
      return this.events.length < 1;
    }
  },
  methods: {
    ...mapActions(['updateFormSection']),
    savePageContents() {
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        this.updateFormSection({ key: 'events', data: { data: this.events } });
        if (this.wholeFormEmpty) {
          this.updateFormSection({ key: 'events', data: { status: null } });
        }
        this.$emit('disableNext', null);
      }
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    addEventClicked() {
      this.shouldValidateYear = true;
      this.numValidFields = 0;
    },
    addEvent() {
      const newEvent = {eventDesc: this.eventDescription, yearRecorded: this.eventYear};
      if (this.entryBeingEdited === null) {
        this.events.push(newEvent);
      } else {
        this.events[this.entryBeingEdited] = newEvent;
      }
      this.clearFormFields();
    },
    clearFormFields() {
      this.eventYear = null;
      this.eventDescription = null;
      this.entryBeingEdited = null;
    },
    checkIfDoneValidating(result) {
      if (result.valid) {
        this.numValidFields += 1;
      }
    },
    removeEventFromTable(index) {
      this.events.splice(index, 1);
      this.entryBeingEdited = null;
    },
    editEvent(index) {
      this.showForm = true;
      this.entryBeingEdited = index;
      const entryToEdit = this.events[index];
      this.eventYear = entryToEdit.yearRecorded;
      this.eventDescription = entryToEdit.eventDesc;
    },
  },
}
</script>

<style scoped>
.top-section {
  margin: 0 0 1rem 0;
}

.table-container {
  background-color: #fafffe;
  padding: 1rem;
}

.add-event-container {
  background-color: #fafffe;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.add-event-container p {
  margin-bottom: 0;
}

.add-event-container:hover {
  color: #003536;
}

.save-event-button {
  margin-top: 1rem;
}

.cancel-edit-button {
  margin-right: 1rem;
}

td {
  vertical-align: middle;
}

.delete-icon {
  margin-left: 1rem;
}

.event-form-buttons {
  white-space: nowrap;
}

.event-form-buttons a {
  font-size: x-large;
}
</style>