<template>
  <div class="search-card card" @click="redirectToDetailPage">
    <div class="card-image">
      <img v-if="imageSrc" :src="imageSrc" class="card-img-top" @error="showErrorImage">
      <img v-else class="card-img-top" src="../../assets/app_icon_512.png" alt="No home photo to show">
      <font-awesome-icon
          v-if="canShareOnSocial && userIsAdmin"
          class="share-icon"
          v-tooltip="'This home is safe to share on social media.'"
          icon="bullhorn"
      />
      <div v-if="!isPublic" class="card-img-overlay">
        <div class="overlay-text">
          <h5>Home is Hidden</h5>
          <span>This home has been hidden- only you can see it. Click </span>
          <font-awesome-icon :icon="eyeIcon"/>
          <span> to make it visible to other users.</span>
        </div>
      </div>
    </div>
    <div class="row card-body">
      <div class="col-12">
              <span class="card-title-container">
                <span v-if="titleTooltip" class="tooltip-text">{{ titleTooltip }}</span>
                <h4 ref="cardTitle" :class="['card-title', {'no-name': !homeHasName}]">{{ homeName }}</h4>
              </span>
        <p class="card-text">{{ locationText }} ({{ cardContent }})</p>
      </div>
      <div class="col-12 edit-container">
        <div v-if="userOwnsHome">
          <button class="btn btn-primary btn-sm edit" title="Edit" @click.stop="editClicked">
            Edit
            <font-awesome-icon icon="edit"/>
          </button>
          <button class="btn btn-primary btn-sm edit" :title="showOrHide" @click.stop="toggleShow">
            {{ showOrHide }}
            <font-awesome-icon :icon="eyeIcon"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getS3Url, stringFormatter, getImgErrorUrl, sendGoogleAnalyticsEvent} from '../../helpers/utils'
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';

export default {
  name: "SearchResultCard",
  data() {
    return {
      placeholderUrl: "/img/app_icon_512.42c7de1c.png",
      titleTooltip: null,
      isPublic: true,
      justToggledVisible: false,
    }
  },
  props: {
    home: {
      type: Object, default: () => {
      }
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getAllUserSettings', 'userSettingsLoading']),
    userOwnsHome() {
      const user = this.getLoggedInUser;
      return user && user.id === this.home.owner_id;
    },
    userIsAdmin() {
      const user = this.getLoggedInUser;
      return user && user.permissions && user.permissions.is_admin;
    },
    imageSrc() {
      const imgToLoad = this.home.photos && this.home.photos[0] ? getS3Url(this.home.photos[0]) : null;
      return imgToLoad;
    },
    locationText() {
      const {home} = this;

      let text = stringFormatter.toSentenceCase(home.country);
      if (home.gad && home.gad.city) {
        text = `${stringFormatter.toSentenceCase(home.gad.city)}, ` + text;
      }
      return text;
    },
    cardContent() {
      return this.yearIsNotEmpty ? this.home.structure.year : 'Year Unknown';
    },
    yearIsNotEmpty() {
      const {home} = this;
      return home.structure && home.structure.year && home.structure.year !== -1;
    },
    homeHasName() {
      return this.home.structure.structName != null && this.home.structure.structName !== "";
    },
    homeName() {
      return this.home.structure.structName || 'Unnamed Home'
    },
    showOrHide() {
      return this.isPublic ? 'Hide' : 'Show';
    },
    eyeIcon() {
      return this.isPublic ? 'eye-slash' : 'eye';
    },
    canShareOnSocial() {
      const ownerSettings = this.getAllUserSettings[this.home.owner_id];
      return ownerSettings ? ownerSettings.consents_to_sharing : false;
    }
  },
  mounted() {
    this.setTooltip();
    this.isPublic = !this.home.isHidden;

    const userSettingsExist = this.getAllUserSettings && this.getAllUserSettings[this.home.owner_id];
    if (!(this.userSettingsLoading || userSettingsExist)) {
      // only fetch fresh user settings if we haven't done so already
      this.getUserSettings(this.home.owner_id);
    }
  },
  updated() {
    this.setTooltip();
    if (!this.justToggledVisible) {
      this.isPublic = !this.home.isHidden;
    } else {
      this.justToggledVisible = false;
    }
  },
  methods: {
    ...mapActions(['updateHome', 'getUserSettings']),
    redirectToDetailPage() {
      sendGoogleAnalyticsEvent(this, 'Home Card Clicked', 'view_item', `from: search page, home_id: ${this.home._id}`);
      this.$router.push({name: 'details', params: {id: this.home._id}});
    },
    editClicked() {
      // redirect to add page, but autofill fields
      sendGoogleAnalyticsEvent(this, 'Edit Home', 'edit_item', this.home._id);
      this.$router.push({name: 'add', params: {homeToEdit: this.home}});
    },
    showErrorImage(event) {
      event.target.src = getImgErrorUrl();
    },
    isElementOverflow(element) {
      return element.scrollWidth > element.clientWidth;
    },
    setTooltip() {
      if (this.isElementOverflow(this.$refs.cardTitle)) {
        this.titleTooltip = this.homeName;
      }
    },
    toggleShow() {
      this.isPublic = !this.isPublic;
      this.updateHome({data: {isHidden: !this.isPublic}, id: this.home._id});
      this.justToggledVisible = true;
    }
  }
}
</script>

<style scoped>
.search-card {
  margin: 0.75rem;
  transition: all .3s ease-out;
}

.search-card:hover {
  cursor: pointer;
  transform: translate(0, -5px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.tooltip-text {
  bottom: 4rem;
  right: 0;
  width: 100%;
}

.card-title-container:hover .tooltip-text {
  visibility: visible;
}

.no-name {
  color: darkgrey;
  font-style: italic;
  font-weight: lighter;
}

.edit {
  margin: 0 0.75rem 0 0;
  padding: 0.25rem 0.5rem;
}

.edit-container {
  display: flex;
  margin-top: 1rem;
}

.card-image {
  position: relative;
}

.share-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: x-large;
  color: #343a40;
}

.card-img-overlay {
  background-color: rgba(33, 33, 33, 0.7);
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
}

img {
  height: 25rem;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  img {
    height: 12rem;
  }
}
</style>