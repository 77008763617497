<template>
    <div class="no-permission-container d-flex align-items-center">
        <div class="text-content d-flex align-items-center">
            <h1>You don't have permission to access this page.</h1>
            <p>
                This feature is currently in beta and is only available to our partner organizations.
                To learn more about partnering with the Ancestral Homes Network and get early access to features like these,
                please <a href="mailto:info@ancestralhomes.net">click here</a> to contact us.
            </p>
        </div>
        <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
    </div>
</template>

<script>
    export default {
        name: "NoPermissionMessage"
    }
</script>

<style scoped>
.no-permission-container {
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.text-content {
    z-index: 1;
    flex-direction: column;
    text-align: center;
}
</style>