<template>
  <div class="login-container">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title title-font">{{ loginTitle }}</h3>
        <div>
          <div>
            <label for="inputEmail">Email address</label>
            <input
                v-model="email"
                type="email"
                :class="['form-control', {'invalid-field': !isEmailValid}]"
                id="inputEmail"
                aria-describedby="emailHelp"
                placeholder="Enter email"
            >
            <p v-if="!isEmailValid" class="input-error">Invalid email address</p>
          </div>
          <div class="form-group password-section">
            <label for="exampleInputPassword1">Password</label>
            <input
                v-model="password"
                type="password"
                :class="['form-control', {'invalid-field': showPasswordError}]"
                id="exampleInputPassword1"
                placeholder="Password">
            <p v-if="showPasswordError" class="input-error">Password cannot be blank</p>
            <input
                v-if="isRegisterPage"
                v-model="passwordConfirmation"
                type="password"
                :class="['form-control', 'confirm-pass', {'invalid-field': showMatchError}]"
                id="passwordConfirmation"
                placeholder="Confirm password"
            >
            <p v-if="showMatchError" class="input-error">Passwords must match</p>
            <div class="form-check col-12" v-if="isRegisterPage">
              <input v-model="acceptedTerms" type="checkbox" class="form-check-input" id="termsCheck">
              <label class="form-check-label" for="termsCheck">
                I have read and accept the
                <router-link to="/terms-conditions" target="_blank">Terms and Conditions</router-link>
              </label>
            </div>
          </div>
          <div v-if="!isRegisterPage" class="form-check">
            <input v-model="rememberMe" type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Remember me</label>
          </div>
          <div class="login-buttons">
            <button @click="secondaryClicked" class="btn btn-secondary">{{ secondaryActionText }}</button>
            <button @click="primaryClicked" :class="[{disabled: !hasRequiredFields}, 'btn', 'btn-primary']">
              {{ mainActionText }}
            </button>
          </div>
          <div class="bottom-text">
            <p v-if="showAuthError" class="warning-text">{{ errorText }}</p>
            <p v-if="showLocalError" class="warning-text">{{ localErrorText }}</p>
            <p v-if="showAuthStatus">{{ authStatusText }}</p>
            <p v-if="!isRegisterPage" class="forgot-password" @click="forgotPasswordClicked">Forgot Password</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isRegisterPage" class="disclaimer-text text-center">
      <p>Your privacy is important to us. We'll never use your email address for marketing purposes or sell your
        personal information to outsiders.</p>
      <p>For more information, see our
        <router-link to="/privacy">privacy policy</router-link>
        .
      </p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import {LOGIN_FORM_KEY, stringFormatter, validate, sendGoogleAnalyticsEvent} from '@/helpers/utils'

export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      rememberMe: false,
      passwordConfirmation: null,
      result: null,
      showLocalError: false,
      localErrorText: '',
      showAuthError: false,
      showAuthStatus: false,
      isRegisterPage: false,
      shouldValidate: false,
      acceptedTerms: false,
      nextPage: null,
    };
  },
  mounted() {
    // if user clicked a 'register' button, show register first
    this.isRegisterPage = this.$route.params.register;
    if (this.isRegisterPage) {
      this.$gtag.pageview('Register');
    }

    this.nextPage = this.$route.params.next;

    // check for username/password saved in local storage
    const loginInfoStr = localStorage.getItem(LOGIN_FORM_KEY);
    if (loginInfoStr) {
      const loginInfo = JSON.parse(loginInfoStr);
      this.email = loginInfo.email;
      this.password = loginInfo.password;
      this.rememberMe = loginInfo.rememberMe;
    }
  },
  watch: {
    getLoggedInUser(user) {
      // TODO: should probably just move this redirect into the store action itself
      if (user != null) {
        this.$router.push({name: this.nextPage || 'profile'});
      }
    },
    authError(to) {
      if (to) {
        sendGoogleAnalyticsEvent(this, 'Login Failed', 'login', this.errorText);
        this.showAuthError = true;
        // remove error message after a few seconds
        setTimeout(() => {
          this.showAuthError = false;
          this.resetAuthError();
        }, 6000);
      }
    },
    authStatus(to) {
      if (to) {
        this.showAuthStatus = true;
      }
    },
    // Clear auth error if new text entered
    email() {
      this.clearMessages();
    },
    password() {
      this.clearMessages();
    },
    showLocalError(to) {
      if (to) {
        // remove error message after a few seconds
        setTimeout(() => {
          this.showLocalError = false;
          this.localErrorText = '';
        }, 6000);
      }
    },
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'authError', 'authStatus']),
    loginTitle() {
      return this.isRegisterPage ? 'Create an Account' : 'Log In';
    },
    mainActionText() {
      return this.isRegisterPage ? 'Register' : 'Log In';
    },
    secondaryActionText() {
      return this.isRegisterPage ? 'Back to Login Page' : 'Register';
    },
    errorText() {
      return stringFormatter.toSentenceCase(this.authError);
    },
    authStatusText() {
      return stringFormatter.toSentenceCase(this.authStatus);
    },
    isEmailValid() {
      return !this.shouldValidate || validate.email(this.email);
    },
    isNewPasswordValid() {
      const passwordsNotNull = this.password && this.passwordConfirmation;
      const passwordsMatch = this.password === this.passwordConfirmation;
      return passwordsNotNull && passwordsMatch;
    },
    hasRequiredFields() {
      const passwordValid = this.isRegisterPage ? this.isNewPasswordValid : this.password;
      const acceptsTerms = this.isRegisterPage ? this.acceptedTerms : true;
      return this.isEmailValid && passwordValid && acceptsTerms;
    },
    showPasswordError() {
      return !this.password && this.shouldValidate;
    },
    showMatchError() {
      return this.isRegisterPage && this.shouldValidate && this.password && !this.isNewPasswordValid;
    },
  },
  methods: {
    ...mapActions(['loginUser', 'createUser', 'sendPasswordResetEmail', 'resetAuthError']),
    primaryClicked() {
      this.shouldValidate = true;
      if (this.hasRequiredFields) {
        if (this.isRegisterPage) {
          this.registerClicked();
        } else {
          this.loginClicked();
        }
      } else if (this.isRegisterPage) {
        //display message informing user that fields are missing
        this.showLocalError = !this.acceptedTerms;
        this.localErrorText = 'Please confirm that you have read and accept the terms and conditions.';
      }
    },
    async loginClicked() {
      if (this.isEmailValid) {
        let data = {email: this.email, password: this.password};
        await this.loginUser(data);
        sendGoogleAnalyticsEvent(this, 'Login Clicked', 'login');
        if (this.rememberMe) {
          // Not saving password for security reasons
          delete data['password'];
          data['rememberMe'] = true;
          localStorage.setItem(LOGIN_FORM_KEY, JSON.stringify(data));
        } else if (localStorage.getItem(LOGIN_FORM_KEY)) {
          localStorage.removeItem(LOGIN_FORM_KEY);
        }
      }
    },
    secondaryClicked() {
      this.showAuthError = false;
      this.showAuthStatus = false;
      this.toggleRegister();
    },
    toggleRegister() {
      this.isRegisterPage = !this.isRegisterPage;
    },
    async registerClicked() {
      this.showLocalError = !this.isNewPasswordValid;

      if (!this.isNewPasswordValid) {
        this.localErrorText = 'The provided passwords do not match.';
        return;
      }

      // send data to API
      let data = {email: this.email, password: this.password};
      await this.createUser(data);
      sendGoogleAnalyticsEvent(this, 'Register Clicked', 'login');
    },
    forgotPasswordClicked() {
      this.showLocalError = !this.email;
      if (this.email) {
        this.sendPasswordResetEmail(this.email);
        sendGoogleAnalyticsEvent(this, 'Forgot Password Clicked', 'login');
      } else {
        this.localErrorText('Email address is required.');
      }
    },
    clearMessages() {
      this.showAuthError = false;
      this.showAuthStatus = false;
      this.shouldValidate = false;
      this.showLocalError = false;
      this.localErrorText = '';
    }
  },
}
</script>

<style scoped>
.login-container {
  padding: 5rem 0;
}

.card {
  margin: 2rem auto;
  width: 40%;
}

.card-body {
  box-shadow: 0 30px 80px 0 rgba(0, 0, 0, .1), 0 10px 40px 0 rgba(0, 0, 0, .1);
  border-radius: .375rem;
}

.card-title {
  margin-bottom: 2rem;
}

.login-buttons .btn {
  margin: 0.5rem;
  width: 45%;
}

.login-buttons {
  width: 100%;
}

.bottom-text {
  text-align: center;
  font-size: small;
}

.forgot-password {
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  margin: 1rem auto 0 auto;
}

.forgot-password:hover {
  color: royalblue;
}

.password-section {
  margin-top: 0.75rem;
}

.confirm-pass {
  margin-top: 0.5rem;
}

.invalid-field {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
}

.input-error {
  color: #d9534f;
  float: right;
  font-size: x-small;
  margin-bottom: 0;
  margin-top: 0;
}

.form-check {
  margin-top: 1rem;
}

.disclaimer-text {
  font-size: small;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {
  .card, .disclaimer-text {
    width: 100%;
  }

  .login-buttons .btn {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
}
</style>