<template>
    <tr>
        <td v-for="(key, index) in eventKeys" :key="index">{{formatValue(event[key]) || '-'}}</td>
    </tr>
</template>

<script>
import { objectFormat } from '../../helpers/utils';

export default {
    name: "EventRow",
    data() {
        return {
            eventKeys: this.getKeys(objectFormat.EVENT_FIELDS),
        }
    },
    props: {
        event: {type: Object, default: () => {}}
    },
    methods: {
        formatValue(value){
            if(value === -1){
                return;
            }
            return value;
        },
        getKeys(fieldArray){
            let keys = [];
            fieldArray.forEach(eventField => {
                keys.push(eventField.key);
            });
            return keys;
        }
    }
}
</script>

<style scoped>

</style>