<template>
    <div class="validation-input">
        <label for="inputField" class="form-label">{{label}}</label>
        <input v-if="type === 'number'" :value="value" type="number" :class="['form-control', {'invalid-field': showError}]"
               id="inputField" step="any" @input="handleInput">
        <input v-else :type="type" :value="value" :class="['form-control', {'invalid-field': showError}]"
               id="inputField" @input="handleInput">
        <p v-if="showError" class="input-error">{{errorMessage}}</p>
    </div>
</template>

<script>
import { stringFormatter } from '@/helpers/utils'
export default {
    name: "ValidationInput",
    props: {
        label: { type: String, default: null},
        type: { type: String, default: 'text'},
        shouldValidate: { type: Boolean, default: false },
        validationFunction: { type: Function, default: null },
        validationType: { type: String, default: null },
        rangeMin: { type: Number, default: null },
        required: { type: Boolean, default: false },
        value: { default: null },
    },
    watch:{
        showError(to){
            if(to){
                this.$emit('invalidField', this.label.toLowerCase());
            }
        }
    },
    computed: {
        showError() {
            // return shouldValidate && if there's actually a problem.
            return this.shouldValidate && !this.isValid;
        },
        errorMessage(){
            let message = 'Field is invalid';
            switch(this.validationType){
                case 'latitude':
                    message = this.label + ' must be between -90.0 and 90.0';
                    break;
                case 'longitude':
                    message = this.label + ' must be between -180.0 and 180.0';
                    break;
                case 'positive':
                    message = this.label + ' cannot be negative';
                    break;
                case 'year':
                    message = this.label + ' cannot be negative or in the future';
                    break;
                case 'range':
                    message = this.label + ' must be larger than '+ this.rangeMin;
                    break;
                default:
                    message = this.label + ' is invalid';
                }
            if(this.required && this.value === null){
                message = this.label + ' cannot be blank'
            }
            return stringFormatter.toSentenceCase(message);
        },
        isValid(){
            if(this.required && (this.value === null || this.value === '')){
                this.finishValidating(false);
                return false;
            }
            if (this.validationFunction){
                const result = this.validationFunction(this.value);
                this.finishValidating(result);
                return result;
            }
            // otherwise, go by validation type
            else if (this.validationType){
                let result = true;
                switch(this.validationType){
                    case 'latitude':
                        result = this.fallsWithinRange(-90, 90);
                        break;
                    case 'longitude':
                        result =  this.fallsWithinRange(-180, 180);
                        break;
                    case 'positive':
                        result = this.isPositive();
                        break;
                    case 'year':
                        result = this.fallsWithinRange(0, new Date().getFullYear());
                        break;
                    case 'date':
                        result = this.isValidDate();
                        break;
                    case 'range':
                        result = this.isLargerThan(this.rangeMin);
                        break;
                    default:
                        result = true;
                }
                this.finishValidating(result);
                return result;
            }
            this.finishValidating(true);
            return true;
        }
    },
    methods: {
        fallsWithinRange(min, max){
            if(this.required && this.value === null){
                return false;
            }
            return this.value >= min && this.value <= max;
        },
        isPositive(){
            if(this.required && this.value === null){
                return false;
            }
            return this.value >= 0;
        },
        isValidDate(){
            const valueMissing = this.value === null;
            if(this.required && valueMissing){
                return false;
            }
            else if(valueMissing){
                return true;
            }
            const date = new Date(this.value+'');
            const dateInFuture = date.getFullYear() <= new Date().getFullYear();
            // check if date is invalid first
            return !isNaN(date.getTime()) && dateInFuture;

        },
        isLargerThan(min){
            if(this.required && this.value === null){
                return false;
            }
            return this.value > min;
        },
        handleInput(event){
            this.$emit('input', event.target.value);
        },
        finishValidating(result){
            const data = { valid: result, label: this.label.toLowerCase()};
            this.$emit('validated', data);
        }
    }
}
</script>

<style scoped>
.invalid-field {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
}
.input-error {
    color: #d9534f;
    float: right;
    font-size: x-small;
    margin-bottom: 0;
    margin-top: 0;
}
</style>