<template>
  <div>
    <h2>Census</h2>
    <div class="selection-details">
      A place to add links or upload PDF files containing copies of census records related to this property.
    </div>
    <div class="row top-section">
      <table v-if="censuses.length > 0" class="table table-container">
        <thead>
        <tr>
          <th scope="col">Year</th>
          <th scope="col">Country Link</th>
          <th scope="col">Townland Link</th>
          <th scope="col">Residents Link</th>
          <th scope="col">Documents</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(census, index) in censuses" :key="index"
            :class="{'light-teal-bg-selected': index === entryBeingEdited}">
          <td>{{ census.year || '-' }}</td>
          <td>{{ census.countryLink || '-' }}</td>
          <td>{{ census.townlandLink || '-' }}</td>
          <td>{{ census.residentsLink || '-' }}</td>
          <td>
            <font-awesome-icon v-if="docsAdded(census)" icon="file-pdf"/>
            {{ docsAdded(census) ? census.pdfLinks.length : '-' }}
          </td>
          <td>
            <div class="census-form-buttons float-right">
              <a class="edit-icon" title="Edit" @click="editCensus(index)">
                <font-awesome-icon icon="edit"/>
              </a>
              <a class="delete-icon" title="Delete" @click="removeCensusFromTable(index)">
                <font-awesome-icon icon="trash"/>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="!showForm" class="add-census-container" @click="toggleForm">
        <p>Click to add a census</p>
      </div>
    </div>
    <div v-if="showForm" class="row">
      <div class="col-lg-6 col-sm-12">
        <ValidationInput
            class="col-12"
            label="Year"
            type="number"
            v-model="year"
            validation-type="year"
            :should-validate="shouldValidateYear"
            @validated="checkIfDoneValidating"
        />
        <div class="col-12">
          <label for="inputCensusCountryLink" class="form-label">Country Link</label>
          <input v-model="countryLink" type="url" class="form-control" id="inputCensusCountryLink">
        </div>
        <div class="col-12">
          <label for="inputCensusTownlandLink" class="form-label">Townland Link</label>
          <input v-model="townlandLink" type="url" class="form-control" id="inputCensusTownlandLink">
        </div>
        <div class="col-12">
          <label for="inputCensusResidentsLink" class="form-label">Residents Link</label>
          <input v-model="residentsLink" type="url" class="form-control" id="inputCensusResidentsLink">
        </div>
        <div class="col-12" v-if="showPdfTable">
          <label>Uploaded Files</label>
          <table class="files-table">
            <tbody>
            <tr v-for="(pdf, index) in pdfLinks" :key="index">
              <td><a :href="'https://ancestralhomes.s3.us-east-2.amazonaws.com/'+pdf">Click to view</a></td>
              <td class="link-delete">
                <font-awesome-icon icon="trash" @click="removePdfLink(index)"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <button
              :class="['btn', 'btn-primary', 'right-button', { 'disabled': fieldsEmpty }]"
              @click="saveCensusClicked"
          >
            Save Census
          </button>
          <button
              v-if="this.entryBeingEdited !== null"
              class="btn btn-secondary cancel-edit-button right-button"
              @click="clearFormFields"
          >
            Cancel
          </button>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 uploads">
        <vue-dropzone
            class="file-upload-section"
            id="pdfDropzone"
            ref="pdfDropzone"
            :options="dropOptions"
            @vdropzone-success="handleResponse"
            @vdropzone-error="handleDropzoneError"
            @vdropzone-queue-complete="addCensus"
        />
        <span class="text-info text-small float-right">A maximum of 5 PDF files can be uploaded per census entry.</span>
      </div>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import {IMG_API_URL, cleanupUrl, progressStatus} from '@/helpers/utils';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import ValidationInput from '../ValidationInput';
import {ROUTENAMES} from "@/helpers/constants";

export default {
  name: "CensusForm",
  components: { ValidationInput, vueDropzone },
  data() {
    return {
      showForm: false,
      censuses: [],
      year: null,
      countryLink: null,
      townlandLink: null,
      residentsLink: null,
      shouldValidateYear: false,
      numValidFields: 0,
      expectedValidFields: 1,
      entryBeingEdited: null,
      dropOptions: {
        url: IMG_API_URL,
        addRemoveLinks: true,
        acceptedFiles: '.pdf',
        maxFileSize: 1024 * 1000, // 1MB
        maxFiles: 5,
        // stops automatic upload (until save button pressed)
        autoProcessQueue: false,
      },
      pdfLinks: null,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    const censusData = this.getHomeFormSections.census?.data;
    if (censusData && censusData.length > 0) {
      this.censuses = censusData;
    } else {
      this.$emit('disableNext', 'Please add at least one census.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please add at least one census.');
        this.updateFormSection({key: 'census', data: {status: null}});
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({key: 'census', data: {status: progressStatus.DONE}});
      }
    },
    year() {
      this.shouldValidateYear = false;
    },
    numValidFields(to) {
      if (this.shouldValidateYear && to === this.expectedValidFields) {

        const filesInQueue = this.$refs.pdfDropzone.getQueuedFiles().length > 0;
        if (filesInQueue) {
          this.$refs.pdfDropzone.processQueue();
        } else {
          this.addCensus();
          this.$emit('disableNext', null);
        }
      }
    },
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    showPdfTable() {
      return this.pdfLinks && this.pdfLinks.length > 0 && this.entryBeingEdited !== null
    },
    fieldsEmpty() {
      const numFiles = this.$refs.pdfDropzone ? this.$refs.pdfDropzone.getQueuedFiles().length : 0;

      return (this.year == null
        && this.countryLink == null
        && this.townlandLink == null
        && this.residentsLink == null
        && numFiles === 0
      )
    },
    wholeFormEmpty() {
      return this.censuses.length < 1;
    }
  },
  methods: {
    ...mapActions(['deletePhoto', 'updateFormSection']),
    savePageContents(){
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        this.updateFormSection({ key: 'census', data: { data: this.censuses } });

        if (this.wholeFormEmpty) {
          this.updateFormSection({ key: 'census', data: { status: null } });
        }
      }
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    saveCensusClicked() {
      if (!this.fieldsEmpty) {
        // fix URLs if necessary
        if (this.countryLink) {
          this.countryLink = cleanupUrl(this.countryLink);
        }
        if (this.townlandLink) {
          this.townlandLink = cleanupUrl(this.townlandLink);
        }
        if (this.residentsLink) {
          this.residentsLink = cleanupUrl(this.residentsLink);
        }

        if (this.year !== null) {
          this.shouldValidateYear = true;
          this.numValidFields = 0;
        } else {
          const filesInQueue = this.$refs.pdfDropzone.getQueuedFiles().length > 0;
          if (filesInQueue) {
            this.$refs.pdfDropzone.processQueue();
          } else {
            this.addCensus();
          }
        }
      }
    },
    addCensus() {
      const newCensus = {
        year: this.year,
        countryLink: this.countryLink,
        townlandLink: this.townlandLink,
        residentsLink: this.residentsLink,
        pdfLinks: this.pdfLinks
      };
      if (this.entryBeingEdited === null) {
        this.censuses.push(newCensus);
      } else {
        this.censuses[this.entryBeingEdited] = newCensus;
      }
      this.clearFormFields();
    },
    clearFormFields() {
      this.year = null;
      this.countryLink = null;
      this.townlandLink = null;
      this.residentsLink = null;
      this.entryBeingEdited = null;
      this.pdfLinks = null;
      if (this.$refs.pdfDropzone) {
        this.$refs.pdfDropzone.removeAllFiles();
      }
    },
    checkIfDoneValidating(result) {
      if (result.valid) {
        this.numValidFields += 1;
      }
      else {
        this.$emit('disableNext', 'Please correct the "' + result.label + '" field before continuing.');
      }
    },
    editCensus(index) {
      this.showForm = true;
      this.entryBeingEdited = index;
      const entryToEdit = this.censuses[index];

      this.year = entryToEdit.year;
      this.countryLink = entryToEdit.countryLink;
      this.townlandLink = entryToEdit.townlandLink;
      this.residentsLink = entryToEdit.residentsLink;
      this.pdfLinks = entryToEdit.pdfLinks;
    },
    removeCensusFromTable(index) {
      const censusToRemove = this.censuses[index];
      // remove uploaded files from S3
      if(censusToRemove.pdfLinks) {
        censusToRemove.pdfLinks.forEach(link => {
          this.deletePhoto(link)
        });
      }

      this.censuses.splice(index, 1);
      this.entryBeingEdited = null;
    },
    handleResponse(file, response) {
      if (this.pdfLinks == null) {
        this.pdfLinks = [];
      }
      this.pdfLinks.push(response.key);
    },
    handleDropzoneError(file, message) {
      // todo: display real error message in UI
      console.log('an error occurred :(');
      console.log(message);
    },
    docsAdded(census) {
      return census && census.pdfLinks && census.pdfLinks.length > 0;
    },
    removePdfLink(index) {
      const linkToRemove = this.pdfLinks[index];
      this.pdfLinks.splice(index, 1);
      this.deletePhoto(linkToRemove)
    }
  }
}
</script>

<style scoped>
.top-section {
  margin: 1rem;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table-container {
  background-color: #fafffe;
  padding: 1rem;
  margin-bottom: 1rem;
}

.right-button {
  margin-top: 1rem;
  float: right;
}

.file-upload-section {
  background-color: #faffff;
  border: 1px solid #006060;
  height: 95%;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: center;
  align-items: center;
  color: #006060;
  cursor: pointer;
}

.text-small {
  font-size: small;
}

.files-table {
  background-color: #faffff;
  border: 1px solid #ced4da;
  width: 100%;
}

.files-table td:first-child {
  width: auto;
}

.files-table a {
  margin-left: 0.4rem;
}

.link-delete {
  width: auto;
  float: right;
  cursor: pointer;
  margin-right: 0.5rem;
  color: #0c5460;
}

.add-census-container {
  background-color: #fafffe;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.census-form-buttons {
  white-space: nowrap;
}

.census-form-buttons a {
  font-size: x-large;
}

.disabled {
  cursor: default;
}

.cancel-edit-button {
  margin-right: 1rem;
}

td {
  vertical-align: middle;
  width: 30%;
  word-break: break-all;
}

td:first-child {
  width: 10%;
}

.delete-icon {
  margin-left: 1rem;
}

@media screen and (max-width: 768px) {
  .uploads {
    margin-top: 1rem;
    text-align: center;
  }

  .file-upload-section p {
    margin: 1rem 0;
  }
}
</style>