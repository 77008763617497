<template>
  <div id="app" class="app">
    <Header/>
    <div class="container-fluid main-section">
        <router-view />
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: 'app',
  components: { Footer, Header },
};
</script>

<style>
.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.main-section {
    flex: 1;
}
</style>