<template>
  <div v-if="!isClosed" class="alert col-12" :class="[alertClassName, { 'floating-alert': floating }]" role="alert">
    <button v-if="!permanent" class="btn close-alert float-right alert-link" @click="closeAlert">
      <font-awesome-icon icon="times"/>
    </button>
    <div class="alert-text">
      <h4 v-if="title" class="alert-heading">{{ title }}</h4>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      isClosed: false,
    }
  },
  props: {
    alertType: {type: String, default: 'success'},
    title: {type: String, default: null},
    floating: {type: Boolean, default: false},
    permanent: {type: Boolean, default: false},
  },
  computed: {
    alertClassName() {
      return 'alert-' + this.alertType;
    }
  },
  methods: {
    closeAlert() {
      this.isClosed = true;
    }
  }
}
</script>

<style scoped>
.alert {
  margin: 1rem 0;
}

.alert-text {
  padding: 1rem 0.5rem;
}

.close-alert {
  margin-right: -1rem;
  margin-top: -0.5rem;
}

.floating-alert {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 80vw;
  display: inline-block;
}

p {
  margin-bottom: 0;
}
</style>