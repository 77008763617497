<template>
  <div class="prog-bar-container">
    <ul :class="['prog-bar', {'small-node-prog-bar': small}]">
      <li
        v-for="(node, index) in progressNodes"
        :key="index"
        :class="getNodeClass(index)"
        :style="progressNodeWidth"
        v-tooltip="getNodeTooltip(node)"
        @click="$emit('nodeClicked', index)"
      >
        {{ node.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import { progressStatus } from "@/helpers/utils";

export default {
  name: "ProgressBar",
  props: {
    progressNodes: { type: Array, required: true },
    currentPageIndex: { type: Number, required: true },
    noValidation: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
  },
  computed: {
    progressNodeWidth() {
      return { width: `calc(100% / ${this.progressNodes.length})` };
    },
  },
  methods: {
    getNodeClass(index) {
      // controls colour of nodes + surrounding progress bar
      const node = this.progressNodes[index];

      if(this.noValidation){
        if(index === this.currentPageIndex){
          return 'active';
        }
        return index < this.currentPageIndex ? 'active no-validation' : '';
      }
      else {
        let suffix;
        if (index === this.currentPageIndex) {
          suffix = ' active';
        } else if (node?.status === null && index < this.currentPageIndex) {
          suffix = ' visited';
        } else {
          suffix = '';
        }
        return node?.status ? `${node.status.toLowerCase()}${suffix}` : suffix;
      }
    },

    getNodeTooltip(node) {
      if (node.label === 'Submit' || this.noValidation) {
        return '';
      }
      if (!node.status) {
        return 'This section is incomplete.';
      }
      if (node.status === progressStatus.ERROR) {
        return 'This section has errors that must be fixed before this home can be submitted.';
      }
      if (node.status === progressStatus.DONE) {
        return 'This section is complete.'
      }
      return '';
    },
  }
}
</script>

<style scoped>
.prog-bar-container {
  text-align: center;
  position: relative;
  z-index: 1;
}

.prog-bar {
  overflow: hidden;
  flex-direction: row;
}

.prog-bar li {
  list-style-type: none;
  color: grey;
  font-size: small;
  float: left;
  position: relative;
}

.prog-bar li:before {
  content: '';
  line-height: 50px;
  font-size: x-large;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: block;
  background: lightgrey;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  cursor: pointer;
}

.small-node-prog-bar li:before {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 10px auto;
}

.prog-bar li:after {
  content: '';
  width: 100%;
  height: 10px;
  background: lightgrey;
  position: absolute;
  left: -50%;
  top: 21px;
  z-index: -1;
}

.prog-bar li:last-child:after {
  width: 150%;
}

.prog-bar li.active, .prog-bar li.done {
  color: #006060;
}

.prog-bar li.active {
  font-weight: bold;
  font-size: medium;
}

.prog-bar li.no-validation{
  font-weight: normal;
  font-size: small;
}

.prog-bar li.active:before {
  content: '';
}

.prog-bar li.active:before, .prog-bar li.active:after {
  background: #006060;
  color: white;
}

.prog-bar li.error {
  color: #d9534f;
}

.prog-bar li.error:before {
  content: 'X';
}

.prog-bar li.error:before, .prog-bar li.error:after {
  background: #d9534f;
  color: white;
}

.prog-bar li.done:before {
  content: '✓';
}

.prog-bar li.done:before, .prog-bar li.done:after, .prog-bar li.visited:after {
  background: #006060;
  color: white;
}
</style>