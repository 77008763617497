<template>
  <div>
    <h2>References</h2>
    <div class="selection-details">
      If you used external resources to complete this entry, you may cite them here.
    </div>
    <div class="row top-section">
      <table v-if="references.length > 0" class="table table-container">
        <thead>
        <tr>
          <th scope="col">Reference</th>
          <th scope="col">Author</th>
          <th scope="col">Link</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(reference, index) in references" :key="index"
            :class="{'light-teal-bg-selected': index === entryBeingEdited}">
          <td>{{ reference.refName || '-' }}</td>
          <td>{{ reference.refAuthor || '-' }}</td>
          <td>{{ reference.refLink || '-' }}</td>
          <td>
            <div class="reference-form-buttons float-right">
              <a class="edit-icon" title="Edit" @click="editReference(index)">
                <font-awesome-icon icon="edit"/>
              </a>
              <a class="delete-icon" title="Delete" @click="removeReferenceFromTable(index)">
                <font-awesome-icon icon="trash"/>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="!showForm" class="add-reference-container" @click="toggleForm">
        <p>Click to add a reference</p>
      </div>
    </div>
    <div v-if="showForm" class="row">
      <div class="col-12">
        <label for="inputReference" class="form-label">Reference Used</label>
        <input v-model="refName" type="text" class="form-control" id="inputReference">
      </div>
      <div class="col-12">
        <label for="inputReferenceAuthor" class="form-label">Author</label>
        <input v-model="refAuthor" type="text" class="form-control" id="inputReferenceAuthor">
      </div>
      <div class="col-12">
        <label for="inputReferenceLink" class="form-label">Link</label>
        <input v-model="refLink" type="url" class="form-control" id="inputReferenceLink">
      </div>
      <div class="col-12 save-reference-button">
        <!--reversed order because float-right flips them-->
        <button class="btn btn-secondary float-right" @click="addReferenceClicked">{{ referenceActionString }}</button>
        <button
            v-if="this.entryBeingEdited !== null"
            class="btn btn-secondary cancel-edit-button float-right"
            @click="clearFormFields"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {cleanupUrl, progressStatus} from '@/helpers/utils';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import {ROUTENAMES} from "@/helpers/constants";

export default {
  name: "ReferenceForm",
  components: {},
  data() {
    return {
      showForm: false,
      references: [],
      refName: null,
      refAuthor: null,
      refLink: null,
      entryBeingEdited: null,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    const referenceData = this.getHomeFormSections.references?.data;
    if (referenceData && referenceData.length > 0) {
      this.references = referenceData;
    } else {
      this.$emit('disableNext', 'Please add at least one reference.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please add at least one reference.');
        this.updateFormSection({ key: 'references', data: { status: null } });
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({ key: 'references', data: { status: progressStatus.DONE } });
      }
    }
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    referenceActionString() {
      return this.entryBeingEdited !== null ? 'Update Reference' : 'Save Reference';
    },
    wholeFormEmpty() {
      return this.references.length < 1;
    }
  },
  methods: {
    ...mapActions(['updateFormSection']),
    savePageContents(){
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        this.updateFormSection({ key: 'references', data: { data: this.references } });
        if (this.wholeFormEmpty) {
          this.updateFormSection({ key: 'references', data: { status: null } });
        }
        this.$emit('disableNext', null);
      }
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    addReferenceClicked() {
      const newReference = { refName: this.refName, refAuthor: this.refAuthor, refLink: cleanupUrl(this.refLink) };
      if (this.entryBeingEdited === null) {
        this.references.push(newReference);
      } else {
        this.references[this.entryBeingEdited] = newReference;
      }
      this.clearFormFields();
    },
    clearFormFields() {
      this.refName = null;
      this.refAuthor = null;
      this.refLink = null;
      this.entryBeingEdited = null;
    },
    removeReferenceFromTable(index) {
      this.references.splice(index, 1);
      this.entryBeingEdited = null;
    },
    editReference(index) {
      this.showForm = true;
      this.entryBeingEdited = index;
      const entryToEdit = this.references[index];
      this.refName = entryToEdit.refName;
      this.refAuthor = entryToEdit.refAuthor;
      this.refLink = entryToEdit.refLink;
    },
  },
}
</script>

<style scoped>
.top-section {
  margin: 0 0 1rem 0;
}

.table-container {
  background-color: #fafffe;
  padding: 1rem;
}

.add-reference-container {
  background-color: #fafffe;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.add-reference-container p {
  margin-bottom: 0;
}

.add-reference-container:hover {
  color: #003536;
}

.save-reference-button {
  margin-top: 1rem;
}

.cancel-edit-button {
  margin-right: 1rem;
}

td {
  vertical-align: middle;
}

.delete-icon {
  margin-left: 1rem;
}

.reference-form-buttons {
  white-space: nowrap;
}

.reference-form-buttons a {
  font-size: x-large;
}
</style>