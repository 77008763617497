<template>
    <tr>
        <td v-for="(key, index) in referenceKeys" :key="index">
            <a v-if="key === 'refLink' && reference.refLink"
               :href="getReferenceURL(reference.refLink)"
               target="_blank">
                Click to Visit
            </a>
            <span v-else>{{reference[key] || '-'}}</span>
        </td>
    </tr>
</template>

<script>
import { objectFormat } from '../../helpers/utils';

export default {
    name: "ReferenceRow",
    data() {
        return {
            referenceKeys: this.getKeys(objectFormat.REFERENCE_FIELDS),
        }
    },
    props: {
        reference: {type: Object, default: () => {}}
    },
    methods: {
        getKeys(fieldArray){
            let keys = [];
            fieldArray.forEach(refField => {
                keys.push(refField.key);
            });
            return keys;
        },
        getReferenceURL(givenURL){
            // if link doesn't start with http or https, will try to redirect within the AH site
            if (givenURL.startsWith('http://') || givenURL.startsWith('https://')){
                return givenURL;
            }
            return `http://${givenURL}`;
        }
    }
}
</script>

<style scoped>

</style>