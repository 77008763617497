<template>
    <div class="home-detail-container page-bg">
      <div v-if="error" class="error">
        <h1>Home could not be found.</h1>
      </div>
      <HomeDetails v-else-if="home" :home="home"/>
      <div v-else>
        <h1>Loading...</h1>
      </div>
    </div>
</template>

<script>
import {api} from '@/helpers/api';
import HomeDetails from "@/views/HomeDetails";

export default {
  name: "SearchDetailWrapper",
  components: {HomeDetails},
  data() {
    return {
      error: false,
      home: null,
    }
  },
  mounted() {
    const homeId = this.$route.params.id;
    // TODO: clean this up and put it in /helpers with the rest of the API methods
    if (homeId) {
      api.getHomeById(homeId).then(result => {
        if (result.error) {
          this.error = true;
        } else {
          this.error = false;
          this.home = result;
        }
      });
    }
  },
}
</script>