<template>
    <div class="reset-pass-container">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title title-font">Reset Password</h5>
            <div>
              <div class="row">
                  <div class="form-group col-12">
                    <label for="passwordInput">New Password</label>
                    <input v-model="password" type="password" class="form-control" id="passwordInput" placeholder="Password">
                    <input
                        v-model="passwordConfirmation"
                        type="password"
                        class="form-control confirm-pass"
                        id="passwordConfirmation"
                        placeholder="Confirm password"
                    >
                  </div>
                  <div class="action-buttons col-12">
                      <button @click="cancelClicked" class="btn btn-secondary left-btn">Cancel</button>
                      <button
                        @click="resetClicked"
                        :class="['btn', 'btn-primary', 'right-btn', { 'disabled': fieldsAreEmpty }]"
                      >
                          Reset
                      </button>
                  </div>
              </div>
              <div class="row">
                  <div class="bottom-text col-12">
                    <p v-if="showUserError" class="warning-text">{{userErrorMessage}}</p>
                    <p v-if="showAuthError" class="warning-text">{{errorText}}</p>
                    <p v-if="showAuthStatus">{{authStatusText}}</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { LOGIN_FORM_KEY, stringFormatter } from '../helpers/utils';

export default {
    name: "ResetPassword",
    data() {
        return {
            showAuthError: false,
            showAuthStatus: false,
            showUserError: false,
            password: null,
            passwordConfirmation: null,
            token: null,
            tokenId: null,
            userErrorMessage: null,
        }
    },
    mounted() {
        const { query } = this.$route;
        this.token = query.token;
        this.tokenId = query.tokenId;
    },
    watch: {
        authError(to) {
            if (to){
                this.showAuthError = true;
            }
        },
        authStatus(to) {
            if (to){
                this.showAuthStatus = true;
            }
        },
        showUserError(to){
            if(to) {
                // remove error message after a few seconds
              setTimeout(() => {
                  this.clearErrorMessage();
              }, 5000);
            }
        },
        passwordConfirmation() {
            this.clearAuthErrors();
        },
        password() {
            this.clearAuthErrors();
        },
    },
    computed: {
        ...mapGetters(['authError', 'authStatus']),
        errorText() {
            return stringFormatter.toSentenceCase(this.authError);
        },
        authStatusText() {
            return stringFormatter.toSentenceCase(this.authStatus);
        },
        fieldsAreEmpty() {
            return this.password == null || this.passwordConfirmation == null
        },
        passwordsMatch(){
            return this.password === this.passwordConfirmation;
        }
    },
    methods: {
        ...mapActions(['resetPassword']),
        cancelClicked(){
            this.$router.push({ name: 'login' });
        },
        triggerErrorMessage(message){
            this.showUserError = true;
            this.userErrorMessage = message;
        },
        clearErrorMessage(){
            this.showUserError = false;
            this.userErrorMessage = null;
        },
        clearAuthErrors(){
            this.showAuthError = false;
            this.showAuthStatus = false;
        },
        async resetClicked(){
            // validate new password
            if(this.fieldsAreEmpty){
                this.triggerErrorMessage('Password and confirmation fields cannot be blank.');
                return;
            }
            else if (!this.passwordsMatch) {
                this.triggerErrorMessage('Password and confirmation fields do not match.');
                return;
            }
            localStorage.removeItem(LOGIN_FORM_KEY);
            const data = {'newPassword': this.password, 'token': this.token, 'token_id': this.tokenId};
            await this.resetPassword(data);
        }
    }
}
</script>

<style scoped>
.card {
    margin: 2rem auto;
    width: 40%;
}
.card-body {
    box-shadow: 0 30px 80px 0 rgba(0,0,0,.1), 0 10px 40px 0 rgba(0,0,0,.1);
    border-radius: .375rem;
}
.action-buttons .btn {
    width: 45%;
}
.action-buttons {
    width: 100%;
}
.left-btn {
    float: left;
}
.right-btn {
    float: right;
}
.bottom-text {
    text-align: center;
    font-size: small;
    margin: 1rem auto 0 auto;
}
.confirm-pass {
    margin-top: 0.5rem;
}
@media screen and (max-width: 600px) {
    .card{
        width: 100%;
    }
    .action-buttons .btn {
        width: 100%;
        margin: 0 0 0.5rem 0;
    }
}
</style>