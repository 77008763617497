<template>
    <div class="confirmation-container">
        <div class="row">
            <div class="col-12 content">
                <h4 v-if="authError" class="text-center error-msg">{{errorText}}</h4>
                <div v-else-if="authStatus" class="content">
                    <h1>Account Created!</h1>
                    <button class="btn btn-primary btn-lg login-button">
                        <router-link class="account-button" to="/login">Log in</router-link>
                    </button>
                </div>
                <h1 v-else>Confirming...</h1>
            </div>
            <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { stringFormatter } from '../helpers/utils'

export default {
    name: "EmailConfirmation",
    mounted() {
        const { query } = this.$route;
        this.confirmUser({ token: query.token, token_id: query.tokenId });
    },
    computed: {
        ...mapGetters(['authError', 'authStatus']),
        errorText(){
            return stringFormatter.toSentenceCase(this.authError);
        }
    },
    methods: {
        ...mapActions(['confirmUser']),
    }
}
</script>

<style scoped>
.row {
    align-content: center;
    min-height: 90vh;
    position: relative;
    padding: 0;
}
.confirmation-container h1 {
    margin: 2rem auto;
    text-align: center;
}
.content {
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.login-button {
    min-width: 10rem;
    margin: 1rem auto;
}
.account-button {
  color: white;
}
.account-button:hover {
  text-decoration: none;
}
.error-msg{
    margin: 0 10rem;
}
@media screen and (max-width: 600px) {
    .error-msg{
        margin: 1rem;
    }
}
</style>