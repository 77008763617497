<template>
  <div>
    <h2>Family Names and Residents</h2>
    <div class="selection-details">
      Biographical details about the people who lived in this home.
    </div>
    <div class="row top-section">
      <table v-if="residents.length > 0" class="table table-container">
        <thead>
        <tr>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(resident, index) in residents" :key="index"
            :class="{'light-teal-bg-selected': index === entryBeingEdited}">
          <td>{{ resident.fName || '-' }}</td>
          <td>{{ resident.lName || '-' }}</td>
          <td>
            <div class="resident-form-buttons float-right">
              <a class="edit-icon" title="Edit" @click="editResident(index)">
                <font-awesome-icon icon="edit"/>
              </a>
              <a class="delete-icon" title="Delete" @click="removeResidentFromTable(index)">
                <font-awesome-icon icon="trash"/>
              </a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="!showForm" class="add-resident-container" @click="toggleForm">
        <p>Click to add a resident</p>
      </div>
    </div>

    <!--Form for adding new residents-->
    <div v-if="showForm" class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <label for="inputFName" class="form-label">First Name</label>
              <input v-model="fName" type="text" class="form-control" id="inputFName">
            </div>
            <div class="col-lg-6 col-sm-12">
              <label for="inputLName" class="form-label">Last Name</label>
              <input v-model="lName" type="text" class="form-control" id="inputLName">
            </div>
          </div>
          <div class="row">
            <ValidationInput
                class="col-lg-9 col-sm-12"
                v-model="occStart"
                :type="occStartApproximate ? 'text' : 'date'"
                label="Occupancy Start"
                validation-type="date"
                :should-validate="shouldValidateField['occupancy start']"
                @validated="checkIfDoneValidating"
            />
            <div class="col-lg-3 col-sm-12 form-check align-text-bottom pull-right">
              <input
                  v-model="occStartApproximate"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="occStartApproximate"
              >
              <label class="form-check-label" for="occStartApproximate">Approximate</label>
            </div>
          </div>
          <div class="row">
            <ValidationInput
                class="col-lg-9 col-sm-12"
                v-model="occEnd"
                :type="occEndApproximate ? 'text' : 'date'"
                label="Occupancy End"
                validation-type="date"
                :should-validate="shouldValidateField['occupancy end']"
                @validated="checkIfDoneValidating"
            />
            <div class="col-lg-3 col-sm-12 form-check">
              <input
                  v-model="occEndApproximate"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="occEndApproximate"
              >
              <label class="form-check-label" for="occEndApproximate">Approximate</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="additionalInfo" class="form-label">Additional Information</label>
              <input v-model="additionalInfo" type="text" class="form-control" id="additionalInfo">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="col-12">
          <div class="row">
            <ValidationInput
                class="col-lg-9 col-sm-12"
                v-model="birthdate"
                :type="birthdateApproximate ? 'text' : 'date'"
                label="Date of Birth"
                validation-type="date"
                :should-validate="shouldValidateField['date of birth']"
                @validated="checkIfDoneValidating"
            />
            <div class="col-lg-3 col-sm-12 form-check align-text-bottom">
              <input
                  v-model="birthdateApproximate"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="dobApproximate"
              >
              <label class="form-check-label" for="dobApproximate">Approximate</label>
            </div>
          </div>
          <div class="row">
            <ValidationInput
                class="col-lg-9 col-sm-12"
                v-model="deathDate"
                :type="deathDateApproximate ? 'text' : 'date'"
                label="Date of Death"
                validation-type="date"
                :should-validate="shouldValidateField['date of death']"
                @validated="checkIfDoneValidating"
            />
            <div class="col-lg-3 col-sm-12 form-check">
              <input
                  v-model="deathDateApproximate"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="dodApproximate"
              >
              <label class="form-check-label" for="dodApproximate">Approximate</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="inputGravesite" class="form-label">Grave Site</label>
              <input v-model="gravesite" type="text" class="form-control" id="inputGravesite">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="inputGravesiteLink" class="form-label">Link to Grave Site</label>
              <input v-model="gravesiteLink" type="url" class="form-control" id="inputGravesiteLink">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 save-resident-button">
        <!--reversed order because float-right flips them-->
        <button class="btn btn-secondary float-right" @click="saveResidentClicked">{{ addActionText }}</button>
        <button
            v-if="entryBeingEdited !== null"
            class="btn btn-secondary cancel-button float-right"
            @click="clearFormFields"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { dateStringToISO, cleanupUrl, progressStatus } from '@/helpers/utils';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import ValidationInput from '../ValidationInput';
import { ROUTENAMES } from '@/helpers/constants';

export default {
  name: "ResidentForm",
  components: { ValidationInput },
  data() {
    return {
      showForm: false,
      residents: [],
      fName: null,
      lName: null,
      occStart: null,
      occStartApproximate: false,
      occEnd: null,
      occEndApproximate: false,
      birthdate: null,
      birthdateApproximate: false,
      deathDate: null,
      deathDateApproximate: false,
      additionalInfo: null,
      gravesite: null,
      gravesiteLink: null,
      shouldValidateField: {
        'occupancy start': false,
        'occupancy end': false,
        'date of birth': false,
        'date of death': false
      },
      numValidFields: 0,
      expectedValidFields: 4,
      validateStarted: false,
      entryBeingEdited: null,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    const residentData = this.getHomeFormSections.occupants?.data;
    if (residentData && residentData.length > 0) {
      this.residents = residentData;
    } else {
      this.$emit('disableNext', 'Please add at least one resident.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please add at least one resident.');
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({key: 'occupants', data: { status: progressStatus.DONE }});
      }
    },
    occStart() {
      this.resetValidation();
    },
    occEnd() {
      this.resetValidation();
    },
    birthdate() {
      this.resetValidation();
    },
    deathDate() {
      this.resetValidation();
    },
    numValidFields(to) {
      if (this.validateStarted && to === this.expectedValidFields) {
        // Save and clear
        this.addResident();
        this.$emit('disableNext', null);
      }
    }
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    addActionText() {
      return this.entryBeingEdited !== null ? 'Update Resident' : 'Add Resident';
    },
    wholeFormEmpty() {
      return this.residents.length < 1;
    }
  },
  methods: {
    ...mapActions(['updateFormSection']),
    savePageContents(){
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        this.updateFormSection({ key: 'occupants', data: { data: this.residents }});
        if (this.wholeFormEmpty) {
          this.updateFormSection({ key: 'occupants', data: { status: null } });
        }
        this.$emit('disableNext', null);
      }
    },
    toggleForm() {
      this.showForm = !this.showForm
    },
    addResident() {
      // trim whitespace on searchable text fields
      const newResident = {
        fName: this.trimEntry(this.fName),
        lName: this.trimEntry(this.lName),
        occupancyStart: this.occStart,
        occupancyEnd: this.occEnd,
        birthdate: this.birthdate,
        deathDate: this.deathDate,
        additionalInfo: this.additionalInfo,
        gravesite: this.trimEntry(this.gravesite),
        gravesiteLink: this.gravesiteLink,
        occStartApproximate: this.occStartApproximate,
        occEndApproximate: this.occEndApproximate,
        birthdateApproximate: this.birthdateApproximate,
        deathDateApproximate: this.deathDateApproximate,
      };
      if (this.entryBeingEdited === null) {
        this.residents.push(newResident);
      } else {
        this.residents[this.entryBeingEdited] = newResident;
      }
      this.clearFormFields();
    },
    saveResidentClicked() {
      // clean up URL if necessary
      if (this.gravesiteLink) {
        this.gravesiteLink = cleanupUrl(this.gravesiteLink)
      }
      // if approximate checked, don't bother validating
      this.shouldValidateField = {
        'occupancy start': !this.occStartApproximate,
        'occupancy end': !this.occEndApproximate,
        'date of birth': !this.birthdateApproximate,
        'date of death': !this.deathDateApproximate
      };
      let numFieldsToValidate = 0;
      Object.entries(this.shouldValidateField).forEach(entry => {
        // [0] is key, [1] is value
        if (entry[1] !== false) {
          numFieldsToValidate++;
        }
      });
      if (numFieldsToValidate === 0) {
        // just save resident
        this.addResident();
      } else {
        this.expectedValidFields = numFieldsToValidate;
        this.validateStarted = true;
        this.numValidFields = 0;
      }
    },
    checkIfDoneValidating(result) {
      if (result.valid) {
        this.numValidFields += 1;
        this.shouldValidateField[result.label] = false;
      } else {
        this.$emit('disableNext', 'Please correct the "' + result.label + '" field before continuing.');
      }
    },
    resetValidation() {
      // if validation is in progress, stop it
      this.shouldValidateField = {
        'occupancy start': false,
        'occupancy end': false,
        'date of birth': false,
        'date of death': false
      };
      this.validateStarted = false;
    },
    clearFormFields() {
      this.fName = null;
      this.lName = null;
      this.occStart = null;
      this.occStartApproximate = false;
      this.occEnd = null;
      this.occEndApproximate = false;
      this.birthdate = null;
      this.birthdateApproximate = false;
      this.deathDate = null;
      this.deathDateApproximate = false;
      this.gravesite = null;
      this.gravesiteLink = null;
      this.additionalInfo = null;
      this.entryBeingEdited = null;
    },
    removeResidentFromTable(index) {
      this.residents.splice(index, 1);
    },
    editResident(index) {
      this.showForm = true;
      this.entryBeingEdited = index;
      const entryToEdit = this.residents[index];

      this.fName = entryToEdit.fName;
      this.lName = entryToEdit.lName;
      this.additionalInfo = entryToEdit.additionalInfo;
      this.gravesite = entryToEdit.gravesite;
      this.gravesiteLink = entryToEdit.gravesiteLink;

      // convert to ISO or make dates approximate if saved in an incorrect format
      if (entryToEdit.occupancyStart && !entryToEdit.occStartApproximate) {
        // try to convert to ISO
        const isoDate = dateStringToISO(entryToEdit.occupancyStart);
        this.occStart = isoDate || entryToEdit.occupancyStart;
        this.occStartApproximate = isoDate == null;
      } else {
        this.occStart = entryToEdit.occupancyStart;
        this.occStartApproximate = entryToEdit.occStartApproximate;
      }

      if (entryToEdit.occupancyEnd && !entryToEdit.occEndApproximate) {
        const isoDate = dateStringToISO(entryToEdit.occupancyEnd);
        this.occEnd = isoDate || entryToEdit.occupancyEnd;
        this.occEndApproximate = isoDate == null;
      } else {
        this.occEnd = entryToEdit.occupancyEnd;
        this.occEndApproximate = entryToEdit.occEndApproximate;
      }

      if (entryToEdit.birthdate && !entryToEdit.birthdateApproximate) {
        const isoDate = dateStringToISO(entryToEdit.birthdate);
        this.birthdate = isoDate || entryToEdit.birthdate;
        this.birthdateApproximate = isoDate == null;
      } else {
        this.birthdate = entryToEdit.birthdate;
        this.birthdateApproximate = entryToEdit.birthdateApproximate;
      }

      if (entryToEdit.deathDate && !entryToEdit.deathDateApproximate) {
        const isoDate = dateStringToISO(entryToEdit.deathDate);
        this.deathDate = isoDate || entryToEdit.deathDate;
        this.deathDateApproximate = isoDate == null;
      } else {
        this.deathDate = entryToEdit.deathDate;
        this.deathDateApproximate = entryToEdit.deathDateApproximate;
      }
    },
    trimEntry(str) {
      if (str) {
        return str.trim();
      }
      return str;
    },
  }
}
</script>

<style scoped>
.top-section {
  margin: 1rem;
}

.table-container {
  background-color: #fafffe;
  padding: 1rem;
}

.add-resident-container {
  background-color: #fafffe;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.add-resident-container p {
  margin-bottom: 0;
}

.add-resident-container:hover {
  color: #003536;
}

.save-resident-button {
  margin-top: 1rem;
  padding-right: 1.75rem;
}

.form-check {
  margin-top: auto;
  margin-bottom: 0.2rem;
}

.delete-icon {
  margin-left: 1rem;
}

.resident-form-buttons {
  white-space: nowrap;
}

.resident-form-buttons a {
  font-size: x-large;
}

.cancel-button {
  margin-right: 1rem;
}

@media screen and (max-width: 992px) {
  .form-check {
    text-align: end;
  }

  .top-section {
    margin: 0;
  }
}
</style>