<template>
  <div class="location-detail-view">
    <div class="description" v-if="sectionData.gadDescription">
      <p>{{sectionData.gadDescription}}</p>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <GmapMap
          class="location-map"
          ref="mapRef"
          :center="coordinates"
          :zoom="15"
          :options="{ streetViewControl: false }"
        >
          <GmapMarker
            :position="{lat: coordinates.lat, lng: coordinates.lng}"
          />
        </GmapMap>
      </div>
      <div class="col-lg-6 col-12 text-section">
        <div v-for="(value, key) in sectionDataToShow" :key="key">
          <div v-if="value">
            <span class="field-name">{{ formatKey(key) }}: </span>
            <span>{{ value || '-' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {detailPageSections, stringFormatter} from "@/helpers/utils";
import {cloneDeep} from "lodash";

export default {
  name: "LocationDetailView",
  props: {
    sectionData: {type: Object, default: () => {}},
    coordinates: {type: Object, default: () => {}}
  },
  data() {
    return {
      map: null,
    }
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then(map => {
      this.map = map;
    });
  },
  computed: {
    sectionDataToShow(){
      const dataCopy = cloneDeep(this.sectionData);
      delete dataCopy['gadDescription'];
      return dataCopy;
    }
  },
  methods: {
    formatKey(key) {
      const specialFormattedKeys = detailPageSections['gad'].sectionLabels;
      const isSpecialKey = Object.keys(specialFormattedKeys).includes(key);
      return isSpecialKey ? specialFormattedKeys[key] : stringFormatter.toSentenceCase(key);
    },
  }
}
</script>

<style scoped>
.location-map {
  height: 360px;
  width: 100%;
  border: 1px solid #418e8d;
}
.field-name {
  font-size: large;
  font-weight: bolder;
  color: #0c5460;
}
.text-section {
  height: 360px;
  overflow-y: scroll;
}
</style>