<template>
    <tr>
        <td v-for="(key, index) in censusKeys" :key="index">
            <a v-if="key !== 'year' && census[key]" :href="census[key]" target="_blank">Click to Visit</a>
            <span v-else>{{formatValue(census[key]) || '-'}}</span>
        </td>
        <td>
            <span v-if="noPdfFiles">-</span>
            <span v-else>
                <a v-for="(link, index) in census.pdfLinks" :href="getLink(link)" :key="index" class="pdf-link">
                    <font-awesome-icon icon="file-pdf"/>
                </a>
            </span>
        </td>
    </tr>
</template>

<script>
import { objectFormat } from '../../helpers/utils';

export default {
    name: "CensusRow",
    props: {
        census: { type: Object, default: () => {}},
    },
    data() {
        return {
            censusKeys: this.getKeys(objectFormat.CENSUS_FIELDS),
        }
    },
    computed: {
        noPdfFiles(){
            return !(this.census.pdfLinks && this.census.pdfLinks.length > 0);
        },
    },
    methods: {
        formatValue(value){
            if(value === -1){
                return;
            }
            return value;
        },
        getKeys(fieldArray){
            let keys = [];
            fieldArray.forEach(eventField => {
                keys.push(eventField.key);
            });
            return keys;
        },
        getLink(fileName){
            return 'https://ancestralhomes.s3.us-east-2.amazonaws.com/'+fileName;
        }
    }
}
</script>

<style scoped>
.pdf-link {
    margin-right: 0.5rem;
}
</style>