<template>
  <div>
    <Alert
      v-if="searchFailed"
      floating
      alert-type="danger"
      title="We're sorry, but we couldn't find a location matching that search. Please try again with a more specific
      location, or use the 'Choose Location on Map' button to set an approximate location."
    />
    <SearchLocationForm v-if="!viewToShow && wholeFormEmpty" @buttonClicked="changeLocationView"/>
    <div v-else class="row location-container">
      <div :class="['col-md-12', {'col-lg-7': !onlyMapIsVisible}]">
        <p v-if="onlyMapIsVisible" class="map-prompt">Click anywhere on the map to set home location.</p>
        <p v-else class="map-prompt">{{mapPromptText}}
          <a class="lat-lng-toggle" @click="toggleLatLngInputs">enter manually</a> using the fields on the right.
        </p>
        <GmapMap
            class="location-map"
            ref="mapRef"
            :center="mapCentre"
            :zoom="mapZoom"
            :options="{ streetViewControl: false }"
            @click="getLatLng"
        >
          <GmapMarker v-if="locationCoordinates" :position="locationCoordinates"/>
        </GmapMap>
      </div>
      <div v-if="!onlyMapIsVisible" class="col-lg-5 col-sm-12">
        <div class="row">
          <ValidationInput
              v-if="showLatLngInputs"
              v-model="latitude"
              type="number"
              label="Latitude"
              class="col-lg-6 col-sm-12"
              validation-type="latitude"
              :required="true"
              :should-validate="shouldValidateField['latitude']"
              @validated="checkIfDoneValidating"
          />
          <ValidationInput
              v-if="showLatLngInputs"
              v-model="longitude"
              type="number"
              label="Longitude"
              class="col-lg-6 col-sm-12"
              validation-type="longitude"
              :required="true"
              :should-validate="shouldValidateField['longitude']"
              @validated="checkIfDoneValidating"
          />
          <ValidationInput
              v-model="country"
              type="text"
              label="Country"
              class="col-12"
              :required="true"
              :should-validate="shouldValidateField['country']"
              @validated="checkIfDoneValidating"
          />
          <div class="additional-info col-12">
            <p>A map location and country name are the only location details that we absolutely require in order
              to record a home in our database.
            </p>
            <p>
              If you have additional location information to add, like city, address, province, or state, you will have
              the opportunity to do so in the 'Choose Details' step.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {submitStatus, progressStatus} from '@/helpers/utils';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import ValidationInput from '../ValidationInput';
import {ROUTENAMES} from "@/helpers/constants";
import SearchLocationForm from "@/components/add/SearchLocationForm";
import Alert from "@/components/Alert";

export default {
  name: "LocationForm",
  components: { Alert, SearchLocationForm, ValidationInput },
  data() {
    return {
      map: null,
      mapCentre: { lat: 53.35, lng: -6.26 },
      mapZoom: 3,
      latitude: null,
      longitude: null,
      country: null,
      shouldValidateField: { latitude: false, longitude: false, country: false },
      validFields: { latitude: false, longitude: false, country: false },
      showLatLngInputs: false,
      viewToShow: null,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    this.fillOutFields();
  },
  beforeDestroy() {
    this.savePageContents();
    this.clearAddressSearch();
    this.$emit('disableNext', null);
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'All location fields are mandatory.');
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({ key: 'location', data: { status: progressStatus.DONE } });
      }
    },
    'getHomeFormSections.location': {
      handler() {
        this.fillOutFields();
      },
      deep: true,
    },
    latitude() {
      this.shouldValidateField['latitude'] = true;
    },
    longitude() {
      this.shouldValidateField['longitude'] = true;
    },
    country() {
      this.shouldValidateField['country'] = true;
    },
    validFields: {
      handler(to) {
        const allTrue = Object.values(to).every(val => val === true);
        if (!this.wholeFormEmpty) {
          let formStatus;
          if(allTrue) {
            // validation passed, yay
            this.$emit('disableNext', null);
            formStatus = progressStatus.DONE;
          }
          else {
            // there are errors
            formStatus = progressStatus.ERROR;
          }
          this.updateFormSection({ key: 'location', data: { status: formStatus } });
        }
      },
      deep: true
    },
    getAutofillStatus(to) {
      if (to === submitStatus.SUCCESS) {
        // set country to country that got returned
        const result = this.getAutofilledLocation;
        this.country = result.country;
      }
    },
    getAddressSearchStatus(to) {
      if(to === submitStatus.SUCCESS){
        const { lat, lng, country } = this.getAddressCoordinates;
        this.latitude = lat;
        this.longitude = lng;
        this.country = country;
        this.mapCentre = { lat, lng };
      }
    },
    viewToShow(to){
      if(to === 'manual'){
        this.showLatLngInputs = true;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAutofillStatus',
      'getAutofilledLocation',
      'getHomeFormSections',
      'getAddressSearchStatus',
      'getAddressCoordinates',
    ]),
    locationCoordinates() {
      if (this.latitude && this.longitude && this.isValidLocation) {
        return {lat: parseFloat(this.latitude + ''), lng: parseFloat(this.longitude + '')};
      }
      return null;
    },
    isValidLocation() {
      const validLat = this.latitude >= -90 && this.latitude <= 90;
      const validLong = this.longitude >= -180 && this.longitude <= 180;
      return validLat && validLong;
    },
    wholeFormEmpty() {
      return !(this.latitude || this.longitude || this.country);
    },
    onlyMapIsVisible(){
      return this.viewToShow === 'map';
    },
    mapPromptText(){
      return this.latitude && this.longitude ? 'Click the map again to change the selected home location, or'
          : 'Click the map to set home location, or'
    },
    searchFailed(){
      return this.getAddressSearchStatus === submitStatus.FAILED;
    }
  },
  methods: {
    ...mapActions(['updateFormSection', 'autofillLocation', 'clearAddressSearch']),
    savePageContents(){
      // don't save changes if the user is navigating away from the page + discarded their changes
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        // Ensure these form values are numbers because the universe is a cruel, cruel place
        const latVal = parseFloat(this.latitude + '');
        const longVal = parseFloat(this.longitude + '');
        const locationData = {latitude: latVal, longitude: longVal, country: this.country};

        this.updateFormSection({key: 'location', data: {data: locationData}});
      }
    },
    fillOutFields(){
      const fieldsFilledOut = this.latitude && this.longitude && this.country;

      if(!fieldsFilledOut) {
        if (this.getHomeFormSections.location?.data) {
          const { data } = this.getHomeFormSections.location;
          this.latitude = data.latitude;
          this.longitude = data.longitude;
          this.country = data.country;
        } else {
          this.$emit('disableNext', 'All location fields are mandatory.');
        }
      }

      if (this.latitude && this.longitude) {
        this.mapCentre = {lat: this.latitude, lng: this.longitude};
        this.mapZoom = 15;
        this.showLatLngInputs = true;
      }
    },
    toggleLatLngInputs() {
      this.showLatLngInputs = !this.showLatLngInputs;
    },
    autofillFromCoordinates() {
      let coordinates = {
        lat: this.latitude,
        lng: this.longitude
      };
      this.autofillLocation(coordinates);
    },
    getLatLng(event) {
      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
      this.viewToShow = 'manual';
      this.autofillFromCoordinates();
    },
    checkIfDoneValidating(result) {
      if (result.valid) {
        this.shouldValidateField[result.label] = false;
        this.validFields[result.label] = true;
      } else {
        // result was invalid, set status to failed
        this.validFields[result.label] = false;
        this.$emit('disableNext', 'Please correct the "' + result.label + '" field before continuing.');
      }
    },
    setupMap(){
      this.$refs.mapRef?.$mapPromise.then(map => {
        this.map = map;
      });
    },
    changeLocationView(buttonClicked){
      this.viewToShow = buttonClicked;
      this.setupMap();
    }
  }
}
</script>

<style scoped>
.right-button {
  margin-top: 1rem;
  float: right;
  margin-left: 1rem;
}

.location-map {
  height: 360px;
  width: 100%;
}

.map-prompt {
  margin-bottom: 0.5rem;
}

.lat-lng-toggle {
  color: #0f6674;
  font-weight: bolder;
}

.lat-lng-toggle:hover {
  text-decoration: underline;
}

.additional-info{
  margin-top: 1rem;
  color: #5a6268;
  font-size: small;
}

@media screen and (max-width: 768px) {
  .location-map {
    margin-bottom: 1rem;
  }
}
</style>