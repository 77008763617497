<template>
  <div>
    <Alert
        v-if="shouldShowDraftMessage"
        :title="`Found a saved draft: ${draftTitle}`"
    >
      <p>
        Click 'Restore' to pick up where you left off, or click 'Delete' to remove the draft altogether.
        You can also ignore this banner and come back to your draft later.
      </p>
      <div class="alert-buttons">
        <button class="btn btn-primary" @click="restoreDraft">Restore</button>
        <button class="btn btn-danger" @click="deleteDraft">Delete</button>
      </div>
    </Alert>
    <Alert v-if="toastMessage" :title="toastMessage" floating/>
    <Alert
        v-if="deleteError"
        :title="'Something went wrong while deleting. Please try again.'"
        :alert-type="'danger'"
        floating
    />
    <Alert v-if="emailSendStatus" :title="emailStatusTitle" :alert-type="emailAlertType" floating/>
    <div class="page-bg">
      <div v-if="!getLoggedInUser">
        <div class="options-container light-teal-bg">
          <div class="card">
            <div class="top-icon-container">
              <font-awesome-icon icon="home" class="top-icon home-icon"/>
            </div>
            <div class="card-body">
              <h5 class="card-title title-font">Ready to Add a Home?</h5>
              <router-link :to="{name: 'login', params: { register: true }}">
                <button class="btn btn-primary">Create a Free Account</button>
              </router-link>
              <p>Already have an account?</p>
              <router-link :to="{name: 'login', params: { next: 'add' }}">
                <button class="btn btn-primary">Log In</button>
              </router-link>
            </div>
          </div>
          <div class="card">
            <div class="top-icon-container">
              <font-awesome-icon icon="search" class="top-icon search-icon"/>
            </div>
            <div class="card-body">
              <h5 class="card-title title-font">Just Browsing?</h5>
              <router-link to="/search">
                <button class="btn btn-secondary">Search for Homes</button>
              </router-link>
              <a href="https://www.facebook.com/groups/140590951577780/" target="_blank">
                <button class="btn btn-secondary">Join our Facebook Community</button>
              </a>
            </div>
          </div>
          <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
        </div>
      </div>
      <div v-else>
        <div class="page-header">
          <div class="title-section">
            <h1 class="title-font">{{ pageHeaderText }}</h1>
            <p>
              Follow the steps outlined below to fill out an entry for the Ancestral Homes database.
              <br>
              Once you've filled out all of the details you want for a particular section, click the 'next' button at the
              bottom of the page to move on to the next one.
            </p>
          </div>
          <button v-if="isMobile" class="btn btn-secondary" @click="openHelpPopup">?</button>
          <button v-else class="btn btn-secondary" v-tooltip="helpTooltip" @click="openHelpPopup">Need Help?</button>
        </div>
        <div :class="['page-body', {'light-teal-bg': !isMobile}]">
          <table v-if="isMobile" class="prog-table-mobile">
            <tr
                v-for="(node, index) in progressNodes"
                :key="index"
            >
              <td class="left-cell" @click="jumpToNode(index)">{{ node.label }}</td>
              <td class="right-cell" :style="getTextColour(node.status)">{{ node.status || '-' }}</td>
            </tr>
          </table>
          <hr v-if="isMobile"/>
          <ProgressBar v-else
            :progress-nodes="progressNodes"
            :current-page-index="currentPageIndex"
            @nodeClicked="jumpToNode"
          />
          <div class="form-contents">
            <component
              :is="currentPage.component"
              :edit-id="existingHomeID"
              :trigger-save-draft="shouldSaveDraft"
              @disableNext="disableNextButton"
              @showModal="handleShowModal"
            />
          </div>
          <hr v-if="isMobile"/>
          <div class="bottom-buttons" v-if="!isLastPage">
            <button :style="backButtonVisibility" class="btn btn-primary" @click="toPreviousPage">
              Back
            </button>
            <button v-if="isEditPage" class="btn btn-danger" @click="showDeletePopup">Delete Home</button>
            <button class="btn btn-secondary" @click="saveDraft">{{ draftBtnText }}</button>
            <button v-if="hasError" class="btn btn-danger" @click="openHelpPopup">Ask for Help</button>
            <button
                :class="['btn','btn-primary', {'btn-disabled': !canGoForward}]"
                v-tooltip="errorTooltip"
                @click="toNextPage"
            >
              {{ nextButtonText }}
            </button>
            <p class="warning-text" v-if="isMobile && errorTooltip">{{errorTooltip}}</p>
          </div>
        </div>
      </div>
    </div>
    <Modal
      ref="confirmationModal"
      class="add-home-modal"
      v-if="isModalVisible"
      :primary-action="modalButtonText"
      @close="closeModal"
      @confirm="confirmClicked"
    >
      <template #header>
        <h3 class="modal-title">{{ modalTitle }}</h3>
      </template>
      <template #body>
        <p class="modal-body">{{ modalText }}</p>
        <div v-if="showHelpSection" class="help-message">
          <div v-if="hasError && errorTooltip" class="error-printout">
            <h5>It looks like you're unable to move forward for the following reason:</h5>
            <p class="modal-body">{{errorTooltip}}</p>
          </div>
          <textarea v-model="userQuestion" class="help-textbox" id="helpTextbox"/>
          <p>A response to your question will be sent to {{userEmail}} within 24 hours.</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DRAFT_STORAGE_KEY, progressStatus, submitStatus} from "@/helpers/utils";
import LocationForm from "@/components/add/LocationForm.vue";
import SearchLocationForm from "@/components/add/SearchLocationForm.vue";
import SelectDetails from "@/components/add/SelectDetails.vue";
import ResidentForm from "@/components/add/ResidentForm.vue";
import GADForm from "@/components/add/GADForm.vue";
import CensusForm from "@/components/add/CensusForm.vue";
import StructureForm from "@/components/add/StructureForm.vue";
import EventForm from "@/components/add/EventForm.vue";
import PhotoForm from "@/components/add/PhotoForm.vue";
import ReferenceForm from "@/components/add/ReferenceForm.vue";
import ReviewDetailWrapper from "@/views/ReviewDetailWrapper";
import SubmitSection from "@/components/add/SubmitSection";
import Alert from "@/components/Alert";
import Modal from "@/components/Modal";
import {frontendConfig} from "@/helpers/frontend_config";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "AddHome",
  components: {
    ProgressBar,
    Modal,
    Alert,
    SearchLocationForm,
    LocationForm,
    SelectDetails,
    ResidentForm,
    GADForm,
    CensusForm,
    StructureForm,
    EventForm,
    PhotoForm,
    ReferenceForm,
    ReviewDetailWrapper,
    SubmitSection
  },
  data() {
    return {
      helpTooltip: 'Need help? Click this button to send us a message! <br><br>' +
          'While you wait, use the links at the bottom of the page to check out our YouTube Tutorials ' +
          'or reach out on the official Ancestral Homes Network Facebook group.',
      currentPageIndex: 0,
      nextButtonErrorMessage: null,
      isEditPage: false,
      homeToEdit: null,
      draftInUse: false,
      toastMessage: null,
      isDraftOutdated: false,
      updatedLocalStorage: false,
      modalTitle: null,
      modalText: null,
      modalAction: null,
      modalArgs: null,
      modalButtonText: null,
      isModalVisible: false,
      deleteError: false,
      shouldLeave: false,
      shouldSaveDraft: false,
      showHelpSection: false,
      userQuestion: null,
      emailSendStatus: null,
    }
  },
  beforeMount() {
    // check whether to display home in edit mode
    const edit = this.$route.params.homeToEdit;
    if (edit && this.userOwnsHome(edit.owner_id)) {
      this.isEditPage = true;
      this.setHomeToEdit(edit)
      this.homeToEdit = edit;
    }
    else {
      // clear out any leftover data, if any has managed to persist
      this.clearForm();
    }
    this.isDraftOutdated = localStorage.getItem(DRAFT_STORAGE_KEY) !== JSON.stringify(this.getHomeFormSections);
    // make sure submit doesn't show as 'done' yet
    this.resetSubmitStatus();
  },
  beforeRouteLeave(to, from, next) {
    const changesWereMade = this.isDraftOutdated && !this.formIsEmpty;
    const redirectingOnSuccess = this.homeDeleteStatus === submitStatus.SUCCESS
        || this.homeSubmitStatus === submitStatus.SUCCESS;

    if(changesWereMade && !redirectingOnSuccess) {
      const details = {
        title: 'Leave Page?',
        text: 'If you leave now, any unsaved changes on this page will be lost.',
        action: this.leavePage,
      }
      this.handleShowModal(details);

      this.$nextTick(() => {
        this.$refs.confirmationModal.$on('close', () => {
          next(false);
        });
        this.$refs.confirmationModal.$on('confirm', () => {
          this.clearForm();
          next();
        })
      });
    }
    else if(!this.isDraftOutdated){
      // latest changes have been saved already, no need to warn before clearing
      this.clearForm();
      next();
    }
    else {
      next();
    }
  },
  watch: {
    toastMessage(to) {
      if (to) {
        // remove toast message after a few seconds
        setTimeout(() => {
          this.toastMessage = null;
        }, 4000);
      }
    },
    deleteError(to) {
      if (to) {
        // remove toast message after a few seconds
        setTimeout(() => {
          this.deleteError = false;
        }, 4000);
      }
    },
    updatedLocalStorage(to) {
      if (to) {
        // reset after a few seconds
        setTimeout(() => {
          this.updatedLocalStorage = false;
        }, 1000);
      }
    },
    emailSendStatus(to){
      if(to){
        // remove toast message after a few seconds
        setTimeout(() => {
          this.emailSendStatus = null;
        }, 7000);
      }
    },
    currentPageIndex(){
      // if page changes, this will need to be reset so child pages can listen for changes
      this.shouldSaveDraft = false;
    },
    getHomeFormSections: {
      handler(to) {
        this.isDraftOutdated = localStorage.getItem(DRAFT_STORAGE_KEY) !== JSON.stringify(to);
      },
      deep: true,
    },
    homeDeleteStatus(to) {
      if (to === submitStatus.SUCCESS) {
        // clean up photos and documents in s3
        if (this.homeToEdit.photos) {
          this.homeToEdit.photos.forEach(photoName => {
            this.deletePhoto(photoName);
          });
        }
        if (this.homeToEdit.censuses) {
          this.homeToEdit.censuses.forEach(census => {
            if(census.pdfLinks) {
              census.pdfLinks.forEach(link => {
                this.deletePhoto(link);
              })
            }
          });
          // redirect to user profile
          this.$router.push({name: 'profile'});
        } else if (to === submitStatus.FAILED) {
          this.deleteError = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'getHomeFormSections', 'homeDeleteStatus', 'formIsEmpty', 'homeSubmitStatus']),
    // draft handling
    storedDraft() {
      const draftStr = localStorage.getItem(DRAFT_STORAGE_KEY);
      return JSON.parse(draftStr);
    },
    draftBtnText() {
      if (this.updatedLocalStorage) {
        // need to re-compute when this happens, but needs deep watching. So here's a useless print statement
        console.log('localstorage updated');
      }
      // Case 1: no draft saved.
      if (localStorage.getItem(DRAFT_STORAGE_KEY) === null) {
        return 'Save Draft';
      }
      // Case 2: a draft exists, and it matches the current form.
      if (!this.isDraftOutdated) {
        return 'Draft Saved';
      }
      // Case 3: a draft exists, but it doesn't match the current form.
      return 'Overwrite Existing Draft';
    },
    shouldShowDraftMessage() {
      if (this.updatedLocalStorage) {
        // need to re-compute when this happens, but needs deep watching. So here's a useless print statement
        console.log('localstorage updated');
      }
      return !this.draftInUse && !this.isEditPage && localStorage.getItem(DRAFT_STORAGE_KEY) && this.getLoggedInUser;
    },
    draftTitle() {
      const {location, structure} = this.storedDraft;
      const prefix = structure?.data?.structName || location?.data?.country || 'Unnamed home';
      const suffix = structure?.data?.year || 'year unknown';
      return `${prefix} (${suffix})`;
    },
    // displaying progress
    progressNodeWidth() {
      return {width: `calc(100% / ${this.progressNodes.length})`};
    },
    progressNodes() {
      const selectedNodes = [];
      Object.entries(this.getHomeFormSections).forEach(([key, value]) => {
        if (value !== null) {
          const optionToAdd = this.getHomeFormSections[key];
          selectedNodes.push(optionToAdd);
        }
      })
      return selectedNodes
    },
    currentPage() {
      return this.progressNodes[this.currentPageIndex]
    },
    hasError() {
      return this.currentPage?.status === progressStatus.ERROR || this.nextButtonErrorMessage;
    },
    // navigation
    backButtonVisibility() {
      return this.canGoBack ? {} : {opacity: 0};
    },
    isLastPage() {
      return this.currentPageIndex === this.progressNodes.length - 1;
    },
    canGoBack() {
      return this.currentPageIndex > 0;
    },
    canGoForward() {
      return !(this.hasError || this.isLastPage);
    },
    errorTooltip() {
      if (this.nextButtonErrorMessage) {
        return this.nextButtonErrorMessage;
      }
      return this.hasError ? 'This section has errors. Please fix them before continuing.' : '';
    },
    nextButtonText() {
      const finalStep = this.isEditPage ? 'Save Changes' : 'Submit';
      return this.currentPage.label === 'Review' ? finalStep : 'Next';
    },
    // editing
    pageHeaderText() {
      return this.isEditPage ? 'Edit Home' : 'Add a Home';
    },
    existingHomeID() {
      return this.homeToEdit?._id;
    },
    // help popup
    userEmail(){
      const contactEmail = this.getLoggedInUser.settings ? this.getLoggedInUser.settings.contact_email : null;
      return contactEmail || this.getLoggedInUser.profile.email;
    },
    emailStatusTitle(){
      switch (this.emailSendStatus){
        case submitStatus.SUCCESS:
          return 'Message sent! We will reply as soon as we can.';
        case submitStatus.FAILED:
          return 'There was an issue sending your message. Please try again or reach out to us using the social media ' +
                  'links at the bottom of the page.';
        default:
          return null;
      }
    },
    emailAlertType(){
      return this.emailSendStatus === submitStatus.FAILED ? 'danger' : 'success';
    },
    isMobile(){
      return window.innerWidth <= 600;
    },
  },
  methods: {
    ...mapActions([
      'updateFormSection',
      'setHomeToEdit',
      'overwriteForm',
      'deleteHome',
      'clearForm',
      'resetSubmitStatus'
    ]),
    getTextColour(status) {
      if (status === 'ERROR') {
        return {color: 'red'};
      }
      if (status === 'DONE') {
        return {color: 'green'};
      }
      return null;
    },
    // navigation
    toNextPage() {
      if (this.canGoForward) {
        this.currentPageIndex += 1;
      }
    },
    toPreviousPage() {
      if (this.canGoBack) {
        this.currentPageIndex -= 1;
      }
    },
    disableNextButton(message) {
      this.nextButtonErrorMessage = message;
    },
    jumpToNode(index) {
      if (index < this.progressNodes.length - 1) {
        this.currentPageIndex = index;
      }
    },
    leavePage(){
      this.shouldLeave = true;
      this.clearForm();
    },
    // drafts
    saveDraft() {
      this.shouldSaveDraft = true;
      localStorage.setItem(DRAFT_STORAGE_KEY, JSON.stringify(this.getHomeFormSections));
      this.toastMessage = 'Saved Draft!'
      this.isDraftOutdated = false;
      this.updatedLocalStorage = true;
    },
    deleteDraft() {
      localStorage.removeItem(DRAFT_STORAGE_KEY);
      this.updatedLocalStorage = true;
    },
    restoreDraft() {
      this.overwriteForm(this.storedDraft);
      this.draftInUse = true;
      this.isDraftOutdated = false;
    },
    // editing
    userOwnsHome(homeOwnerId) {
      const loggedInUser = this.getLoggedInUser;
      return loggedInUser && loggedInUser.id === homeOwnerId;
    },
    showDeletePopup() {
      const details = {
        title: 'Confirmation',
        text: 'Are you sure you want to delete this home? Once complete, this action cannot be undone.',
        action: this.confirmDeleteHome,
        args: this.homeToEdit._id,
        confirmButtonText: 'Delete',
      };
      this.handleShowModal(details);
    },
    confirmDeleteHome(id) {
      this.deleteHome(id);
      this.closeModal();
    },
    // popup methods
    handleShowModal(details) {
      const {title, text, action, args, confirmButtonText} = details;
      this.modalTitle = title;
      this.modalText = text;
      this.modalAction = action;
      this.modalArgs = args;
      this.modalButtonText = confirmButtonText;
      this.isModalVisible = true;
    },
    confirmClicked() {
      this.modalAction(this.modalArgs);
      this.closeModal();
    },
    closeModal() {
      this.isModalVisible = false;
      this.modalText = null;
      this.modalTitle = null;
      this.modalAction = null;
      this.modalArgs = null;
      this.modalButtonText = null;
      this.showHelpSection = false;
    },
    openHelpPopup(){
      // update form so it fully matches what the user sees if they end up sending a message
      this.shouldSaveDraft = true;
      this.showHelpSection = true;
      const details = {
        title: 'Ask for Help',
        text: "Having trouble completing your entry? Use the text box below to ask us a question or report an issue. " +
            "Your question, as well as the home details you've filled out so far, will be sent to the " +
            "Ancestral Homes Network team to be addressed.",
        action: this.sendHelpRequest,
        confirmButtonText: 'Send',
      };
      this.handleShowModal(details);
    },
    sendHelpRequest(){
      // sends request to me via email. Limit 25 per day but if we hit that I'd be surprised
      const request = new XMLHttpRequest();
      const self = this;
      request.onreadystatechange = () => {
        if (request.readyState === 4 && request.status === 200) {
          self.emailSendStatus = submitStatus.SUCCESS;

        } else
        if(request.readyState === 4) {
          console.log('failed to send email: '+ request.response);
          self.emailSendStatus = submitStatus.FAILED;
        }
      };

      // fill out email
      const subject = 'Add a Home - Help Request Submitted';
      const message = `User email: ${this.userEmail}\n
      User question: ${this.userQuestion}\n
      Section sent from: ${this.currentPage.label}\n
      Error message (if any): ${this.errorTooltip}\n
      Home form contents: ${JSON.stringify(this.getHomeFormSections)}`
      const emailData = { access_token: frontendConfig.POSTMAIL_ACCESS_TOKEN, subject, text: message };
      const params = this.toEmailParams(emailData);

      request.open("POST", "https://postmail.invotes.com/send", true);
      request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      request.send(params);
    },
    toEmailParams(emailData){
      const params = [];
      Object.entries(emailData).forEach(([key, value]) => {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      })
      return params.join("&");
    }
  }
}
</script>

<style scoped>
/*logged out view*/
.options-container {
  display: flex;
  position: relative;
  padding: 2.5rem 1rem;
  overflow: hidden;
}

.options-container button {
  width: 100%;
  height: 5rem;
  font-size: x-large;
  margin-bottom: 1rem;
}

.options-container a {
  width: 100%;
}

.top-icon-container {
  font-size: 16vh;
  text-align: center;
  margin-top: -6rem;
}

.home-icon {
  color: #006060;
}

.search-icon {
  color: #6c757d;
}

.card {
  width: 50%;
  margin: 5rem 1rem 1rem 1rem;
  padding: 1rem;
  align-items: center;
  z-index: 1;
}

.card-title {
  text-transform: capitalize;
  font-size: xx-large;
  text-align: center;
}

.card-body {
  width: 60%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  margin-bottom: 0.5rem;
  font-size: small;
}

/* draft alert */
.alert-buttons {
  margin-bottom: -0.5rem;
}

.alert-buttons .btn {
  margin: 1rem 1rem 0 0;
}

/* logged in view */
.page-header {
  display: flex;
}

.page-header button {
  margin-left: auto;
  height: 90%;
}

.title-section p {
  font-size: 14px;
}

.btn {
  font-size: large;
  padding: 0.5rem 1rem;
}

.page-body {
  padding: 1rem;
  border-radius: 4px;
}

.form-contents {
  background: white;
  min-height: 60vh;
  border: 1px lightgrey solid;
  padding: 1rem;
}

.bottom-buttons {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

/* help popup */
.modal-title {
  margin: 0;
}
.modal-body {
  font-size: medium;
}
.help-message {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.error-printout {
  background-color: #f5c6cb;
  border: 1px solid #a92222;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.help-textbox {
  min-height: 20vh;
}

@media screen and (max-width: 600px) {
  /*logged out view (mobile)*/
  .options-container {
    flex-direction: column;
    padding: 0;
  }

  .top-icon-container {
    font-size: 10vh;
    text-align: center;
    margin-top: 0;
  }

  .card {
    width: 100%;
    margin: 0;
    border: none;
    padding: 0;
  }

  .card-body {
    width: 100%;
    padding: 0;
  }

  /*logged in view (mobile)*/
  .page-body {
    padding: 0;
  }

  .form-contents {
    padding: 0;
    border: none;
  }

  .page-header button {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .prog-table-mobile {
    width: 100%;
    margin: 1rem 0;
  }

  .left-cell {
    cursor: pointer;
  }

  .right-cell {
    text-align: right;
  }

  .bottom-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-buttons .btn {
    margin-bottom: 1rem;
    width: 100%;
  }
}
</style>