<template>
  <footer class="container-fluid footer">
    <div class="row my-3">
      <div class="col-sm-12 col-md-4">
        <div class="row logo-row">
          <div class="col-md-12 logo">
            <img src="../assets/logo-round.svg" class="footer-logo"/>
            <h3 class="title-font">Ancestral Homes Network</h3>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="row">
              <div class="col-md-12">
                <h5>Connect With Us</h5>
              </div>

              <div class="col-md-12">
                <ul class="links">
                  <!-- email -->
                  <li class="social-media-link">
                    <a href="mailto:info@ancestralhomes.net" title="Email" target="_blank">
                      <font-awesome-icon icon="envelope" class="social-media-icon"/>Email
                    </a>
                  </li>
                  <!-- Instagram -->
                  <li>
                    <a class="social-media-link" href="https://www.instagram.com/ancestralhomesnetwork/" title="Instagram" target="_blank"
                       @click="socialMediaLinkClicked('Instagram')">
                      <font-awesome-icon :icon="['fab', 'instagram']" class="social-media-icon"/>  Instagram
                    </a>
                  </li>
                  <!-- Facebook -->
                  <li>
                    <a class="social-media-link" href="https://www.facebook.com/AncestralHomes" title="Facebook" target="_blank"
                       @click="socialMediaLinkClicked('Facebook')">
                      <font-awesome-icon :icon="['fab', 'facebook']" class="social-media-icon"/>
                      Facebook
                    </a>
                  </li>
                  <!-- YouTube -->
                  <li>
                    <a class="social-media-link" href="https://www.youtube.com/channel/UCqodtTTG9bqcOjD0MccEWbQ" title="YouTube" target="_blank"
                       @click="socialMediaLinkClicked('YouTube')">
                      <font-awesome-icon :icon="['fab', 'youtube']" class="social-media-icon"/>
                      YouTube
                    </a>
                  </li>
                  <!-- LinkedIn -->
                  <li>
                    <a class="social-media-link" href="https://www.linkedin.com/company/ancestralhomes-network/" title="LinkedIn" target="_blank"
                       @click="socialMediaLinkClicked('LinkedIn')">
                      <font-awesome-icon :icon="['fab', 'linkedin']" class="social-media-icon"/>
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>

          <!-- Navigation -->
          <div class="col-sm-12 col-md-4">
            <div class="row">
              <div class="col-md-12">
                <h5>Site Navigation</h5>
              </div>
              <div class="col-md-12">
                <ul class="links">
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/about">About</router-link>
                  </li>
                  <li>
                    <router-link to="/contribute">Contribute</router-link>
                  </li>
                  <li>
                    <router-link to="/search">Search</router-link>
                  </li>
                  <li>
                    <router-link to="/add">Add a Home</router-link>
                  </li>
                  <li>
                    <router-link to="/login">Login/Register</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div class="row">
              <div class="col-md-12">
                <h5>Additional Resources</h5>
              </div>

              <div class="col-md-12">
                <ul class="links">
                  <li><a href="http://donnellycanada.com/" target="_blank" @click="socialMediaLinkClicked('blog')">
                    Donnelly Canada Blog
                  </a></li>
                  <li><a href="https://www.youtube.com/channel/UCqodtTTG9bqcOjD0MccEWbQ" target="_blank"
                         @click="socialMediaLinkClicked('YouTube')">
                    Video Tutorials
                  </a></li>
                </ul>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <!-- Copyright & Policies -->
    <div class="row copyright-container px-4">

      <!-- Copyright -->
      <div class="col-md-6">
        <p>Copyright Ancestral Homes Network 2024</p>
      </div>

      <!-- Privacy & Terms -->
      <div class="col-md-6 text-right">
        <p>
          <router-link to="/privacy">Privacy and Security</router-link>
          &
          <router-link to="/terms-conditions">Terms and Conditions</router-link>
        </p>
      </div>
    </div>

  </footer>
</template>

<script>
import {sendGoogleAnalyticsEvent} from '../helpers/utils';

export default {
  name: "Footer",
  methods: {
    socialMediaLinkClicked(channelName) {
      sendGoogleAnalyticsEvent(this, 'Clicked social media link (footer)', 'generate_lead', channelName);
    },
    downloadAppClicked() {
      sendGoogleAnalyticsEvent(this, 'Clicked download app button', 'generate_lead');
    }
  }
}
</script>
<style>
.footer {
  width: 100%;
  min-height: 60px;
  background-color: #f5f5f5;
}

footer a {
  color: #006060 !important;
}

footer a:hover {
  color: #003536 !important;
  text-decoration: none !important;
}

.links {
  list-style: none;
  padding-left: 0;
}

.links li {
  margin-left: 5px;
}

.links li a:hover img {
  filter: invert(25%) sepia(13%) saturate(5918%) hue-rotate(146deg) brightness(102%) contrast(101%) !important;
}

.links img {
  width: 20px;
  margin-right: 4px;
}

.copyright-container {
  border-top: 1px solid darkgrey;
  font-size: 10px;
  color: darkgrey;
}

.copyright-container p {
  margin: 0.5rem auto;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.logo h3 {
  margin: 0;
}

.footer-logo {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.social-media-icon {
  color: #343a40;
  font-size: larger;
  margin-right: 0.5rem;
}
.social-media-icon:hover {
  color: #0c5460;
}
.social-media-link {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  line-height: 1;
}
</style>