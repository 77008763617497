import { render, staticRenderFns } from "./ImageCarousel.vue?vue&type=template&id=04e3b5d2&scoped=true"
import script from "./ImageCarousel.vue?vue&type=script&lang=js"
export * from "./ImageCarousel.vue?vue&type=script&lang=js"
import style0 from "./ImageCarousel.vue?vue&type=style&index=0&id=04e3b5d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e3b5d2",
  null
  
)

export default component.exports