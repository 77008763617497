<template>
    <tr>
        <td>{{resident.fName || '-'}}</td>
        <td>{{resident.lName || '-'}}</td>
        <td>{{occupancyPeriod}}</td>
        <td>{{formatDate(resident.birthdate, resident.birthdateApproximate) || '-'}}</td>
        <td>{{formatDate(resident.deathDate, resident.deathDateApproximate) || '-'}}</td>
        <td class="text-center">
            {{resident.gravesite || '-'}}
            <a v-if="resident.gravesiteLink" :href="resident.gravesiteLink" target="_blank">Link</a>
        </td>
        <td class="additional-info">{{resident.additionalInfo || '-'}}</td>
    </tr>
</template>

<script>
import { stringFormatter } from '../../helpers/utils';

export default {
    name: "ResidentRow",
    props: {
       resident: { type: Object, default: () => {}}
    },
    computed: {
        occupancyPeriod() {
            const { occupancyStart, occupancyEnd, occStartApproximate, occEndApproximate } = this.resident;
            if (occupancyStart && occupancyEnd){
                return this.formatDate(occupancyStart, occStartApproximate)+' - '+this.formatDate(occupancyEnd, occEndApproximate);
            }
            else if (occupancyStart){
                return this.formatDate(occupancyStart, occStartApproximate)+' - Unknown';
            }
            else if (occupancyEnd){
                return 'Unknown - '+this.formatDate(occupancyEnd, occEndApproximate);
            }
            return '-'
        }
    },
    methods: {
        formatDate(date, isApproximate) {
            if (date) {
                return isApproximate ? date : stringFormatter.toHumanReadableDate(date);
            }
            return;
        }
    }
}
</script>

<style scoped>
.additional-info {
    max-width: 25rem;
}
</style>