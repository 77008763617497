import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store'
import {frontendConfig} from './helpers/frontend_config';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import './styles/global.css';
import router from './router'

import * as VueGoogleMaps from 'vue2-google-maps';
import VueGeolocation from 'vue-browser-geolocation';
import vue2Dropzone from 'vue2-dropzone';
import VueGtag from 'vue-gtag'; // For Google Analytics
import VTooltip from 'v-tooltip';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faEdit, faTrash, faTimes, faHome, faGlobe, faSearch,
    faBook, faInfoCircle, faEyeSlash, faEye, faCheck,
    faChessRook, faFilePdf, faBullhorn, faCaretRight,
    faCaretLeft, faMugHot, faEnvelope, faStar, faUsers,
    faCalendar, faCoins
} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faFortAwesome, faInstagram, faLinkedin, faYoutube} from '@fortawesome/free-brands-svg-icons' // needs fab prefix, ex. :icon="['fab', 'fort-awesome']"
// free-regular-svg-icons also included but not currently used

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faEdit);
library.add(faTrash);
library.add(faTimes);
library.add(faHome);
library.add(faGlobe);
library.add(faSearch);
library.add(faBook);
library.add(faInfoCircle);
library.add(faEyeSlash);
library.add(faEye);
library.add(faCheck);
library.add(faChessRook);
library.add(faFortAwesome);
library.add(faFilePdf);
library.add(faBullhorn);
library.add(faCaretRight);
library.add(faCaretLeft);
library.add(faMugHot);
library.add(faEnvelope);
library.add(faInstagram);
library.add(faFacebook);
library.add(faYoutube);
library.add(faLinkedin);
library.add(faStar);
library.add(faUsers);
library.add(faCalendar);
library.add(faCoins)

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueGoogleMaps, {
    load: {
        key: frontendConfig.GOOGLE_MAPS_API_KEY
    }
});
Vue.use(VTooltip);
Vue.use(VueGeolocation);
Vue.use(vue2Dropzone);
Vue.use(VueGtag, {
    property: [
        {id: frontendConfig.GOOGLE_ANALYTICS_MEASUREMENT_ID},
        {id: frontendConfig.GOOGLE_ADS_CONVERSION_TRACKING_TAG}
    ]
});

new Vue({
    router,
    render: h => h(App),
    store: store,
}).$mount('#app');
