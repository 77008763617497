import moment from 'moment';
import {frontendConfig} from "@/helpers/frontend_config";

// Constants
export const LAST_SEARCH_KEY = 'mostRecentSearchQuery';
export const DRAFT_STORAGE_KEY = 'homeDraft';
export const LOGIN_FORM_KEY = 'loginFormValues';
export const USER_KEY = 'userInfo';
export const IMG_API_URL = '/api/photos';
export const objectFormat = {
    GAD_FIELDS: [
        {key: 'address', label: 'Address'},
        {key: 'subdivision', label: 'Subdivision'},
        {key: 'city', label: 'City'},
        {key: 'county', label: 'County'},
        {key: 'townland', label: 'Townland'},
        {key: 'township', label: 'Township'},
        {key: 'parish', label: 'Parish'},
        {key: 'concession', label: 'Concession'},
        {key: 'lot', label: 'Lot'},
        {key: 'municipal', label: 'Municipal'},
        {key: 'mapSheetNum', label: 'Map Sheet Number'},
        {key: 'mapSeries', label: 'Map Series'},
        {key: 'gadDescription', label: 'Description'},
    ],
    EVENT_FIELDS: [
        {key: 'yearRecorded', label: 'Year'},
        {key: 'eventDesc', label: 'Description'},
    ],
    RESIDENT_FIELDS: [
        {key: 'fName', label: 'First Name'},
        {key: 'lName', label: 'Last Name'},
        {key: 'occupancyStart', label: 'Occupancy Start'},
        {key: 'occupancyEnd', label: 'Occupancy End'},
        {key: 'birthdate', label: 'Birth Date'},
        {key: 'deathDate', label: 'Death Date'},
        {key: 'additionalInfo', label: 'Additional Information'},
        {key: 'gravesite', label: 'Gravesite'},
        {key: 'gravesiteLink', label: 'Gravesite Link'},
    ],
    CENSUS_FIELDS: [
        {key: 'year', label: 'Year'},
        {key: 'countryLink', label: 'Country Link'},
        {key: 'townlandLink', label: 'Townland Link'},
        {key: 'residentsLink', label: 'Residents Link'},
    ],
    REFERENCE_FIELDS: [
        {key: 'refName', label: 'Reference'},
        {key: 'refAuthor', label: 'Author'},
        {key: 'refLink', label: 'Link'},
    ]
};
export const submitStatus = {
    SUCCESS: 'success',
    LOADING: 'loading',
    FAILED: 'failed',
};
export const progressStatus = {
    ERROR: 'ERROR',
    DONE: 'DONE'
}
export const formKeys = {
    LOCATION: 'location',
    GAD: 'gad',
    STRUCTURE: 'structure',
    EVENTS: 'events',
    RESIDENTS: 'occupants',
    CENSUS: 'censuses',
    PHOTOS: 'photos',
    REFERENCES: 'references',
};

export const addHomeDetails = {
    occupants: {
        label: 'Family Names & Residents',
        component: 'ResidentForm',
        info: 'Biographical details about the people who lived in this home.'
    },
    gad: {
        label: 'Location Details',
        component: 'GADForm',
        info: 'More detailed information about where the home is located, such as town, municipality, or parish information.<br><br>Many of these details can be filled in automatically if desired, using the location you have already provided.'
    },
    census: {
        label: 'Census',
        component: 'CensusForm',
        info: 'A place to add links or upload PDF files containing copies of census records related to this property.'
    },
    structure: {
        label: 'Property Details',
        component: 'StructureForm',
        info: 'This section is for entering information about the home itself.<br><br>Examples might include the year it was built, the materials used to build it, or a name it was referred to by.'
    },
    events: {
        label: 'Family & Property Events',
        component: 'EventForm',
        info: 'Noteworthy historical or personal events that impacted the property or its inhabitants.'
    },
    photos: {
        label: 'Photos',
        component: 'PhotoForm',
        info: 'Upload photos of the home, either contemporary or historical.'
    },
    references: {
        label: 'References',
        component: 'ReferenceForm',
        info: 'If you used external resources to complete this entry, you may cite them here.'
    },
}

export const detailPageSections = {
    gad: {
        label: 'Location',
        info: 'More detailed information about where the home is located.',
        useTable: false,
        customComponent: 'LocationDetailView',
        sectionLabels: {
            mapSheetNum: 'Map Sheet Number',
            mapSeries: 'Map Series',
            gadDescription: 'Description'
        }
    },
    structure: {
        label: 'Property Details',
        info: 'Information about the structure and its construction.',
        useTable: false,
        customComponent: 'StructureDetailView',
        sectionLabels: {
            roofDesc: 'Roof Description',
            wallDesc: 'Wall Description',
            floorDesc: 'Floor Description',
            numDoors: 'Number of Doors',
            numWindows: 'Number of Windows',
            numRooms: 'Number of Rooms',
            structName: 'Structure Name',
            structDesc: 'Description',
        }
    },
    occupants: {
        label: 'Family Names & Residents',
        info: 'Biographical details about the people who lived in this home.',
        useTable: true,
        sectionLabels: {}
    },
    censuses: {
        label: 'Census',
        info: 'Links or PDF files containing copies of census records related to this home.',
        useTable: true,
        sectionLabels: {}
    },
    census: {
        label: 'Census',
        info: 'Links or PDF files containing copies of census records related to this home.',
        useTable: true,
        sectionLabels: {}
    },
    references: {label: 'References', info: 'External resources used to complete this entry.', useTable: true},
}

// Functions
export const getS3Url = (urlSuffix) => {
    return "https://s3-us-east-2.amazonaws.com/ancestralhomes/" + urlSuffix;
};

export const getPlaceholderUrl = () => {
    var images = require.context('../assets/', false, /\.png$/);
    return images('./app_icon_512.png');
};

export const getImgErrorUrl = () => {
    var images = require.context('../assets/', false, /\.png$/);
    return images('./house_icon_error.png');
};

export const stringFormatter = {
    toSentenceCase: string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toHumanReadableDate: date => {
        const momentDate = moment(date);
        return momentDate.format('Do MMMM YYYY');
    },
    toLocation: (latitude, longitude) => {
        const latStr = (latitude >= 0) ? latitude.toFixed(3) + 'º N' : (-1 * latitude).toFixed(3) + 'º S';
        const lngStr = (longitude >= 0) ? longitude.toFixed(3) + 'º E' : (-1 * longitude).toFixed(3) + 'º W';
        return latStr + ', ' + lngStr;
    }
};

export const dateStringToISO = dateString => {
    let date = new Date(dateString);

    // check if it's a real date before trying to convert it
    if (isNaN(date.getTime())) {
        return;
    }
    // not using .toISOString because that converts time zone to UTC
    const isoString = `${date.getFullYear().toString()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return isoString;
};

export const getFormattedAddress = (googleResult) => {
    // Google's geocoding API doesn't return things in a uniform way, so dynamically pick out what you need
    const formattedAddress = {
        country: null,
        subdivision: null, // lv1 for now- state, province etc
        city: null, // lv3
        county: null, // lv2
        townland: null, // lv4
        township: null, //locality
        municipal: null, //lv5? IDK
        address: null,
        concession: null, // sublocality
    };
    // outer section- each of these is a separate response with a different level of detail
    for (let i = 0; i < googleResult.length; i++) {
        const resultSection = googleResult[i];
        if (resultSection.types.includes('street_address')) {
            formattedAddress.address = resultSection.formatted_address;
        }
        // each section contains more options
        for (let j = 0; j < resultSection.address_components.length; j++) {
            const section = resultSection.address_components[j];
            if (section.types.includes('neighborhood')) {
                // basically a community name I guess?
                formattedAddress.townland = section.long_name;
            }
            if (section.types.includes('sublocality')) {
                // how the county is divided
                formattedAddress.concession = section.long_name
            }
            if (section.types.includes('administrative_area_level_5')) {
                // minor civil division
                formattedAddress.municipal = section.long_name;
            }
            if (section.types.includes('administrative_area_level_4')) {
                // minor civil division
                formattedAddress.township = section.long_name;
            }
            if (section.types.includes('administrative_area_level_3')) {
                // minor civil division
                formattedAddress.city = section.long_name;
            }
            if (section.types.includes('administrative_area_level_2')) {
                //counties
                formattedAddress.county = section.long_name;
            }
            if (section.types.includes('administrative_area_level_1')) {
                //states
                formattedAddress.subdivision = section.long_name;
            }
            if (section.types.includes('country')) {
                formattedAddress.country = section.long_name;
            }
        }
    }
    return formattedAddress;
};

export const validate = {
    email: emailString => {
        // eslint-disable-next-line no-useless-escape
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        return regex.test(String(emailString).toLowerCase());
    }
};

export const sendGoogleAnalyticsEvent = (vueInstance, action, category, label, value) => {
    // note that value has to be an integer
    vueInstance.$gtag.event(action, {'event_category': category, 'event_label': label, 'value': value});
};

export const sendGoogleAdsConversion = (vueInstance) => {
    vueInstance.$gtag.event(
        'conversion',
        {
            'send_to': `${frontendConfig.GOOGLE_ADS_CONVERSION_TRACKING_TAG}/vB60CNvloZ8ZENvFhbQ9`,
            'value': 1.0,
            'currency': 'USD'
        }
    );
};

export const getUserPermissions = () => {
    const userStr = sessionStorage.getItem(USER_KEY);
    const user = JSON.parse(userStr);
    return user.permissions || {};
};

export const cleanupUrl = (url) => {
    // if url doesn't start with http, add it
    if (url && !url.startsWith('http')) {
        return `http://${url}`;
    }
    return url;
};