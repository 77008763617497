<template>
<div class="detail-selector-container">
  <h2>Add more details to your entry</h2>
  <div class="selection-details">
    Pick <b>at least one</b> of the following options to include as information on your ancestral home.
    <InfoIcon :tooltip-text="infoText"/>
  </div>
  <div class="options-checkboxes">
    <div v-for="(option, key, index) in optionsList" :key="index" class="checkbox-row">
      <input
        type="checkbox"
        id="optionChoice"
        class="form-check-input"
        v-model="checkboxVals[key]"
        @click="checkboxClicked(key, $event)"
      >
      <label for="optionChoice" class="form-check-label">{{option.label}}</label>
      <InfoIcon :tooltip-text="option.info"/>
    </div>
  </div>
</div>
</template>

<script>
import InfoIcon from "@/components/InfoIcon.vue";
import {addHomeDetails, progressStatus} from "@/helpers/utils";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SelectDetails",
  components: { InfoIcon },
  data(){
    return {
      infoText: 'In addition to location information, we require a minimum of one extra detail in order to ' +
          'keep entries as useful as possible to other users.<br>' +
          'Don’t have any details at the moment? Save your information as a draft and come back later.',
      optionsList: addHomeDetails,
      checkboxVals: {
          occupants: false,
          gad: false,
          census: false,
          structure: false,
          events: false,
          photos: false,
          references: false,
      },
      defaultNodes: ['location', 'selectDetails', 'review', 'submit'],
    }
  },
  mounted() {
    Object.entries(this.getHomeFormSections).forEach(([key, value]) => {
      if(!this.defaultNodes.includes(key) && value){
        this.checkboxVals[key] = true;
      }
    });
    if(this.numBoxesClicked < 1) {
      this.emitInvalidMessage();
    }
  },
  computed: {
    ...mapGetters(['getHomeFormSections']),
    numBoxesClicked(){
      return Object.values(this.getHomeFormSections).filter(item => item !== null).length - this.defaultNodes.length;
    }
  },
  methods: {
    ...mapActions(['toggleAddFormExtra', 'updateFormSection']),
    emitInvalidMessage(){
      this.$emit('disableNext', 'Please select at least one option.');
    },
    checkboxClicked(key, event){
      const content = this.getHomeFormSections[key]?.data;
      if(content && content.length) {
        // stop checkbox from unchecking until we confirm
        event.preventDefault();
        this.$emit(
            'showModal',
            {
              title: 'Are you sure?',
              text: `You have already filled out parts of the ${key} section. Are you sure you want to remove it? ` +
                  'Once confirmed, all of the details you have added for this section will be deleted, ' +
                  'and cannot be retrieved.',
              action: this.confirmRemoveSection,
              args: key,
            });
      }
      else {
        this.toggleAddFormExtra(key);
      }
      if(this.numBoxesClicked < 1) {
        this.updateFormSection({key: 'selectDetails', data: {status: progressStatus.ERROR}});
        this.emitInvalidMessage();
      }
      else {
        // requirements have been met. Get rid of any error messages.
        this.$emit('disableNext', null);
        this.updateFormSection({key: 'selectDetails', data: {status: progressStatus.DONE}});
      }
    },
    confirmRemoveSection(key){
      this.toggleAddFormExtra(key);
      this.checkboxVals[key] = false;
    },
  }
}
</script>

<style scoped>
.checkbox-row {
  margin: 1rem;
}
.options-checkboxes {
  padding: 1.5rem;
}
.options-checkboxes label {
  font-size: large;
  pointer-events: none;
}
input[type=checkbox] {
  transform: scale(1.5);
  vertical-align: middle;
  position: relative;
  bottom: 4px;
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .checkbox-row {
    margin: 1rem 0;
  }
  .options-checkboxes label {
    font-size: medium;
  }
}
</style>