<template>
  <div>
    <h2>Photos</h2>
    <div class="selection-details">
      Upload photos of the home, either contemporary or historical.
    </div>
    <div class="row">
      <div v-if="photos.length > 0" class="col-12 uploaded-photos">
        <PhotoSquare
            v-for="(photo, index) in photos"
            :photo-name="photo"
            :key="index"
            @deleteClicked="removeUploadedPhoto(photo)"
        />
      </div>
      <div class="col-12">
        <p v-if="deleteFailed" class="warning-text">Failed to delete the selected image.</p>
      </div>
    </div>
    <vue-dropzone
        id="homePhotoDropzone"
        ref="dropzone"
        :options="dropOptions"
        @vdropzone-success="handleResponse"
        @vdropzone-queue-complete="saveSection"
    />
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <button class="btn btn-secondary remove-button" @click="removeAll">Clear Upload Section</button>
      </div>
      <div class="col-lg-4"/>
      <div class="upload-container col-lg-4 col-sm-12">
        <button
          class="btn btn-primary save-button"
          @click="saveAll"
        >
          Upload all Photos
        </button>
        <p v-if="submitError" class="warning-text">You must be logged in to upload photos.</p>
      </div>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import {IMG_API_URL, submitStatus, progressStatus} from '@/helpers/utils';
import PhotoSquare from "./PhotoSquare";
import {ROUTENAMES} from "@/helpers/constants";

export default {
  name: "PhotoForm",
  components: { PhotoSquare, vueDropzone },
  data() {
    return {
      dropOptions: {
        url: IMG_API_URL,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        // stops automatic upload (until save button pressed)
        autoProcessQueue: false,
        resizeWidth: 2560,
      },
      photos: [],
      submitError: false,
      photoToDelete: null,
      deleteFailed: false,
    }
  },
  props: {
    triggerSaveDraft: { type: Boolean, default: false },
  },
  mounted() {
    const photoData = this.getHomeFormSections.photos?.data;
    if (photoData && photoData.length > 0) {
      this.photos = photoData;
    } else {
      this.$emit('disableNext', 'Please upload at least one photo.');
    }
  },
  beforeDestroy() {
    this.savePageContents();
  },
  watch: {
    triggerSaveDraft(to){
      if(to){
        this.savePageContents();
      }
    },
    wholeFormEmpty(to) {
      if (to) {
        this.$emit('disableNext', 'Please upload at least one photo.');
        this.updateFormSection({ key: 'photos', data: { status: null } });
      } else {
        this.$emit('disableNext', null);
        this.updateFormSection({ key: 'photos', data: { status: progressStatus.DONE } });
      }
    },
    getPhotoDeleteStatus(to) {
      let indexToRemove;
      switch (to) {
        case submitStatus.LOADING:
          // TODO: disable delete button and show loading spinner instead
          console.log('loading');
          break;
        case submitStatus.SUCCESS:
          // delete photo from home record too
          if (this.photoToDelete) {
            indexToRemove = this.photos.indexOf(this.photoToDelete);
            if (indexToRemove > -1) {
              this.photos.splice(indexToRemove, 1);
            }
          }
          this.photoToDelete = null;
          break;
        case submitStatus.FAILED:
          // show indicator that delete failed
          console.log('failed to delete photo.');
          this.deleteFailed = true;
          break;
        default:
          console.log('Unrecognized photo deletion state.');
      }
    },
    deleteFailed(to) {
      if (to) {
        setTimeout(() => {
          this.deleteFailed = false;
        }, 4000);
      }
    },
  },
  computed: {
    ...mapGetters(['getPhotoDeleteStatus', 'getHomeFormSections']),
    wholeFormEmpty() {
      return this.photos.length < 1;
    }
  },
  methods: {
    ...mapActions(['deletePhoto', 'updateFormSection']),
    savePageContents(){
      if(this.$route.name === ROUTENAMES.ADD_HOME) {
        this.saveSection();
        this.$emit('disableNext', null);
      }
    },
    removeAll() {
      this.$refs.dropzone.removeAllFiles();
      this.photos = [];
      this.saveSection();
    },
    saveAll() {
      this.$refs.dropzone.processQueue();
    },
    handleResponse(file, response) {
      this.photos.push(response.key);
    },
    removeUploadedPhoto(photoName) {
      this.photoToDelete = photoName;
      // remove photo from s3
      this.deletePhoto(photoName);
    },
    saveSection(){
      this.updateFormSection({key: 'photos', data: {data: this.photos}});
    }
  }
}
</script>

<style scoped>
.btn {
  width: 12rem;
}

.remove-button {
  margin: 1rem 0;
}

.save-button {
  float: right;
  margin: 1rem 0 0 auto;
}

.upload-container {
  display: flex;
  flex-direction: column;
}

.warning-text {
  color: #d9534f;
  font-size: x-small;
  margin: 0 0 0 auto;
}

.uploaded-photos {
  display: flex;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .btn {
    width: 100%;
    margin: 1rem 0 0 0;
  }

  .uploaded-photos {
    overflow-x: scroll;
  }

  .uploaded-photos:after {
    content: '';
    width: 0.15rem;
    flex-shrink: 0;
  }
}
</style>