<template>
  <transition name="modal-fade">
      <div class="modal-backdrop" @click.self="close">
        <div class="modal-main page-bg">
          <header class="modal-header">
              <slot name="header">Confirmation</slot>
              <button type="button" class="btn-close" @click="close"><font-awesome-icon icon="times"/></button>
          </header>

          <section class="modal-body">
              <slot name="body">
                  <p>Are you sure?</p>
                  <p>Once complete, this action cannot be reversed.</p>
              </slot>
          </section>

          <footer class="modal-footer">
              <slot name="footer">
                  <div class="button-section row d-flex justify-content-end">
                      <button type="button" class="btn btn-secondary" @click="close">{{secondaryAction}}</button>
                      <button type="button" class="btn btn-primary" @click="confirm">{{primaryAction}}</button>
                  </div>
              </slot>
          </footer>
        </div>
      </div>
  </transition>
</template>

<script>
export default {
    name: "Modal",
    props: {
        primaryAction: { type: String, default: 'Confirm' },
        secondaryAction: { type: String, default: 'Cancel' },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      confirm() {
          this.$emit('confirm');
      }
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-main {
  max-width: 70vw;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
    padding: 1rem;
    display: flex;
    flex-direction: row;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
}

.modal-body {
    position: relative;
    padding: 2rem;
    text-align: center;
}
.modal-body p {
    margin: 0;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
    color: #0c5460;
}
.button-section {
    width: 100%;
}
.btn-secondary {
    margin-right: 1rem;
}
.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

@media screen and (max-width: 600px) {
  .modal-main {
    max-width: 96%;
  }

  .modal-body {
    padding: 0.5rem;
  }
}
</style>