<template>
    <div class="photo-square">
        <img class="uploaded-photo" :src="imgSrc"/>
        <div class="img-overlay">
            <font-awesome-icon class="delete-icon" icon="trash" @click="deleteClicked"/>
        </div>
    </div>
</template>

<script>
import { getPlaceholderUrl, getS3Url } from '../../helpers/utils'

export default {
    name: "PhotoSquare",
    props: {
        photoName: { type: String, default: null }
    },
    computed: {
        imgSrc() {
            const imgToLoad = this.photoName ? getS3Url(this.photoName) : getPlaceholderUrl();
            return imgToLoad;
        },
    },
    methods: {
        deleteClicked(){
            this.$emit('deleteClicked', this.photoName);
        }
    }
}
</script>

<style scoped>
.uploaded-photo {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: .2rem;
    border: 1px solid #006060;
}
.photo-square{
    position: relative;
    margin-right: 1rem;
}
.delete-icon {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: xx-large;
    color: white;
}
.img-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    border-radius: .2rem;
}
.img-overlay:hover {
    background-color: rgba(0, 89, 107, 0.62);
    transition: opacity 0.75s;
    opacity: 1;
}
</style>