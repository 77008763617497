<template>
  <div class="jumbotron opening">
    <h1 v-if="title" class="display-4 title-font">{{title}}</h1>
    <hr class="my-4">
    <p class="button-container lead row">
      <Button v-for="(navButton, index) in buttons"
        :key="index"
        class="btn btn-primary btn-lg title-font-light"
        @click="clickedNavButton(navButton.label)"
      >
        <font-awesome-icon :icon="navButton.icon" class="icon"/>
        <a :href="`#${navButton.refName}`" role="button">{{navButton.label}}</a>
      </Button>
    </p>
  </div>
</template>

<script>
import {sendGoogleAnalyticsEvent} from "@/helpers/utils";

export default {
  name: "JumbotronNav",
  props: {
    title: { type: String, required: false },
    buttons: { type: Array, required: true },
  },
  methods: {
    clickedNavButton(buttonName) {
      const pageName = this.$route.name;
      sendGoogleAnalyticsEvent(this, `Clicked Nav Button (${pageName} page)`, 'generate_lead', buttonName);
    },
  }
}
</script>

<style scoped>
.jumbotron {
  background: none;
}
.button-container {
  padding-left: 0.5rem;
}

.btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.btn a {
  color: #FFFFFF;
  /*Makes whole button clickable instead of just link text*/
  display: inline-block;
  position: relative;
  max-height: 4rem;
  padding: 0.5rem 5vw;
  margin: -0.5rem -5vw;
  z-index: 1;
}

.icon {
  margin-right: 1rem;
}

@media screen and (max-width: 900px) {
  .btn {
    position: relative;
    width: 100%;
  }
}
</style>