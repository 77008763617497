<template>
<div>
  <h2>Location</h2>
  <div class="selection-details">
    <p>
      Provide an address or map reference that shows where the home is, or was, located. If you are not sure exactly
      where the home is located, an approximate location, such as a city or town, is acceptable.
      <br>
      This is the only part of the 'Add a Home' form that is mandatory.
    </p>
  </div>
  <div class="main-text-content">
    <div class="input-group input-group-lg">
      <input
        v-model="searchAddress"
        type="text"
        class="form-control"
        placeholder="Type an address here"
        aria-label="Address"
      >
      <div class="input-group-append">
        <button class="btn btn-primary rounded-pill" type="button" @click="searchByAddress(searchAddress)">
          <div v-if="isSearching" class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <font-awesome-icon v-else icon="search"/>
        </button>
      </div>
    </div>
    <p>Don't have an address?</p>
    <div class="alt-option-buttons">
      <button class="btn btn-primary btn-lg" @click="emitButtonClicked('map')">Choose Location on Map</button>
      <button class="btn btn-primary btn-lg" @click="emitButtonClicked('manual')">Enter Location Coordinates</button>
    </div>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {submitStatus} from "@/helpers/utils";

export default {
  name: "SearchLocationForm",
  data(){
    return {
      searchAddress: null,
    }
  },
  watch: {
    getAddressSearchStatus(to){
      if(to === submitStatus.SUCCESS) {
        this.emitButtonClicked('textSearch');
      }
    }
  },
  computed: {
    ...mapGetters(['getAddressSearchStatus']),
    isSearching(){
      return this.getAddressSearchStatus === submitStatus.LOADING;
    }
  },
  methods: {
    ...mapActions(['toggleAddFormExtra', 'searchByAddress']),
    emitButtonClicked(buttonName){
      this.$emit('buttonClicked', buttonName);
    }
  }
}
</script>

<style scoped>
.input-group {
  margin-top: 4rem;
  margin-bottom: 2rem;
  max-width: 50%;
  padding: 0.4rem;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 30px;
}
.main-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-text-content input {
  border: none;
  margin-right: 0.5rem;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.main-text-content input:focus {
  border-color: white;
  box-shadow: none;
}
.alt-option-buttons .btn{
  margin: 0.5rem;
}

@media screen and (max-width: 600px) {
  .input-group {
    margin-top: 2rem;
    max-width: 100%;
  }

  .alt-option-buttons {
    display: flex;
    flex-direction: column;
  }

  .alt-option-buttons .btn {
    margin-bottom: 1rem;
  }
}
</style>