<template>
    <div class="bulk-upload-container page-bg">
        <h2>Bulk upload homes</h2>
        <div class="info-text">
            <p>
                This section of the platform can be used to mass upload many homes at a time from a spreadsheet. Once the
                upload process is complete, you will be able to view and edit the homes you've created via the
                <router-link to="/profile/your-homes">Your Homes</router-link> tab. These new entries are hidden from
                the Ancestral Homes Network search results by default, and we encourage you to take the time to review
                them before making the homes visible to the public.
            </p>
            <p>
                In addition to the contents of the spreadsheet rows, any additional location details we can find will be
                added to each home automatically based on its coordinates.
            </p>
            <p>
                Currently, the Ancestral Homes Network supports CSV spreadsheets with the following headings:
            </p>
            <div class="sample-tables">
                <table class="sample-headings light-teal-bg">
                    <tr>
                        <td>Site Name</td>
                        <td>County</td>
                        <td>Grid-Ref</td>
                        <td>east_ING</td>
                        <td>north_ING</td>
                        <td>EAST_ITM</td>
                        <td>NORTH_ITM</td>
                    </tr>
                </table>
            </div>

            <p>
                If you or your organization use a different format for your CSVs, you can request that a custom format
                be added by <a class="text-link">clicking here</a>. A one-time fee of $7.99 will be
                required to complete the request.
            </p>
            <br>
            <p>
               The uploaded file must be in CSV format.
               For larger files, we recommend uploading homes in batches of 50 or fewer, but this is not mandatory.
               If no starting or ending row numbers are entered, all rows of the spreadsheet will be uploaded.
            </p>
        </div>
        <div class="upload-section col-12 light-teal-bg">
            <div class="choose-file">
                <div class="row">
                    <ValidationInput
                        class="col-6"
                        label="Starting Row Number"
                        type="number"
                        v-model="startRow"
                        validation-type="positive"
                        should-validate
                        @validated="setStartValid"
                    />
                    <ValidationInput
                        class="col-6"
                        label="Ending Row Number"
                        type="number"
                        v-model="endRow"
                        validation-type="range"
                        :range-min="parseInt(startRow || 0)"
                        :should-validate="endRowSpecified"
                        @validated="setEndValid"
                    />
                </div>
                <div class="row">
                    <input
                        class="col-12"
                        type="file"
                        @change="handleUpload($event.target.files)"
                        accept="text/csv"
                        :disabled="selectedRangeInvalid"
                    />
                    <p v-if="csvErrorMessage" class="warning-text col-12">{{csvErrorMessage}}</p>
                </div>
            </div>

            <div class="preview-container" v-if="resultsToShow.length > 0">
                <h5>The following {{resultsToShow.length}} homes will be uploaded:</h5>
                <div class="results-preview">
                    <table class="preview-table">
                        <tr v-for="(row, index) in resultsToShow" :key="index">
                            <td v-for="(rowSection, sectionIndex) in row" :key="sectionIndex">{{rowSection}}</td>
                            <td :class="['regular-icon', statusIconColour(index)]">
                                <font-awesome-icon v-if="pickStatusIcon(index)" :icon="pickStatusIcon(index)"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <button
                    :class="[{'disabled': rowsEmpty || isUploading}, 'btn', 'btn-primary', 'btn-lg']"
                    @click="generateHomesToUpload"
                >
                    {{uploadButtonText}}
                </button>
                <p v-if="errorUploading" class="warning-text">{{errorText}}</p>
                <p v-if="uploadSuccessful" class="success-text">Uploaded successfully!</p>
            </div>
        </div>
    </div>
</template>

<script>
import Papa from 'papaparse';
import { gridToLatLong, COORDS_GPS_IRISH } from '../../helpers/gridRefUtilities';
import { getFormattedAddress, submitStatus, getUserPermissions } from '../../helpers/utils';
import { api } from '../../helpers/api';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import ValidationInput from '../ValidationInput';

export default {
    name: "BulkUpload",
    components: { ValidationInput },
    data() {
        return {
            csvLines: [],
            startRow: 1,
            endRow: 50,
            allResultsUploaded: false,
            errorUploading: false,
            homesList: [],
            uploadSuccessful: false,
            errorText: null,
            successfulRows: [],
            failedRows: [],
            csvErrorMessage: null,
            startIsValid: true,
            endIsValid: true,
        }
    },
    beforeMount() {
        const permissions = getUserPermissions();
        if(permissions === {} || !permissions.can_access_paid){
            this.$router.push({ name: 'noAccess' });
        }
    },
    mounted() {
        this.$gtag.pageview('User Profile - Bulk Upload');
    },
    watch: {
        allResultsUploaded(to){
            if(to){
                if(this.failedRows.length > 0){
                    this.errorUploading = true;
                    this.errorText = `Upload failed. Only ${this.homesList.length}/${this.resultsToShow.length} spreadsheet
                                        rows could be translated into home records.`;
                }
                else {
                    if (this.homesList.length > 0){
                        this.bulkSubmitHomes(this.homesList);
                    }
                }
            }
        },
        homeSubmitStatus(to){
          this.uploadSuccessful = false;
          if(to === submitStatus.SUCCESS){
              this.uploadSuccessful = true;
              this.clearFields();
          }
          else if(to === submitStatus.FAILED){
              this.errorUploading = true;
              this.errorText = 'Upload failed.'
          }
        },
        uploadSuccessful(to){
            if (to){
              // remove success message after a few seconds
              setTimeout(() => {
                  this.uploadSuccessful = false;
              }, 5000);
          }
        },
        errorUploading(to){
            if (to){
              setTimeout(() => {
                  this.errorUploading = false;
                  this.errorText = null;
              }, 7000);
          }
        },
        csvErrorMessage(to){
            if (to){
              setTimeout(() => {
                  this.csvErrorMessage = null;
              }, 7000);
          }
        },
        startRow(){
            this.failedRows = [];
            this.successfulRows = [];
        },
        endRow(){
            this.failedRows = [];
            this.successfulRows = [];
        }
    },
    computed: {
        ...mapGetters(['getLoggedInUser', 'homeSubmitStatus']),
        resultsToShow(){
            const {startRow, endRow} = this;
            const startIndex = startRow && startRow > 0 ? startRow - 1 : 0;
            const endIndex = endRow || this.csvLines.length - 1;

            return this.csvLines.slice(startIndex, endIndex);
        },
        rowsEmpty(){
            return this.resultsToShow.length === 0;
        },
        isUploading(){
            return this.homeSubmitStatus === submitStatus.LOADING;
        },
        uploadButtonText(){
            return this.isUploading ? 'Uploading...' : 'Upload Homes';
        },
        selectedRangeInvalid(){
            return !(this.startIsValid && this.endIsValid);
        },
        endRowSpecified(){
            return this.endRow !== "";
        }
    },
    methods: {
        ...mapActions(['autofillLocation', 'bulkSubmitHomes', 'resetHomeSubmitStatus']),
        readFileAsync(file){
            return new Promise((resolve, reject)=> {
                let reader = new FileReader();

                reader.onload = () => {
                  resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsText(file);
            });
        },
        async handleUpload(file){
            this.successfulRows = [];
            this.failedRows = [];
            try {
                let result = await this.readFileAsync(file[0]);
                const parsedCSV = Papa.parse(result);
                this.csvLines = parsedCSV.data;
                // remove headers
                this.csvLines.splice(0, 1);
            }
            catch (e) {
                console.log(e);
                this.csvErrorMessage = "An error occurred. Could not read the provided spreadsheet data."
            }
        },
        generateHomesToUpload(){
            let progress = 0;
            this.homesList = [];
            this.successfulRows = [];
            this.failedRows = [];
            this.resetHomeSubmitStatus();

            if(!this.rowsEmpty) {
                const userId = this.getLoggedInUser.id;
                this.resultsToShow.forEach(async (line, index) => {
                    // CSV format is [name, county, grid ref, easting, northing, east_itm, north_itm]
                    const name = line[0];
                    const county = line[1];
                    const irishGridNums = [line[3], line[4]];
                    if (irishGridNums.includes("")){
                        // incomplete coordinates, can't finish entry
                        this.failedRows.push(index);
                    }
                    else {
                        const latLong = gridToLatLong(irishGridNums, COORDS_GPS_IRISH);
                        const lat = latLong[0];
                        const lng = latLong[1];
                        const originalGridRef = `Grid reference: ${line[2]},
                                             Irish Grid Coordinates: Easting: ${line[3]}, Northing: ${line[4]},
                                             Irish Transverse Mercator (ITM) Coordinates: Easting: ${line[5]}, Northing: ${line[6]}`;

                        // Use GAD autofill methods to fill in any other details
                        const locationDetails = await api.autofillLocation({lat, lng});
                        const address = getFormattedAddress(locationDetails.data.results);
                        const formData = {
                            owner_id: userId,
                            gad: {county, gadDescription: originalGridRef},
                            censuses: [],
                            structure: {
                                year: null,
                                roofDesc: null, floorDesc: null, wallDesc: null,
                                numDoors: null, numWindows: null, numRooms: null,
                                structName: name
                            },
                            events: [],
                            occupants: [],
                            latitude: lat,
                            longitude: lng,
                            country: address.country,
                            references: [],
                            isHidden: true,
                        };

                        if (Object.keys(address).length > 1) {
                            // remove country and county since we have those already
                            delete address.country;

                            if (formData.gad.county != null) {
                                delete address.county;
                            }

                            formData.gad = {...formData.gad, ...address}
                        }
                        this.homesList.push(formData);
                        this.successfulRows.push(index);
                    }

                    progress += 1;
                    this.allResultsUploaded = progress === this.resultsToShow.length;
                });
            }
        },
        clearFields(){
            this.startRow = 1;
            this.endRow = 50;
            this.errorUploading = false;
            this.successfulRows = [];
            this.failedRows = [];
        },
        pickStatusIcon(index){
            if(this.successfulRows.includes(index)) {
                return 'check'
            }
            else if(this.failedRows.includes(index)) {
                return 'times'
            }
            return null;
        },
        statusIconColour(index){
            if(this.successfulRows.includes(index)) {
                return 'success-text'
            }
            else if(this.failedRows.includes(index)) {
                return 'warning-text'
            }
            return null;
        },
        setStartValid(result){
            this.startIsValid = result.valid;
        },
        setEndValid(result){
            this.endIsValid = result.valid;
        }
    }
}
</script>

<style scoped>
.bulk-upload-container {
    padding: 1rem;
}
.info-text {
    margin-top: 1.5rem;
}
.sample-tables {
    max-width: 100%;
    overflow-x: scroll;
    margin-bottom: 1rem;
}
.sample-headings td {
    border: black 1px solid;
    padding: 0 1rem;
}
.row {
    margin-bottom: 0.5rem;
}
.preview-container {
    margin: 1rem 0;
}
.results-preview {
    max-height: 12rem;
    overflow: scroll;
    margin-bottom: 1rem;
    border: 1px black solid;
    background-color: white;
}
.preview-table {
    width: 100%;
}
.regular-icon {
    font-size: medium;
}
.page-bg {
    margin-top: 0.5rem;
}
.upload-section {
    padding: 1rem;
}
.warning-text {
    margin-top: 0.5rem;
}
.page-bg {
  height: 95%;
}
</style>