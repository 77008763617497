<template>
  <div class="about-container" id="top">
    <button v-if="scrollPosition > 400" class="btn btn-secondary btn-floating">
      <a class="back-to-top-text" href="#top">Back to Top</a>
    </button>
    <JumbotronNav :title="title" :buttons="navButtons" class="nav-section"/>

    <div class="about">
      <div class="row main-row">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-7 left-text">
              <p class="lead">
                The AncestralHomes Network's website, AncestralHomes.net, offers a secure, free platform for individuals to
                record and share the history of their family homes, making it a valuable resource for anyone interested in
                family history and ancestral research. Users can easily document their ancestral homes, providing details
                about the architecture, historical significance, and personal stories associated with these homes. This
                platform not only serves genealogists and historians but also connects family members across generations
                by preserving their shared heritage.
              </p>
              <p class="lead">
                AncestralHomes.net features a user-friendly interface where you can create detailed profiles for each
                ancestral home, upload photos, and attach historical documents. The site also allows users to search for
                their family history using last names, making it easier to uncover information about ancestors' living
                places that may not be readily available in traditional genealogical records.
              </p>
              <p class="lead">
                By contributing to AncestralHomes.net, users help build a comprehensive international archive that captures
                the essence of ancestral homes from around the world. This collective effort ensures that the memories and
                histories of these homes are preserved for future generations. Whether you are tracing your family tree,
                exploring your ancestors' history, or simply passionate about preserving historical homes,
                AncestralHomes.net provides the tools and community support to enrich your genealogical journey.
              </p>
            </div>
            <div class="col-5"/>
          </div>
          <img class="bg-img-right" src="../assets/alice_b_w.png"/>
        </div>
      </div>

      <div class="row light-teal-bg main-row" id="vision">
        <div class="col-12">
          <div class="row">
            <div class="col-5"/>
            <div class="col-lg-7 right-text">
              <h2 class="featurette-heading title-font">Vision & Mission</h2>
              <p class="lead">The records of Ancestral Homes constitute a “missing link” in existing genealogical
                records. The vision is to support the world-wide community of genealogists and family historians by:</p>
              <ul>
                <li class="lead">Providing a technological tool for collecting, storing, retrieving, and sharing
                  information about Ancestral Homes and the families that once occupied them.</li>
                <li class="lead">Creating the International Archive of Ancestral Homes (IAAH), starting with Canada
                  and Ireland, and eventually embracing the Irish diaspora, and others, in all countries of the world.</li>
                <li class="lead">Creating sustainable ongoing educational programs to engage young people in researching
                  the cultural heritage of their communities.</li>
              </ul>
              <p class="lead">For AHN purposes, an ancestral home is a human habitation, or its remains, in any time period anywhere in the world. </p>
            </div>
          </div>
        </div>
        <img class="bg-img-left" src="../assets/ireland_house_left.png"/>
      </div>

      <div class="row main-row" id="objectives">
        <div class="col-12 centre-text">
          <h2 class="featurette-heading title-font">Our Objectives</h2>
          <p class="lead">The goal, over time, is to build the International Archive of Ancestral Homes (IAAH)
            by adding one ancestral home at a time to the archive by the volunteer contributions of many
            motivated individuals, genealogical and historical societies, heritage programs, educational institutions,
            and other community-based partners.</p>
        </div>
        <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
      </div>

      <div class="row main-row light-teal-bg" id="history">
        <div class="col-5"/>
        <div class="col-lg-7 right-text">
          <h2 class="featurette-heading title-font">A Brief History</h2>
          <p class="lead">AncestralHomes Network (AHN) was registered as a Canadian not-for-profit corporation
            in December 2020. The concept was developed by the founder, Philip Donnelly, while he was researching
            and writing his memoirs which were published as a book entitled 'The Eyes That Shone - From Ireland
            to Canada in the 1950s' and launched at the Embassy of Ireland in Ottawa, Canada, in 2010.
            These memoirs cover Philip’s youth growing up in Counties Cavan and Meath in Ireland from the 1930s to 1950s,
            and his later years as a civil engineer and father of four in Canada.</p>
          <p class="lead">
            During his research, Philip realised that it is easier to find, in genealogical records, the burial places
            of one’s ancestors than it is to find the place where those ancestors lived. The need to create the
            AncestralHomes Network was recognized.
          </p>
          <p class="lead">
            Given that AHN’s roots were in Ireland and Canada, the founding motivation of the Network was to
            forge bonds across the global Irish diaspora by recording the memories, both genealogical and
            historical, of their ancestral homes.
          </p>
          <p class="lead">
            Philip Donnelly worked with the following collaborators between 2015 and 2020 to develop the initial
            AHN website and app technology:
          </p>
          <ul>
            <li class="lead"><a href="http://donnellycanada.com/" target="_blank">DonnellyCanada (Philip Donnelly)</a></li>
            <li class="lead"><a href="http://www.irishsocietyncr.com/" target="_blank">The Irish Society of the National Capital Region</a></li>
            <li class="lead"><a href="https://ottawa.ogs.on.ca/" target="_blank">The Ontario Genealogical Society, Ottawa Branch</a></li>
            <li class="lead"><a href="https://www.algonquincollege.com/sat/program/computer-engineering-technology-computing-science/" target="_blank">Algonquin College Computing Science</a></li>
          </ul>
        </div>
        <img class="bg-img-left" src="../assets/ottawa_house_left.png"/>
      </div>

      <div class="team-group" id="team">
        <div class="row team-section">
          <div v-for="(bio, index) in teamBios" :key=index class="col-lg-4 team-card">
            <img class="rounded-circle" :src="getImgUrl(bio.photo)" alt="Generic placeholder image" width="140" height="140">
            <h2>{{bio.name}}</h2>
            <h5>{{bio.title}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JumbotronNav from "@/components/JumbotronNav.vue";

export default {
  name: "About",
  components: {JumbotronNav},
  data() {
    return {
      navButtons: [
        {refName: 'vision', label: 'Vision and Mission', icon: 'eye'},
        {refName: 'objectives', label: 'Our Objectives', icon: 'star'},
        {refName: 'history', label: 'A Brief History', icon: 'book'},
        {refName: 'team', label: 'Meet the Team', icon: 'users'},
      ],
      teamBios: [
        { name: 'Phil Donnelly', title: 'Founder', photo: 'phil'},
        { name: 'Nancy Donnelly', title: 'Director, President, CEO', photo: 'nancy'},
        { name: 'Marlee Donnelly', title: 'Vice-President, Technology Development', photo: 'marlee'},
        { name: 'Thomas Donnelly', title: 'Director, Treasurer', photo: 'thom'},
        { name: 'Adam Pringle', title: 'Director', photo: 'adam'},
        { name: 'Joel Villeneuve', title: 'Director, Secretary', photo: 'app_icon_512'},
      ],
      scrollPosition: 0,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted(){
    this.$gtag.pageview('About');
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    isMobile(){
      return window.innerWidth <= 600;
    },
    title(){
      return this.isMobile ? 'About Ancestral Homes Network' : 'About AncestralHomes Network';
    }
  },
  methods: {
    getImgUrl(imgName) {
      var images = require.context('../assets/', false, /\.png$/);
      return images('./'+imgName+'.png');
    },
    handleScroll() {
      // Check whether we're at the top of the page,in which case don't show 'scroll to top' button
      this.scrollPosition = window.scrollY;
    }
  }
}
</script>

<style scoped>
.about {
  padding: 0;
}
.nav-section {
  margin-bottom: 0;
}
.featurette-heading {
  margin-top: 4rem;
  font-size: 50px;
}
.rounded-circle {
  border: 1px solid lightgrey;
}
.btn-floating {
  position: fixed;
  z-index: 2;
  bottom: 2rem;
  right: 2rem;
}
.back-to-top-text {
  color: white;
}
.back-to-top-text:hover{
  text-decoration: none;
}

/* Scrollable team section */
.team-group > .row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
}
.team-group > .row > .team-card {
  flex: 0 0 auto;
  margin: 3rem 0.5rem;
}
.team-section {
  margin: 2rem 0;
  text-align: center;
}
.team-section h2 {
  font-weight: 400;
  margin-top: 0.75rem;
}
.team-section h5 {
  font-weight: 400;
  margin-top: 0;
}
.team-section .col-lg-4 p {
  margin: 0.75rem;
  padding: 2rem 0;
  font-size: small;
}

/* backgrounds */
/*Use decimals, not percentages, for opacity since %s don't work w/ current hosting setup*/
.main-row {
  position: relative;
  min-height: 60vh;
}
.left-text {
  padding-left: 3rem;
  padding-bottom: 1rem;
  z-index: 1;
}
.right-text {
  padding-right: 3rem;
  padding-bottom: 1rem;
  z-index: 1;
}
.centre-text {
  padding: 1rem 3rem;
  z-index: 1;
}
.bg-img-right {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  opacity: 0.40;
  max-width: 100%;
}

.bg-img-left {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0.40;
  max-width: 100%;
}
.bg-img-bottom {
  max-width: 100%;
}

@media screen and (max-width: 900px) {
  .bg-img-left, .bg-img-right, .bg-img-bottom {
    display: none;
  }
  .left-text, .right-text, .centre-text {
    padding: 1rem 2rem;
  }
}
</style>