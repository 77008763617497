<template>
  <div class="contribute-container">
    <button v-if="scrollPosition > 400" class="btn btn-secondary btn-floating">
      <a class="back-to-top-text" href="#top">Back to Top</a>
    </button>
    <Alert :title="'We\'re recruiting!'" :alert-type="'warning'">
      <p>
        Want to have a say in what Ancestral Homes does next?
        The Ancestral Homes Network is currently looking for a knowledgeable, enthusiastic individual to
        join us as a volunteer Board member as we move forward in the development of our registered not-for-profit
        corporation.
        Avid genealogical researchers and those with experience working with not-for-profit organizations are
        especially encouraged to apply.
      </p>
      <br>
      <p>If this sounds like you, <a class="alert-link" href="mailto:info@ancestralhomes.net"
                                     @click="clickedRecruiting">click here</a> to contact us.</p>
    </Alert>
    <JumbotronNav title="How Can I Help?" :buttons="navButtons" id="top"/>
    <div id="funding" class="row main-row funding">
      <div class="col-12">
        <h1 class="section-heading title-font">Funding</h1>
      </div>
      <div class="col-12 funding-details">
        <div class="card col-lg-4 col-md-12 col-sm-12 subsection-left">
          <div class="card-body">
            <h3 class="card-title">Sponsor</h3>
            <p class="lead">
              Collecting, storing, retrieving, and sharing information on ancestral homes is one of the primary
              objectives of the AncestralHomes Network. Sponsors are needed to fund:
            </p>
            <ul class="text-left">
              <li>The continued enhancement of the AHN internet platforms</li>
              <li>The marketing and communication of AHN’S activities and developments</li>
              <li>The creation of sustainable on-going educational programs to engage students in researching
                and adding ancestral sites to the AHN database
              </li>
            </ul>
          </div>
        </div>
        <div class="card col-lg-4 col-md-12 col-sm-12 subsection">
          <div class="card-body">
            <h3 class="card-title">Donate</h3>
            <p class="lead">
              As a not-for-profit corporation, the AncestralHomes Network relies on public and private funding to
              operate its services.
            </p>
            <p class="lead last-section">
              Any donations of funds or services-in-kind are welcomed. Please
              contact any of our Board Members for more information on how to donate.
            </p>
            <Button class="btn btn-primary btn-lg coffee-btn" @click="openMail">
              <font-awesome-icon class="coffee-icon" icon="envelope"/>
              Send us an Email
            </Button>
          </div>
        </div>
        <div class="card col-lg-4 col-md-12 col-sm-12 subsection subsection-right">
          <div class="card-body">
            <h3 class="card-title">Buy Us a Coffee</h3>
            <p class="lead">
              The Ancestral Homes Network is kept running by a small (but mighty!) team of
              volunteers.
            </p>
            <p class="lead last-section">
              If you're excited by our work, you can show your support by making a one-time donation of $3 CAD
              via the button below. It may not sound like much, but every contribution means the world to us.
            </p>
            <Button class="btn btn-primary btn-lg coffee-btn" @click="visitLink">
              <font-awesome-icon class="coffee-icon" icon="mug-hot"/>
              Visit our Donation Page
            </Button>
          </div>
        </div>
      </div>
      <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
    </div>

    <div id="add" class="row main-row add">
      <div class="col-12">
        <div class="row">
          <div class="col-5"/>
          <div class="col-lg-7 col-md-12 higher-content">
            <h1 class="title-font">Add Data</h1>
            <p class="lead">
              To add and edit information on this site about an ancestral home, it is necessary to create a
              free user account. The limited personal information required to create an account is protected
              in accordance with our
              <router-link to="/privacy">Privacy Statement</router-link>
              .
            </p>
            <button class="btn btn-lg btn-secondary">
              <router-link
                  class="account-button"
                  :to="{ name: loginDestination, params: {register: true } }">
                {{ dataButtonText }}
              </router-link>
            </button>
          </div>
        </div>
      </div>
      <img class="bg-img-left" src="../assets/ireland_house_left.png"/>
    </div>
    <div id="volunteer" class="row main-row volunteer">
      <div class="col-12">
        <div class="row">
          <div class="col-lg-6 col-md-12 higher-content">
            <h1 class="title-font">Volunteer</h1>
            <p class="lead">
              Are you a member of a Historical or Genealogical Society? A student of History or Architecture?
              An avid researcher of your family tree? Join AHN as a volunteer ambassador to share information
              about the Network with your friends, family, and community. You can also submit your name to serve
              on the AHN Board of Directors. For more information, please <a href="mailto:info@ancestralhomes.net">contact</a>
              one of our team members.
            </p>
          </div>
        </div>
      </div>
      <img class="bg-img-right" src="../assets/alice_b_w.png"/>
    </div>
    <div id="event" class="row main-row host-data-collection">
      <div class="row">
        <div class="col-5"/>
        <div class="col-lg-7 col-md-12 higher-content host-data-collection-inner">
          <h1 class="title-font">Host a Data Collecting Event</h1>
          <p class="lead">A data collecting event is a fun way to socialize, learn and share. Why not consider
            organizing one:</p>
          <ul>
            <li>for your historical or genealogical society</li>
            <li>for a family reunion</li>
            <li>as a learning assignment in social history for your school, college, or university</li>
            <li>as a special interest activity with your social bubble?</li>
          </ul>
          <p class="lead">
            Share anecdotes, data, photos, and record them on the AHN website, or arrange a visit to the actual
            site of the ancestral home and capture the data on-site using the app.
          </p>
          <p class="lead">
            Need a speaker? Our founder, Philip Donnelly, or one of our Board members, would be happy to address
            your group. For more information, and guidance on organizing such an event, please
            <a href="mailto:info@ancestralhomes.net">contact</a> our CEO, Nancy Donnelly.
          </p>
        </div>
      </div>
      <img class="bg-img-left" src="../assets/ottawa_house_left.png"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {sendGoogleAnalyticsEvent} from '../helpers/utils';
import Alert from '../components/Alert';
import JumbotronNav from "@/components/JumbotronNav.vue";

export default {
  name: "Contribute",
  components: {JumbotronNav, Alert},
  data() {
    return {
      scrollPosition: 0,
      navButtons: [
        {refName: 'funding', label: 'Funding', icon: 'coins'},
        {refName: 'add', label: 'Add Data', icon: 'home'},
        {refName: 'volunteer', label: 'Volunteer', icon: 'users'},
        {refName: 'event', label: 'Host an Event', icon: 'calendar'},
      ]
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$gtag.pageview('Contribute');
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters(['getLoggedInUser']),
    loginDestination() {
      return this.getLoggedInUser ? 'add' : 'login';
    },
    dataButtonText() {
      return this.getLoggedInUser ? 'Add a Home' : 'Create an Account';
    }
  },
  methods: {
    handleScroll() {
      // Check whether we're at the top of the page,in which case don't show 'scroll to top' button
      this.scrollPosition = window.scrollY;
    },
    clickedRecruiting() {
      sendGoogleAnalyticsEvent(this, 'Clicked Recruiting Contact Us Prompt (contribute page)', 'generate_lead');
    },
    clickedNavButton(buttonName) {
      sendGoogleAnalyticsEvent(this, 'Clicked Nav Button (contribute page)', 'generate_lead', buttonName);
    },
    visitLink(){
      window.open('https://www.buymeacoffee.com/ancestralhomes', '_blank');
    },
    openMail(){
      window.open('mailto:info@ancestralhomes.net', '_self');
    }
  }
}
</script>

<style scoped>
/* header */
.lead .btn-secondary {
  margin: 0 0.5rem 0.5rem 0.5rem;
}

/* funding */
.funding {
  background-color: #e6f7fa;
}

.funding-details {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  overflow-x: scroll;
}

.subsection p {
  margin-top: 2rem;
}

.subsection-left {
  margin-right: 1rem;
}

.subsection-right {
  margin-left: 1rem;
}

.bg-img-bottom {
  max-width: 100%;
}

/*volunteer*/
.volunteer {
  background-color: #e6f7fa;
}

/*general*/
.higher-content, .card {
  z-index: 1;
}

.back-to-top-text:hover {
  text-decoration: none;
}

.btn-secondary a {
  font-weight: normal;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary a, .back-to-top-text {
  color: white;
}

.main-row {
  padding: 2rem 2rem 1rem 2rem;
  min-height: 40rem;
  align-content: center;
  position: relative;
}

/*Use decimals, not percentages, for opacity since %s don't work w/ current hosting setup*/
.bg-img-right {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  opacity: 0.40;
  max-width: 100%;
}

.bg-img-left {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 0.40;
  max-width: 100%;
}

.section-heading {
  margin-top: 2rem;
}

.opening {
  margin: 2rem 1rem;
}

.btn-floating {
  position: fixed;
  z-index: 2;
  bottom: 2rem;
  right: 2rem;
}

.coffee-btn {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.coffee-icon {
  margin-right: 0.5rem;
  font-size: x-large;
}

.last-section {
  padding-bottom: 1rem;
}

a {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .funding-details {
    display: block;
  }

  .card {
    margin: 1rem 0 0 0;
  }

  .subsection-left, .subsection-right {
    margin-left: 0;
    margin-right: 0;
  }

  .bg-img-right, .bg-img-left, .bg-img-bottom {
    display: none;
  }

  .main-row {
    padding: 1rem;
  }

  .btn-secondary a {
    padding: 0.5rem 25vw;
    margin: -0.5rem -25vw;
  }

  .host-data-collection-inner {
    margin: 1rem;
  }
}
</style>