<template>
    <div class="header">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <a href="/">
                <img src="../assets/logo-text.svg" class="nav-logo"/>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-content collapse navbar-collapse" id="collapsibleNavbar">
              <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                      <router-link to="/about" class="nav-link">About</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link to="/contribute" class="nav-link">Contribute</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link to="/search" class="nav-link">Search</router-link>
                  </li>
                  <li class="nav-item">
                      <router-link to="/add" class="nav-link">Add a Home</router-link>
                  </li>
                  <li>
                      <router-link  class="nav-link" :to="loginDestination">{{loginLabel}}</router-link>
                  </li>
              </ul>
          </div>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Header",
    computed: {
        ...mapGetters(['getLoggedInUser']),
        loginLabel() {
            return this.getLoggedInUser ? 'Account' : 'Log In';
        },
        loginDestination() {
            return this.getLoggedInUser ? '/profile' : '/login';
        },
    },
}
</script>

<style>
.nav-logo {
    height: 4.25rem;
    padding: 0;
}
.navbar-content {
    display: flex;
    align-items: center;
    width: 100%;
}
.navbar-nav li {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-transform: uppercase;
}
@media screen and (max-width: 985px) {
    .navbar-nav li {
        margin: 0;
        text-align: end;
    }
}
</style>